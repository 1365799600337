import axiosWithToken from './request'

interface IContact {
  name: string
  email: string
  comment: string
}

interface IAccess {
  name: string
  email: string
  institute: string
  comment?: string
}

const contactSupport = async (data: IContact, token: string) => {
  try {
    const response = await axiosWithToken.post('support/contact', data, { headers: { recaptcha: token } })
    return response
  } catch (error) {
    return error.response.data
  }
}

const serviceAccessRequest = async (data: IAccess, token) => {
  try {
    const response = await axiosWithToken.post('support/access', data, { headers: { recaptcha: token } })
    return response
  } catch (error) {
    return error.response.data
  }
}

export const supportService = { contactSupport, serviceAccessRequest }