import React, { useEffect, useState } from "react";
import {
  Card,
  CardFooter,
  CardHeader,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
  UncontrolledDropdown,
  Button,
} from "reactstrap";
import AdminHeader from "../../components/AdminComponents/AdminHeader";
import AdminStatCards from "../../components/AdminComponents/AdminStatCards";

import {
  WsFilterTypes,
  WsOrderTypes,
  WsOrderValues,
  WsResponseAction,
  WsTable,
} from "../../app/models/ws.interface";
import ReactPaginate from "react-paginate";
import { useAppDispatch, useAppSelector } from "../../app/hooks/hooks";
import {
  setRequestedEmployee,
  setRequstedInstitute,
  setSearchPlaceholder,
  setSearchQuery,
} from "../../app/state/adminPanelSlice";
import { IVerifyRequests } from "../../app/models/adminPanel.interface";
import VerifyRequestPopup from "../../components/AdminComponents/VerifyRequestPopup";
import { VERIFY_REQUEST_STATUS } from "../../constants";
import { formatDate } from "../../utils/formatDate";
import { UserRole } from "../../app/models/role.interface";
import { useAdminWebSocket } from "../../app/hooks/useAdminWebSocket";
import { useTranslation } from "react-i18next";

const statusFiltersInitialState = [
  {
    status: VERIFY_REQUEST_STATUS.WAITING,
    checked: false,
  },
  {
    status: VERIFY_REQUEST_STATUS.APPROVED,
    checked: false,
  },
  {
    status: VERIFY_REQUEST_STATUS.REJECTED,
    checked: false,
  },
];

const VerifyRequestsTable = () => {
  const dispatch = useAppDispatch();
  const { searchQuery } = useAppSelector(state => state.adminPanel);

  const { role: currentUserRole } = useAppSelector(state => state.user);

  const isAbleToCheck = currentUserRole <= UserRole.employee;

  const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);
  const [currentCheckIndex, setCurrentCheckIndex] = useState(null);

  const [firstRender, setFirstRender] = useState(true);

  const [statusFilters, setStatusFilters] = useState(statusFiltersInitialState);

  const [selectedFilterName, setSelectedFilterName] = useState(null);
  const [isSorting, setIsSorting] = useState(false);
  const { t } = useTranslation();

  const { setTableRequest, setFilters, pageCount, filters, tableRequest, data } =
    useAdminWebSocket<IVerifyRequests>(
      WsTable.verifyRequests,
      WsResponseAction.verifyRequestsTable
    );

  // Handlers
  const handlePageClick = (event: any) => {
    setTableRequest(prevState => ({ ...prevState, page: event.selected + 1 }));
  };

  const performFiltration = (
    orderBy: WsOrderValues,
    orderType: WsOrderTypes,
    filterName: string
  ) => {
    setTableRequest(prevState => ({ ...prevState, page: 1, orderBy, orderType }));
    setSelectedFilterName(filterName);
    setIsSorting(true);
  };

  const resetFiltration = () => {
    setTableRequest(prevState => ({
      ...prevState,
      page: 1,
      orderBy: WsOrderValues.createdDate,
      orderType: WsOrderTypes.desc,
    }));
    setSelectedFilterName(null);
    setStatusFilters(statusFiltersInitialState);
    setIsSorting(false);
  };

  const handleChangeStatusFilter = (e: React.ChangeEvent<HTMLInputElement>, status: number) => {
    setStatusFilters(prevState => {
      const newState = prevState.map(item => {
        return {
          status: item.status,
          checked: false,
        };
      });
      newState[status].checked = e.target.checked;

      setIsSorting(true);

      return newState;
    });
  };

  const toggleApproveModal = (id?: number) => {
    setIsApproveModalOpen(prevState => !prevState);
    if (id) {
      setCurrentCheckIndex(id);
    }
  };

  // Search
  useEffect(() => {
    const fIndex = filters.findIndex(f => f.filterType === WsFilterTypes.byDefault);
    const newFilter = [...filters];
    newFilter[fIndex].filterVariable = searchQuery;
    setFilters(newFilter);
  }, [searchQuery]);

  useEffect(() => {
    dispatch(setSearchPlaceholder(t("verify-requests-table.search-placeholder")));
    return () => {
      dispatch(setSearchQuery(""));
      dispatch(setRequstedInstitute(""));
      dispatch(setRequestedEmployee(""));
    };
  }, [dispatch]);

  // Observe filter change
  useEffect(() => {
    setTableRequest(prevState => ({ ...prevState, filters }));
  }, [filters]);

  // Status Filters Change
  useEffect(() => {
    if (firstRender) {
      setFirstRender(false);
      return;
    }
    const shouldFilter = statusFilters.filter(item => item.checked).map(item => item.status);

    setFilters(prevState => {
      const newFilter = prevState.filter(item => item.filterType !== WsFilterTypes.byStatus);

      if (shouldFilter.length > 0) {
        newFilter.push({
          filterType: WsFilterTypes.byStatus,
          filterVariable: shouldFilter.join(""),
        });
      }

      return newFilter;
    });
  }, [statusFilters]);

  return (
    <>
      <AdminHeader />

      <AdminStatCards />

      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="d-flex flex-column justify-content-between flex-md-row">
                  <h3 className="mb-1 mb-md-0">{t("verify-requests-table.title")}</h3>
                  <div className="row justify-content-end">
                    {/*Status*/}
                    <UncontrolledDropdown>
                      <DropdownToggle role="button" color="primary" outline caret>
                        {t("admin-panel.status")}
                      </DropdownToggle>
                      <DropdownMenu
                        className="dropdown-menu-arrow"
                        right
                        style={{ maxHeight: 500, overflowY: "auto" }}
                      >
                        <DropdownItem toggle={false}>
                          <FormGroup check inline className="w-100">
                            <Input
                              type="checkbox"
                              id="waiting"
                              name="waiting"
                              value="waiting"
                              onChange={e =>
                                handleChangeStatusFilter(e, VERIFY_REQUEST_STATUS.WAITING)
                              }
                              checked={statusFilters[0].checked}
                            />
                            <Label check for={"waiting"} className="w-100" role="button">
                              {t("admin-panel.waiting")}
                            </Label>
                          </FormGroup>
                        </DropdownItem>
                        <DropdownItem toggle={false}>
                          <FormGroup check inline className="w-100">
                            <Input
                              type="checkbox"
                              id="approved"
                              name="approved"
                              value="approved"
                              onChange={e =>
                                handleChangeStatusFilter(e, VERIFY_REQUEST_STATUS.APPROVED)
                              }
                              checked={statusFilters[1].checked}
                            />
                            <Label check for={"approved"} className="w-100" role="button">
                              {t("admin-panel.approved")}
                            </Label>
                          </FormGroup>
                        </DropdownItem>
                        <DropdownItem toggle={false}>
                          <FormGroup check inline className="w-100">
                            <Input
                              type="checkbox"
                              id="rejected"
                              name="rejected"
                              value="rejected"
                              onChange={e =>
                                handleChangeStatusFilter(e, VERIFY_REQUEST_STATUS.REJECTED)
                              }
                              checked={statusFilters[2].checked}
                            />
                            <Label check for={"rejected"} className="w-100" role="button">
                              {t("admin-panel.rejected")}
                            </Label>
                          </FormGroup>
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    {/* FILTER */}
                    <UncontrolledDropdown>
                      <DropdownToggle
                        role="button"
                        color="primary"
                        outline
                        caret
                        style={{
                          maxWidth: 315,
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {selectedFilterName ? selectedFilterName : t("admin-panel.filters.base")}
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-arrow" right>
                        <DropdownItem
                          onClick={() =>
                            performFiltration(
                              WsOrderValues.certificateId,
                              WsOrderTypes.asc,
                              t("admin-panel.filters.sortAZasc")
                            )
                          }
                        >
                          {t("admin-panel.filters.sortAZasc")}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            performFiltration(
                              WsOrderValues.certificateId,
                              WsOrderTypes.desc,
                              t("admin-panel.filters.sortAZdesc")
                            )
                          }
                        >
                          {t("admin-panel.filters.sortAZdesc")}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            performFiltration(
                              WsOrderValues.createdDate,
                              WsOrderTypes.desc,
                              t("admin-panel.filters.dateNewest")
                            )
                          }
                        >
                          {t("admin-panel.filters.dateNewest")}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            performFiltration(
                              WsOrderValues.createdDate,
                              WsOrderTypes.asc,
                              t("admin-panel.filters.dateOldest")
                            )
                          }
                        >
                          {t("admin-panel.filters.dateNewest")}
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    {isSorting && (
                      <Button
                        color="secondary"
                        className="mb-1 mr-2 mb-lg-0"
                        onClick={resetFiltration}
                      >
                        {t("admin-panel.filters.reset")}
                      </Button>
                    )}
                  </div>
                </div>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">{t("admin-panel.certificateId")}</th>
                    <th scope="col">{t("admin-panel.decline-date")}</th>
                    <th scope="col">{t("admin-panel.status")}</th>
                    {isAbleToCheck && <th scope="col"></th>}
                  </tr>
                </thead>
                <tbody>
                  {data.map((diploma: IVerifyRequests) => (
                    <tr key={diploma.id}>
                      <td>{diploma.certificate}</td>
                      <td>{formatDate(diploma.createdAt, true)}</td>
                      <td>
                        {diploma.status === 0 && t("admin-panel.waiting")}
                        {diploma.status === 1 && t("admin-panel.approved")}
                        {diploma.status === 2 && t("admin-panel.rejected")}
                      </td>
                      {isAbleToCheck && (
                        <td className="text-right">
                          <Button
                            variant="secondary"
                            onClick={() => toggleApproveModal(diploma.id)}
                          >
                            {t("admin-panel.check")}
                          </Button>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel={<i className="fas fa-angle-right" />}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={0}
                    pageCount={pageCount}
                    previousLabel={<i className="fas fa-angle-left" />}
                    className="pagination justify-content-end mb-0"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    activeClassName="active"
                    disabledClassName="disabled"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    forcePage={tableRequest.page - 1}
                  />
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      <VerifyRequestPopup
        toggleApproveModal={toggleApproveModal}
        data={data.find(item => item.id === currentCheckIndex)}
        isApproveModalOpen={isApproveModalOpen}
      />
    </>
  );
};

export default VerifyRequestsTable;
