import "./setTwoFactorForm.scss";

import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import * as Yup from "yup";

import { authService } from "../../../services/auth.service";
import { useTranslation } from "react-i18next";

const setTwoFactorFormValidationSchema = Yup.object({
  token: Yup.string()
    .required("Please enter a token")
    .matches(/[0-9]+/, "Token includes numbers only")
    .length(6, "Token must be 6 numbers long"),
});

interface ISetTwoFactorFormProps {
  onSet: () => void;
}

export function SetTwoFactorForm({ onSet }: ISetTwoFactorFormProps) {
  const [authError, setAuthError] = useState("");
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [twoFactorData, setTwoFactorData] = useState({
    qrCode: "",
    secret: "",
  });
  const {t} = useTranslation()

  useEffect(() => {
    async function getTwoFactorData() {
      const response = await authService.getTwoFactorData();
      setTwoFactorData(response.data);
    }
    getTwoFactorData();
  }, []);

  const formik = useFormik({
    initialValues: {
      token: "",
    },
    validationSchema: setTwoFactorFormValidationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: values => {
      setTwoFactor(values.token);
    },
  });

  const setTwoFactor = async (token: string) => {
    setIsFormSubmitting(true);

    try {
      await authService.setTwoFactor(twoFactorData.secret, token);
      onSet();
    } catch (e) {
      setAuthError(e.message);
    } finally {
      setIsFormSubmitting(false);
    }
  };

  return (
    <Form className="set-two-factor-form" onSubmit={formik.handleSubmit}>
      <div className="set-two-factor-form__information">
        <div className="set-two-factor-form__instruction">
          <h1>{t('2fa.setup')}</h1>
          <p>
            {t('2fa.description')}
          </p>
        </div>
        <div className="set-two-factor-form__qr">
          <img src={twoFactorData.qrCode} alt={twoFactorData.secret} />
        </div>
      </div>

      <FormGroup className="set-two-factor-form__form-group">
        <Label for="seed">{t('2fa.seed')}</Label>
        <Input
          id="seed"
          className="set-two-factor-form__input"
          value={twoFactorData.secret}
          readOnly
        />
      </FormGroup>
      <FormGroup className="set-two-factor-form__form-group">
        <Label for="token">{t('2fa.token')}</Label>
        <Input
          id="token"
          className="set-two-factor-form__input"
          autoComplete="off"
          {...formik.getFieldProps("token")}
        />
        {formik.touched.token && formik.errors.token && (
          <small className="text-danger mt-1">{formik.errors.token}</small>
        )}
      </FormGroup>
      {authError && <small className="text-danger mb-1">{authError}</small>}

      <Button
        type="submit"
        color="primary"
        disabled={isFormSubmitting}
        className="set-two-factor-form__submit-button"
      >
        {t('submit.2fa')}
      </Button>
    </Form>
  );
}
