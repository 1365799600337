import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import {
  Modal,
  Button,
  Form,
  Input,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { employeesService } from "../../services/employees.service";
import { useAppDispatch, useAppSelector } from "../../app/hooks/hooks";
import { UserRole } from "../../app/models/role.interface";
import { fetchOverallStats } from "../../app/state/adminPanelSlice";
import { facultyService } from "../../services/faculty.service";
import { IFaculty, IInstitute } from "../../app/models/adminPanel.interface";
import { useTranslation } from "react-i18next";

const validationAddEmployee = Yup.object({
  newEmployeeName: Yup.string()
    .required("Enter the name of the employee")
    .min(2, "Minimum string length is 2 characters")
    .max(64, "Maximum string length is 64 characters"),
  newEmployeeEmail: Yup.string()
    .required("Enter the email address")
    .email("Please enter a valid e-mail address."),
});

export const AddEmployeePopup = props => {
  const { newEmployeePopup, setNewEmployeePopup, allInstitutes } = props;
  const { role: userRole } = useAppSelector(state => state.user);
  const dispatch = useAppDispatch();
  const [selectedInstitute, setSelectedInstitute] = useState<Pick<IInstitute, "id" | "name">>(null);
  const [selectedFaculty, setSelectedFaculty] = useState<IFaculty>(null);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [faculties, setFaculties] = useState<IFaculty[]>([]);
  const isAbleToChangeFaculty =
    (userRole <= UserRole.admin && selectedInstitute) || userRole === UserRole.insitute;
  const { t } = useTranslation();

  const formikAddEmployee = useFormik({
    initialValues: {
      newEmployeeName: "",
      newEmployeeEmail: "",
    },
    validationSchema: validationAddEmployee,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async values => {
      if (!selectedInstitute) {
        toast.error(t("add-employee-popup.select-institute"));
        return;
      }

      if (!selectedFaculty) {
        toast.error(t("add-employee-popup.select-faculty"));
        return;
      }

      const toastLoader = toast.loading(t("admin-loading.create-new-employee"));
      setIsFormSubmitting(true);

      const newEmployee = {
        name: values.newEmployeeName,
        email: values.newEmployeeEmail,
        institute: selectedInstitute.id,
        faculty: selectedFaculty.id,
      };
      const resp = await employeesService.addNewEmployee(newEmployee);
      if (resp.error) {
        toast.update(toastLoader, {
          render: resp.error,
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
        setIsFormSubmitting(false);
        return;
      }
      toast.update(toastLoader, {
        render: t("add-employee-popup.created"),
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
      setIsFormSubmitting(false);
      dispatch(fetchOverallStats());
      closeAndClearPopup();
    },
  });

  const closeAndClearPopup = () => {
    formikAddEmployee.resetForm();
    setSelectedInstitute(null);
    setNewEmployeePopup(false);
  };

  // Auto select institution if it is only one
  useEffect(() => {
    if (newEmployeePopup && allInstitutes.length === 1) {
      setSelectedInstitute(allInstitutes[0]);
    }
  }, [allInstitutes, newEmployeePopup]);

  useEffect(() => {
    if (selectedInstitute) {
      const getFaculties = async () => {
        try {
          const response = await facultyService.getFacultiesByInstituteId(selectedInstitute.id);
          setFaculties(response);
          setSelectedFaculty(null);
        } catch (error) {
          toast.error(error.message);
          setNewEmployeePopup(false);
          return;
        }
      };

      getFaculties();
    }
  }, [selectedInstitute]);

  return (
    <Modal className="modal-dialog-centered" isOpen={newEmployeePopup} toggle={closeAndClearPopup}>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          {t("add-employee-popup.title")}
        </h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={closeAndClearPopup}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <Form onSubmit={formikAddEmployee.handleSubmit}>
        <div className="modal-body">
          <p>{t("add-employee-popup.description")}</p>
          <Input
            placeholder={t("add-employee-popup.form.name")}
            type="text"
            className="pl-2"
            {...formikAddEmployee.getFieldProps("newEmployeeName")}
          />
          {formikAddEmployee.touched.newEmployeeName &&
            formikAddEmployee.errors.newEmployeeName && (
              <small className="text-danger mb-1">{formikAddEmployee.errors.newEmployeeName}</small>
            )}
          <Input
            placeholder={t("add-employee-popup.form.email")}
            type="email"
            className="pl-2 mt-3"
            {...formikAddEmployee.getFieldProps("newEmployeeEmail")}
          />
          {formikAddEmployee.touched.newEmployeeEmail &&
            formikAddEmployee.errors.newEmployeeEmail && (
              <small className="text-danger mb-1">
                {formikAddEmployee.errors.newEmployeeEmail}
              </small>
            )}
          {userRole <= UserRole.admin && (
            <UncontrolledDropdown className="mt-3 d-block">
              <DropdownToggle
                role="button"
                color="primary"
                outline
                caret
                style={{
                  maxWidth: "100%",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {selectedInstitute
                  ? selectedInstitute.name
                  : t("add-employee-popup.form.institute")}
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow">
                {allInstitutes.length > 0 &&
                  allInstitutes.map(i => (
                    <DropdownItem key={i.id} onClick={() => setSelectedInstitute(i)}>
                      {i.name}
                    </DropdownItem>
                  ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          )}
          {isAbleToChangeFaculty && (
            <UncontrolledDropdown className="mt-3 d-block">
              <DropdownToggle
                role="button"
                color="primary"
                outline
                caret={faculties.length > 0}
                style={{
                  maxWidth: "100%",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
                disabled={faculties.length === 0}
              >
                {faculties.length === 0
                  ? t("add-employee-popup.form.no-faculty")
                  : selectedFaculty
                  ? selectedFaculty.name
                  : t("add-employee-popup.form.faculty")}
              </DropdownToggle>
              {selectedInstitute && (
                <DropdownMenu className="dropdown-menu-arrow">
                  {faculties.length > 0 &&
                    faculties.map(i => (
                      <DropdownItem key={i.id} onClick={() => setSelectedFaculty(i)}>
                        {i.name}
                      </DropdownItem>
                    ))}
                </DropdownMenu>
              )}
            </UncontrolledDropdown>
          )}
        </div>
        <div className="modal-footer">
          <Button color="secondary" type="button" onClick={closeAndClearPopup}>
            {t("admin-panel.cancel")}
          </Button>
          <Button color="primary" type="submit" disabled={isFormSubmitting}>
            {t("admin-panel.add")}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};
