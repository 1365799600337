import React, { useState } from "react";
import { useFormik } from "formik";
import { Modal, Button, Form, Input, FormGroup, Label } from "reactstrap";
import * as Yup from "yup";
import { institutesService } from "../../services/institutes.service";
import { toast } from "react-toastify";
import { useAppDispatch } from "../../app/hooks/hooks";
import { fetchOverallStats } from "../../app/state/adminPanelSlice";
import { useTranslation } from "react-i18next";

const validationAddInstitute = Yup.object({
  newInstituteName: Yup.string()
    .required("Enter the name of the institution")
    .min(8, "Minimum 8")
    .max(64, "Maximum string length is 64 characters"),
  newInstituteEmail: Yup.string()
    .required("Enter the email address")
    .email("Please enter a valid e-mail address."),
  newLimit: Yup.number()
    .typeError("Please enter a number")
    .required("Enter the limit")
    .min(1, "Minimum 1")
    .max(99999999, "Eight-digit number is the maximum allowed limit"),
});

const AddInstitutePopup = props => {
  const { newInstitutePopup, setNewInstitutePopup } = props;
  const dispatch = useAppDispatch();
  const [logoFile, setLogoFile] = useState(null);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const { t } = useTranslation();

  const formikAddInstitution = useFormik({
    initialValues: {
      newInstituteName: "",
      newInstituteEmail: "",
      newLimit: "",
    },
    validationSchema: validationAddInstitute,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async values => {
      if (!logoFile) {
        toast.error(t("add-institute-popup.upload-logo"));
        return;
      }

      const toastLoader = toast.loading(t("admin-loading.create-new-institute"));
      setIsFormSubmitting(true);

      const newInstitute = {
        name: values.newInstituteName,
        email: values.newInstituteEmail,
        limit: +values.newLimit,
      };
      const resp = await institutesService.addNewInstitute(newInstitute);
      if (resp.error || resp.statusCode === 500) {
        toast.update(toastLoader, {
          render: resp.error,
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
        setIsFormSubmitting(false);
        return;
      }
      toast.update(toastLoader, {
        render: t("add-institute-popup.created"),
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });

      const formData = new FormData();
      formData.append("logo", logoFile);
      const respLogo = await institutesService.uploadInstituteLogo(resp, formData);
      // console.log(respLogo);
      if (respLogo.error) {
        toast.error(t("add-institute-popup.error-logo"));
      }
      setIsFormSubmitting(false);
      dispatch(fetchOverallStats());
      closeAndClearPopup();
    },
  });

  const closeAndClearPopup = () => {
    formikAddInstitution.resetForm();
    setLogoFile(null);
    setNewInstitutePopup(false);
  };

  const handleFileInput = ({ target: { files } }) => {
    // console.log(files[0]);
    const selectedFile = files[0];
    if (logoFile && files.length === 0) {
      return;
    }
    if (selectedFile.size > 2000000) {
      toast.error(t("add-institute-popup.logo-big"));
      return;
    }
    setLogoFile(selectedFile);
  };

  return (
    <Modal className="modal-dialog-centered" isOpen={newInstitutePopup} toggle={closeAndClearPopup}>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          {t("add-institute-popup.title")}
        </h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={closeAndClearPopup}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <Form onSubmit={formikAddInstitution.handleSubmit}>
        <div className="modal-body">
          <p>{t("add-institute-popup.description")}</p>
          <Input
            placeholder={t("add-institute-popup.form.name")}
            type="text"
            className="pl-2"
            {...formikAddInstitution.getFieldProps("newInstituteName")}
          />
          {formikAddInstitution.touched.newInstituteName &&
            formikAddInstitution.errors.newInstituteName && (
              <small className="text-danger mb-1">
                {formikAddInstitution.errors.newInstituteName}
              </small>
            )}
          <Input
            placeholder={t("add-institute-popup.form.email")}
            type="email"
            className="pl-2 mt-3"
            {...formikAddInstitution.getFieldProps("newInstituteEmail")}
          />
          {formikAddInstitution.touched.newInstituteEmail &&
            formikAddInstitution.errors.newInstituteEmail && (
              <small className="text-danger mb-1">
                {formikAddInstitution.errors.newInstituteEmail}
              </small>
            )}
          <Input
            placeholder={t("add-institute-popup.form.limit")}
            type="text"
            className="pl-2 mt-3"
            {...formikAddInstitution.getFieldProps("newLimit")}
          />
          {formikAddInstitution.touched.newLimit && formikAddInstitution.errors.newLimit && (
            <small className="text-danger mb-1">{formikAddInstitution.errors.newLimit}</small>
          )}
          <FormGroup className="input-group mt-3 mb-0">
            <div className="input-group-prepend">
              <span className="input-group-text" id="inputGroupFileAddon01">
                <i className="ni ni-image" />
              </span>
            </div>
            <div className="custom-file">
              <Input
                name="file"
                type="file"
                accept="image/png, image/svg+xml"
                onChange={e => handleFileInput(e)}
                className="custom-file-input"
                id="logoFileInput"
                aria-describedby="inputGroupFileAddon01"
              />
              <Label className="custom-file-label" htmlFor="logoFileInput">
                {logoFile ? logoFile.name : t("add-institute-popup.form.upload-logo")}
              </Label>
            </div>
          </FormGroup>
          <p>{t("add-institute-popup.form.upload-logo-description")}</p>
        </div>
        <div className="modal-footer">
          <Button color="secondary" type="button" onClick={closeAndClearPopup}>
            {t("admin-panel.cancel")}
          </Button>
          <Button color="primary" type="submit" disabled={isFormSubmitting}>
            {t("admin-panel.add")}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default AddInstitutePopup;
