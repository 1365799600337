import { useFormik } from "formik";
import { useState } from "react";
import { toast } from "react-toastify";
import { Button, Form, FormGroup, ModalProps } from "reactstrap";
import * as Yup from "yup";
import { studentService } from "../../../../services/student.service";
import { useTranslation } from "react-i18next";
import info from '../../../../assets/icons/info.svg'

const transferModalEmailFormValidationSchema = Yup.object({
  email: Yup.string().required("Email is required").email("Please enter a valid email address"),
});

interface ITransferModalEmailFormProps extends ModalProps {
  onFormSubmit: (email: string) => void;
}

export const TransferModalEmailForm = ({ onFormSubmit: onSubmit, toggle }: ITransferModalEmailFormProps) => {
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [isFormDataVerified, setIsFormDataVerified] = useState(false);
  const {t} = useTranslation()

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: transferModalEmailFormValidationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async ({ email }) => {
      setIsFormSubmitting(true);
      const toastLoader = toast.loading(t("loading.checking"));

      const response = await studentService.checkEmail(email);

      if (response && response.error) {
        toast.update(toastLoader, {
          render: response.error,
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
        setIsFormSubmitting(false);
        return;
      }

      if (!response) {
        toast.update(toastLoader, {
          render: t("transferModal.noEmail"),
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
        setIsFormSubmitting(false);
        return;
      }

      toast.update(toastLoader, {
        render: t("transferModal.success"),
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });

      setIsFormSubmitting(false);
      setIsFormDataVerified(true);
    },
  });

  const handleToggle = (event: any) => {
    toggle(event);
  };

  //   let timeout: any = null;

  //   const handleEmailChange = () => {
  //     if (timeout) {
  //       clearTimeout(timeout);
  //       setIsFormSubmitting(true);
  //       setIsFormDataVerified(false);
  //     }
  //     if (formik.errors.email.length) {
  //       return;
  //     }

  //     timeout = setTimeout(async () => {
  //       const response = await studentService.checkEmail(formik.values.email);

  //       if (response && response.error) {
  //         toast.error(response.error, {
  //           autoClose: 3000,
  //         });
  //         setIsFormSubmitting(false);
  //         return;
  //       }

  //       toast.success("Your document was send", {
  //         autoClose: 3000,
  //       });
  //       setIsFormDataVerified(true);
  //     }, 3000);
  //   };

  return (
    <Form className="transfer-document-form" onSubmit={formik.handleSubmit}>
      <div className="transfer-document-form__header">
        <h2>{t('transferModal.title')}</h2>
        <img src={info} alt="info" />
      </div>
      <div className="transfer-document-form__content">
        <p>
          {t('transferModal.once')}
        </p>
      </div>
      <FormGroup>
        <div className="transfer-document-form__input">
          <label htmlFor="email">
            {t("transferModal.enter")}
          </label>
          <input type="text" id="email" placeholder="example@email.com" {...formik.getFieldProps('email')}/>
        </div>
        {formik.touched.email && formik.errors.email && (
          <small className="text-danger mt-1">{formik.errors.email}</small>
        )}
      </FormGroup>

      {isFormDataVerified ? (
        <Button
          type="reset"
          color="primary"
          className="transfer-document-form__submit-button"
          onClick={() => onSubmit(formik.values.email)}
        >
          {t('transferModal.transfer')}
        </Button>
      ) : (
        <div className="transfer-document-form__buttons">
          <Button
            type="submit"
            color="primary"
            className="transfer-document-form__submit-button"
            disabled={isFormSubmitting}
          >
            {t('submit.check')}
          </Button>
          <Button
            type="button"
            color="primary"
            outline
            onClick={handleToggle}
          >
            {t('submit.back')}
          </Button>
        </div>
      )}
    </Form>
  );
};
