import React from "react";
import './WarningSign.scss'
import warningSign from '../../assets/icons/warning.svg'

interface IWarningSignProps {
  text: string
}

const WarningSign = (props: IWarningSignProps) => {

  const {text} = props

  return (
    <div className="warning-sign">
      <img src={warningSign} alt="warning" />
      <p className="warning-sign__text">{text}</p>
    </div>
  );
};

export default WarningSign;