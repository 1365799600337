import "./dropzone.scss";

import { useMemo } from "react";

import { Button } from "../../Button/Button";
import { UploadedFileIcon } from "../UploadedFileIcon/UploadedFileIcon";

import filesPlaceholder from "../../../assets/icons/verification/files-placeholder.svg";
import { useTranslation } from "react-i18next";

interface IDropzoneProps {
  files: File[];
  isVerifying?: boolean;
  onDelete: (index: number) => void;
  onVerify: () => Promise<void>;
}

export function Dropzone(props: IDropzoneProps) {
  const { files, onDelete, onVerify } = props;
  const {t} = useTranslation()

  const filesSize = useMemo(() => {
    let size = 0;
    files.forEach(file => (size += file.size));

    const sizeInKb = (size / 10 ** 3).toFixed(2);

    if (+sizeInKb > 1000) {
      const sizeInMb = (size / 10 ** 6).toFixed(2);
      return `${sizeInMb} mb`;
    }

    return `${sizeInKb} kb`;
  }, [files]);

  return (
    <div className="dropzone">
      {files.length > 0 ? (
        <div className="dropzone__files-list-wrapper">
          <div>
            <h4>{t('dropzone.success')}</h4>
            <p>{t('dropzone.timeTaken')}</p>
          </div>

          <div className="dropzone__files-list">
            {files.map((file, index) => {
              if (index < 11) {
                return (
                  <UploadedFileIcon file={file} key={index} onDelete={() => onDelete(index)} />
                );
              } else {
                return null;
              }
            })}

            {files.length > 11 && (
              <div className="dropzone__files-expand">+ {files.length - 11}</div>
            )}
          </div>

          <div className="dropzone__files-list-panel">
            <div className="dropzone__uploaded-files-info">
              <h4>{files.length} {t('dropzone.files')}</h4>
              <p>{filesSize}</p>
            </div>

            <div className="dropzone__buttons-wrapper">
              <Button variant="green" className="dropzone__button" onClick={onVerify}>
                {t('submit.verify')}
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div className="dropzone__files-placeholder">
          <img src={filesPlaceholder} alt="" />
          <h3>{t('dropzone.dragFiles')}</h3>
          <p>{t('dropzone.bulkVerify')}</p>
        </div>
      )}
    </div>
  );
}
