import "./documentCard.scss";

import { useEffect, useState } from "react";

import { DocumentTypes } from "../../../app/models/adminPanel.interface";
import { ISelectedDocument, ModalType } from "../../../pages/Student/Student";
import { ipfsService } from "../../../services/ipfs.service";

import downloadIcon from "../../../assets/icons/student/download.svg";
import fileLightIcon from "../../../assets/icons/student/file-light.svg";
import fileIcon from "../../../assets/icons/student/file.svg";
import shareIcon from "../../../assets/icons/student/share.svg";
import transferIcon from "../../../assets/icons/student/transfer.svg";
import { useTranslation } from "react-i18next";
import WarningSign from "../../WarningSign/WarningSign";
// import mailIcon from "../../../assets/icons/student/mail.svg";

const digitalOceanUrl = process.env.REACT_APP_DIGITAL_OCEAN_URL;

export interface IDocument {
  documentType: DocumentTypes;
  id: string;
  instituteLogo: any;
  metadata: string;
  isApproved: boolean
}

interface IDocumentMetadata {
  institution: string;
  physicalNumber: string;
  student: string;
  career: string;
  graduationDate: string;
  file: string;
}

interface IDocumentCardProps {
  document: IDocument;
  onOpenModal: (modalType: ModalType, document: ISelectedDocument) => void;
}

export const DocumentCard = (props: IDocumentCardProps) => {
  const { document, onOpenModal } = props;
  const { documentType, id, instituteLogo, metadata } = document;
  const {t} = useTranslation()

  const [documentMetadata, setDocumentMetadata] = useState<IDocumentMetadata | null>({
    institution: "",
    physicalNumber: "",
    student: "",
    career: "",
    graduationDate: "",
    file: "",
  });

  useEffect(() => {
    async function getDocumentMetadata() {
      const response = await ipfsService.proxyIPFSrequest(metadata.replace("https://", ""));

      const timestamp = Date.parse(response.graduationDate);
      const date = new Date(timestamp).toLocaleDateString("en-UK");

      const documentFile = response.diploma_image.replace("https://", "");

      setDocumentMetadata({
        institution: response.institution_name,
        physicalNumber: response.diploma_number,
        student: response.student_name,
        career: response.career,
        graduationDate: date,
        file: `https://${documentFile}`,
      });
    }
    getDocumentMetadata();
  }, []);

  const handleOpenModal = (modalType: ModalType) => {
    const { hostname } = window.location;
    const selectedDocument: ISelectedDocument = {
      id,
      link: `${hostname}/diploma/${id}`,
    };
    onOpenModal(modalType, selectedDocument);
  };

  return (
    <div className="document-card">
      <div className="document-card__header">
        <div className="document-card__document-type">
          <div className="document-card__file-icon">
            <img src={fileIcon} alt="" />
          </div>
          <span>{documentType === DocumentTypes.certificate ? t("documentCard.certificate") : t("documentCard.diploma")}</span>
        </div>
        <div className="document-card__institute-logo">
          <img
            src={`${digitalOceanUrl + instituteLogo}`}
            alt={`${documentMetadata.institution} logo`}
          />
        </div>
      </div>

      <div className="document-card__props-wrapper">
        <p className="document-card__prop">
          {t("documentCard.name")}
          <br />
          <span>{documentMetadata.student}</span>
        </p>

        <p className="document-card__prop">
          {t('documentCard.institution')}
          <br />
          <span>{documentMetadata.institution}</span>
        </p>

        <p className="document-card__prop">
          {t("documentCard.date")}
          <br />
          <span>{documentMetadata.graduationDate}</span>
        </p>

        <p className="document-card__prop">
          {t("documentCard.certificateId")}
          <br />
          <span>{id}</span>
        </p>

        <p className="document-card__prop">
          {t("documentCard.career")}
          <br />
          <span>{documentMetadata.career}</span>
        </p>

        <p className="document-card__prop">
          {t("documentCard.physical")}
          <br />
          <span>{documentMetadata.physicalNumber}</span>
        </p>
      </div>

      {
        !document.isApproved && <WarningSign text="Please confirm the details of your academic credential"/>
      }

      <div className="document-card__footer">
        <a
          href={`/diploma/${id}`}
          target="_blank"
          rel="noreferrer noopener"
          className="document-card__button document-card__button_link"
        >
          <span>{t('documentCard.view')}</span>
          <img src={fileLightIcon} alt="" />
        </a>
        {/* <button
          className="document-card__button document-card__button_icon"
          onClick={() => onClickOnTransferButton(id)}
        >
          <img src={mailIcon} alt="Send via e-mail" />
        </button> */}
        <a
          href={documentMetadata.file}
          target="_blank"
          rel="noreferrer noopener"
          className="document-card__button document-card__button_icon"
          download
        >
          <img src={downloadIcon} alt="Download document" />
        </a>
        <button
          className="document-card__button document-card__button_icon"
          onClick={() => handleOpenModal(ModalType.Transfer)}
        >
          <img src={transferIcon} alt="Transfer document to another account" />
        </button>
        <button
          className="document-card__button document-card__button_icon"
          onClick={() => handleOpenModal(ModalType.Share)}
        >
          <img src={shareIcon} alt="Share document" />
        </button>
      </div>
    </div>
  );
};
