import axiosWithToken from "./request";
import { VERIFY_REQUEST_STATUS } from "../constants";

const verify = async (file: File) => {
  try {
    const fileFormData = new FormData();
    fileFormData.append("diplomaImage", file);

    const response = await axiosWithToken.post("certificate/verify", fileFormData);
    return response.data;
  } catch (error) {
    console.log(error.response);
    return error.response.data;
  }
};

const updateVerifyRequestStatus = async (status: VERIFY_REQUEST_STATUS, id: number) => {
  try {
    await axiosWithToken.post(`verify-request/${id}/update-status`, { status })
  } catch (error) {
    throw new Error(error)
  }
}

export const verificationService = { verify, updateVerifyRequestStatus };
