import "./mainPageHero.scss";

import Searchbar from "../../Searchbar/Searchbar";
import { useTranslation } from "react-i18next";

export function MainPageHero() {
  const {t} = useTranslation()
  return (
    <section className="mainPageHero">
      <div className="container">
        <div className="mainPageHero__wrapper">
          <h1 dangerouslySetInnerHTML={{
            __html: t('mainPageHero.text')
          }} />
          <p>{t('mainPageHero.protect')}</p>
          <Searchbar isInHeader={false} />
        </div>
      </div>
    </section>
  );
}
