import React from "react";
import "./invalidDiploma.scss";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const InvalidDiploma = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <main className="container text-center invalidDiploma">
      <h1 className="invalidDiploma__title">{t("error.error")}</h1>
      <h2
        className="invalidDiploma__text"
        dangerouslySetInnerHTML={{ __html: t("invalidDiploma.text") }}
      ></h2>
      <button
        className="mt-4 custom-button custom-button_full"
        onClick={() => navigate("/support")}
      >
        {t("invalidDiploma.contact")}
      </button>
    </main>
  );
};

export default InvalidDiploma;
