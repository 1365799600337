import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader, CardText, CardTitle, Container } from "reactstrap";
import { useTranslation } from "react-i18next";

const Deactivated = () => {
  const {t} = useTranslation()
  return (
    <div className="d-flex align-items-center min-vh-100">
      <Container className="text-center">
        <Card>
          <CardHeader>
            <CardTitle tag="h3" className="m-0">
              {t('deactivated.account')}
            </CardTitle>
          </CardHeader>
          <CardBody>
            <CardText dangerouslySetInnerHTML={{__html: t('deactivated.yourAccount')}}/>
            <Link to="/">{t('deactivated.mainPage')}</Link> | <Link to="/login">{t('deactivated.loginPage')}</Link>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default Deactivated;
