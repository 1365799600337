import React, { useState } from "react";
import { Button, Form, Input, Modal } from "reactstrap";
import { useAppDispatch } from "../../app/hooks/hooks";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { facultyService } from "../../services/faculty.service";
import { fetchOverallStats } from "../../app/state/adminPanelSlice";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

interface IEditFacultyPopupProps {
  toggleEditFacultyPopup: () => void
  isEditFacultyPopupOpen: boolean
  facultyId: number
}

const validationEditFaculty = Yup.object({
  name: Yup.string().required("Enter name of the faculty")
});

const EditFacultyPopup = (props: IEditFacultyPopupProps) => {
  const {toggleEditFacultyPopup, isEditFacultyPopupOpen, facultyId} = props

  const dispatch = useAppDispatch();
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const {t} = useTranslation()

  const formikEditFaculty = useFormik({
    initialValues: {
      name: ''
    },
    validationSchema: validationEditFaculty,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values) => {
      const toastLoader = toast.loading(t('admin-loading.update-faculty'));

      try {
        await facultyService.editFaculty(values, facultyId);
        toast.update(toastLoader, {
          render: t('edit-faculty-popup.updated'),
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
        setIsFormSubmitting(false);
        dispatch(fetchOverallStats());
        closeAndClearPopup();
      } catch (error) {
        toast.update(toastLoader, {
          render: error.error,
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
        setIsFormSubmitting(false);
        return;
      }
    }
  })

  const closeAndClearPopup = () => {
    formikEditFaculty.resetForm();
    toggleEditFacultyPopup();
  };

  return (
    <Modal isOpen={isEditFacultyPopupOpen} toggle={toggleEditFacultyPopup} centered>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          {t('edit-faculty-popup.title')}
        </h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={toggleEditFacultyPopup}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="p-4">
        <Form onSubmit={formikEditFaculty.handleSubmit}>
          <div>
            <p>{t('edit-faculty-popup.description')}</p>
            <Input
              placeholder={t("edit-faculty-popup.form.name")}
              type="text"
              className="pl-2"
              {...formikEditFaculty.getFieldProps("name")}
            />
            {formikEditFaculty.touched.name &&
              formikEditFaculty.errors.name && (
                <small className="text-danger mb-1">{formikEditFaculty.errors.name}</small>
              )}
          </div>
          <div className="d-flex justify-content-end mt-4">
            <Button color="secondary" type="button" onClick={toggleEditFacultyPopup}>
              {t('admin-panel.cancel')}
            </Button>
            <Button color="primary" type="submit" disabled={isFormSubmitting}>
              {t('admin-panel.save')}
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default EditFacultyPopup;
