import "./header.scss";

import classNames from "classnames";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useAppSelector } from "../../app/hooks/hooks";
import { UserRole } from "../../app/models/role.interface";
import { Button } from "../Button/Button";
import { Logo } from "../Logo/Logo";
import { MobileMenu } from "../MobileMenu/MobileMenu";
import Searchbar from "../Searchbar/Searchbar";
import { UserDropdown } from "./UserDropdown/UserDropdown";

import burger from "../../assets/icons/burger.svg";
import joinIcon from "../../assets/icons/join.svg";
import { useTranslation } from "react-i18next";
import ChangeLanguage from "../ChangeLanguage/changeLanguage";

export const Header = () => {
  const navigate = useNavigate();
  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);
  const {t} = useTranslation()

  const openMobileMenu = () => {
    setIsMobileMenuVisible(true);
    document.body.style.overflow = "hidden";
  };
  const closeMobileMenu = () => {
    setIsMobileMenuVisible(false);
    document.body.style.overflow = "auto";
  };

  const location = useLocation();
  const isStudentPage = location.pathname === "/student";

  const userRole = useAppSelector(state => state.user.role);

  const HeaderButton = () => {
    switch (userRole) {
      case UserRole.basicUser:
        return <UserDropdown variant={isStudentPage ? "secondary" : "primary"} />;

      default:
        return (
          <Button
            variant="secondary"
            className="main-header__button"
            onClick={() => navigate("/join")}
          >
            <img src={joinIcon} alt="" />
            {t('header.join')}
          </Button>
        );
    }
  };

  return (
    <header
      className={classNames(
        "main-header__wrapper",
        isStudentPage && "main-header__wrapper_student"
      )}
    >
      <div className="container main-header">
        <Logo variant={isStudentPage ? "white" : "black"} />

        <div className="main-header__search">
          <Searchbar isInHeader={true} isStudentPage={isStudentPage} />
        </div>

        <HeaderButton />

        <ChangeLanguage isBlack={!isStudentPage} isStudentPage={isStudentPage}/>

        <button className="main-header__mobile-burger" onClick={openMobileMenu}>
          <img src={burger} alt="menu"></img>
        </button>

        {isMobileMenuVisible && <MobileMenu onClose={closeMobileMenu} />}
      </div>
    </header>
  );
};
