import { useAppSelector } from "../../../app/hooks/hooks";
import "./studentHero.scss";
import { useTranslation } from "react-i18next";

export const StudentHero = () => {
  const userName = useAppSelector(state => state.user.name);
  const {t} = useTranslation()
  
  return (
    <div className="student-hero">
      <div className="container">
        <div className="student-hero__wrapper">
          <h1>
            {t('studentHero.welcome')} {userName}
          </h1>
          <p>
            {t('studentHero.passport')}
          </p>
        </div>
      </div>
    </div>
  );
};
