import React, { useState } from "react";
import { Modal, Form, Input, Button } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { institutesService } from "../../services/institutes.service";
import { useTranslation } from "react-i18next";

const validationEditInstitution = Yup.object({
  editInstitutionName: Yup.string()
    .required("Enter the name of the admin")
    .min(2, "Minimum string length is 2 characters")
    .max(64, "Maximum string length is 64 characters")
    .matches(
      /^[aA-zZ\s]+$/,
      "Entering special characters is prohibited. Please enter the correct name of the admin"
    ),
  editInstitutionEmail: Yup.string()
    .required("Enter the email address")
    .email("Please enter a valid e-mail address."),
});

export const EditInstitutionPopup = ({
  editInstitutionPopup,
  setEditInstitutionPopup,
  selectedInstitutionEmail,
  selectedInstitutionName,
  selectedInstitution,
}) => {
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const { t } = useTranslation();

  const formikEditInstitution = useFormik({
    initialValues: {
      editInstitutionName: selectedInstitutionName,
      editInstitutionEmail: selectedInstitutionEmail,
    },
    validationSchema: validationEditInstitution,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: async values => {
      const toastLoader = toast.loading(t("admin-loading.update-institute"));
      setIsFormSubmitting(true);

      let newData: any;
      if (values.editInstitutionName !== selectedInstitutionName) {
        newData = { name: values.editInstitutionName };
      }
      if (values.editInstitutionEmail !== selectedInstitutionEmail) {
        newData = { ...newData, email: values.editInstitutionEmail };
      }
      const resp = await institutesService.updateInstitute(selectedInstitution, newData);
      if (resp.error) {
        toast.update(toastLoader, {
          render: resp.error,
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
        setIsFormSubmitting(false);
        return;
      } else {
        toast.update(toastLoader, {
          render: t("edit-institute-popup.updated"),
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
        setIsFormSubmitting(false);
        closeAndClearPopup();
      }
    },
  });

  const closeAndClearPopup = () => {
    formikEditInstitution.resetForm();
    setEditInstitutionPopup(false);
  };

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={editInstitutionPopup}
      toggle={closeAndClearPopup}
    >
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          {t("edit-institute-popup.title")}
        </h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={closeAndClearPopup}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <Form onSubmit={formikEditInstitution.handleSubmit}>
        <div className="modal-body">
          <p>{t("edit-institute-popup.description")}</p>
          <Input
            placeholder={t("edit-institute-popup.form.name")}
            type="text"
            className="pl-2"
            {...formikEditInstitution.getFieldProps("editInstitutionName")}
          />
          {formikEditInstitution.touched.editInstitutionName &&
            formikEditInstitution.errors.editInstitutionName && (
              <small className="text-danger mb-1">
                {formikEditInstitution.errors.editInstitutionName}
              </small>
            )}
          <Input
            placeholder={t("edit-institute-popup.form.email")}
            type="email"
            className="pl-2 mt-3"
            {...formikEditInstitution.getFieldProps("editInstitutionEmail")}
          />
          {formikEditInstitution.touched.editInstitutionEmail &&
            formikEditInstitution.errors.editInstitutionEmail && (
              <small className="text-danger mb-1">
                {formikEditInstitution.errors.editInstitutionEmail}
              </small>
            )}
        </div>
        <div className="modal-footer">
          <Button color="secondary" type="button" onClick={closeAndClearPopup}>
            {t("admin-panel.cancel")}
          </Button>
          <Button color="primary" type="submit" disabled={isFormSubmitting}>
            {t("admin-panel.save")}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};
