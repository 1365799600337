import React, { useEffect, useState } from "react";
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Table,
  Container,
  Row,
  Button,
} from "reactstrap";
import AdminHeader from "../../components/AdminComponents/AdminHeader";
import AdminStatCards from "../../components/AdminComponents/AdminStatCards";
import { ConfirmationPopup } from "../../components/AdminComponents/ConfirmationPopup";
import { toast } from "react-toastify";
import {
  WsFilterTypes,
  WsOrderTypes,
  WsOrderValues,
  WsResponseAction,
  WsTable,
} from "../../app/models/ws.interface";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks/hooks";
import {
  fetchOverallStats,
  setRequstedInstitute,
  setSearchPlaceholder,
  setSearchQuery,
} from "../../app/state/adminPanelSlice";
import AddInstitutePopup from "../../components/AdminComponents/AddInstitutePopup";
import { EditLimitPopup } from "../../components/AdminComponents/EditLimitPopup";
import { EditInstitutionPopup } from "../../components/AdminComponents/EditInstitutionPopup";
import { institutesService } from "../../services/institutes.service";
import UploadInstituteLogoPopup from "../../components/AdminComponents/UploadInstituteLogoPopup";
import { IInstitute } from "../../app/models/adminPanel.interface";
import { useAdminWebSocket } from "../../app/hooks/useAdminWebSocket";
import { useTranslation } from "react-i18next";

const InstitutionsTable = () => {
  const dispatch = useAppDispatch();
  const searchQuery = useAppSelector(state => state.adminPanel.searchQuery);

  const [selectedInstitute, setSelectedInstitute] = useState(null);
  const [newInstitutePopup, setNewInstitutePopup] = useState(false);
  const [activateInstitutePopup, setActivateInstitutePopup] = useState(false);
  const [deactivateInstitutePopup, setDeactivateInstitutePopup] = useState(false);
  const [editLimitPopup, setEditLimitPopup] = useState(false);
  const [editInstitutionPopup, setEditInstitutionPopup] = useState(false);
  const [newLogoPopup, setNewLogoPopup] = useState(false);
  const [selectedInstitutionName, setSelectedInstitutionName] = useState("");
  const [selectedInstitutionEmail, setSeletedInstitutionEmail] = useState("");
  // const [selectedInstituteLimit, setSelectedInstitutedLimit] = useState(null);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [selectedFilterName, setSelectedFilterName] = useState(null);

  const { setTableRequest, pageCount, tableRequest, data } = useAdminWebSocket<IInstitute>(
    WsTable.institutions,
    WsResponseAction.intitutesTable
  );

  // HANDLERS
  const handlePageClick = (event: any) => {
    setTableRequest(prevState => ({ ...prevState, page: event.selected + 1 }));
  };

  const performFiltration = (
    orderBy: WsOrderValues,
    orderType: WsOrderTypes,
    filterName: string
  ) => {
    setTableRequest(prevState => ({ ...prevState, page: 1, orderBy, orderType }));
    setSelectedFilterName(filterName);
  };

  const resetFiltration = () => {
    setTableRequest(prevState => ({
      ...prevState,
      page: 1,
      orderBy: WsOrderValues.createdDate,
      orderType: WsOrderTypes.desc,
    }));
    setSelectedFilterName(null);
  };

  const openActivateInstitutePopup = (id: number) => {
    setActivateInstitutePopup(true);
    setSelectedInstitute(id);
  };
  const openDeactivateInstitutePopup = (id: number) => {
    setDeactivateInstitutePopup(true);
    setSelectedInstitute(id);
  };
  const openEditInstitutePopup = (id: number) => {
    setSelectedInstitute(id);
    const editedInstitute = data.filter(a => a.id === id)[0];
    setSelectedInstitutionName(editedInstitute.name);
    setSeletedInstitutionEmail(editedInstitute.email);
    setEditInstitutionPopup(true);
  };
  const openEditLimitPopup = (id: number) => {
    setSelectedInstitute(id);
    // const editedInstitute = data.filter(a => a.id === id)[0];
    // setSelectedInstitutedLimit(editedInstitute.limit);
    setEditLimitPopup(true);
  };
  const openNewLogoPopup = (id: number) => {
    setSelectedInstitute(id);
    setNewLogoPopup(true);
  };

  const activateInstitute = async () => {
    setIsFormSubmitting(true);
    const resp = await institutesService.updateInstitute(selectedInstitute, { isActive: true });
    if (resp.error) {
      toast.error(resp.error);
      return;
    }
    toast.success(t("institutions-table.institute-activated"));
    setActivateInstitutePopup(false);
    dispatch(fetchOverallStats());
    setIsFormSubmitting(false);
  };

  const deactivateInstitute = async () => {
    setIsFormSubmitting(true);
    const resp = await institutesService.updateInstitute(selectedInstitute, { isActive: false });
    if (resp.error) {
      toast.error(resp.error);
      return;
    }
    toast.success(t("institutions-table.institute-deactivated"));
    setDeactivateInstitutePopup(false);
    dispatch(fetchOverallStats());
    setIsFormSubmitting(false);
  };

  const copyWalletToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(() => toast.success(t("institutions-table.copied")));
  };

  const showInstituteEmployees = (instituteId: number) => {
    dispatch(setRequstedInstitute(instituteId));
    navigate("/panel/employees");
  };

  const showInstituteDiplomas = (instituteId: number) => {
    dispatch(setRequstedInstitute(instituteId));
    navigate("/panel/certificates");
  };

  const showInstituteCareers = (instituteId: number) => {
    dispatch(setRequstedInstitute(instituteId));
    navigate("/panel/careers");
  };

  // SEARCH
  useEffect(() => {
    const newFilter = {
      filterType: WsFilterTypes.byDefault,
      filterVariable: searchQuery,
    };
    setTableRequest(prevState => ({ ...prevState, filters: [newFilter] }));
  }, [searchQuery]);

  useEffect(() => {
    dispatch(setSearchPlaceholder(t("institutions-table.search-placeholder")));

    return () => {
      dispatch(setSearchQuery(""));
    };
  }, [dispatch]);

  return (
    <>
      <AdminHeader />

      <AdminStatCards />

      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="d-flex flex-column justify-content-between flex-md-row">
                  <h3 className="mb-1 mb-md-0">{t("admin-panel.institution")}</h3>
                  <div className="row justify-content-end">
                    <Button
                      color="primary"
                      className="mb-1 mb-lg-0"
                      onClick={() => setNewInstitutePopup(true)}
                    >
                      {t("institutions-table.add")}
                    </Button>
                    <UncontrolledDropdown>
                      <DropdownToggle
                        role="button"
                        color="primary"
                        outline
                        caret
                        style={{
                          maxWidth: 315,
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {selectedFilterName ? selectedFilterName : t("admin-panel.filters.base")}
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-arrow" right>
                        <DropdownItem
                          onClick={() =>
                            performFiltration(
                              WsOrderValues.alphabetically,
                              WsOrderTypes.asc,
                              t("admin-panel.filters.sortAZasc")
                            )
                          }
                        >
                          {t("admin-panel.filters.sortAZasc")}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            performFiltration(
                              WsOrderValues.alphabetically,
                              WsOrderTypes.desc,
                              t("admin-panel.filters.sortAZdesc")
                            )
                          }
                        >
                          {t("admin-panel.filters.sortAZdesc")}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            performFiltration(
                              WsOrderValues.numberOfEmployees,
                              WsOrderTypes.desc,
                              t("institutions-table.filter.byNumberEmployeesDESC")
                            )
                          }
                        >
                          {t("institutions-table.filter.byNumberEmployeesDESC")}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            performFiltration(
                              WsOrderValues.numberOfEmployees,
                              WsOrderTypes.asc,
                              t("institutions-table.filter.byNumberEmployeesASC")
                            )
                          }
                        >
                          {t("institutions-table.filter.byNumberEmployeesASC")}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            performFiltration(
                              WsOrderValues.numberOfIntitutesCertificates,
                              WsOrderTypes.desc,
                              t("institutions-table.filter.byNumberCertificatesDESC")
                            )
                          }
                        >
                          {t("institutions-table.filter.byNumberCertificatesDESC")}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            performFiltration(
                              WsOrderValues.numberOfIntitutesCertificates,
                              WsOrderTypes.asc,
                              t("institutions-table.filter.byNumberCertificatesASC")
                            )
                          }
                        >
                          {t("institutions-table.filter.byNumberCertificatesASC")}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            performFiltration(
                              WsOrderValues.byLimit,
                              WsOrderTypes.desc,
                              t("institutions-table.filter.byLimitDESC")
                            )
                          }
                        >
                          {t("institutions-table.filter.byLimitDESC")}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            performFiltration(
                              WsOrderValues.byLimit,
                              WsOrderTypes.asc,
                              t("institutions-table.filter.byLimitASC")
                            )
                          }
                        >
                          {t("institutions-table.filter.byLimitASC")}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            performFiltration(
                              WsOrderValues.createdDate,
                              WsOrderTypes.desc,
                              t("admin-panel.filters.dateNewest")
                            )
                          }
                        >
                          {t("admin-panel.filters.dateNewest")}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            performFiltration(
                              WsOrderValues.createdDate,
                              WsOrderTypes.asc,
                              t("admin-panel.filters.dateOldest")
                            )
                          }
                        >
                          {t("admin-panel.filters.dateOldest")}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            performFiltration(
                              WsOrderValues.activeStatus,
                              WsOrderTypes.asc,
                              t("admin-panel.filters.deactivated")
                            )
                          }
                        >
                          {t("admin-panel.filters.deactivated")}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            performFiltration(
                              WsOrderValues.activeStatus,
                              WsOrderTypes.desc,
                              t("admin-panel.filters.activated")
                            )
                          }
                        >
                          {t("admin-panel.filters.activated")}
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    {selectedFilterName && (
                      <Button
                        color="secondary"
                        className="mb-1 mr-2 mb-lg-0"
                        onClick={resetFiltration}
                      >
                        {t("admin-panel.filters.reset")}
                      </Button>
                    )}
                  </div>
                </div>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">{t("admin-panel.name")}</th>
                    <th scope="col">{t("admin-panel.email")}</th>
                    <th scope="col">{t("admin-panel.employees")}</th>
                    <th scope="col">{t("admin-panel.certificates-issued")}</th>
                    <th scope="col">{t("admin-panel.wallet")}</th>
                    <th scope="col">{t("admin-panel.status")}</th>
                    <th scope="col">{t("admin-panel.licenses")}</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {data.map(institute => (
                    <tr key={institute.id}>
                      <td>{institute.name}</td>
                      <td>{institute.email}</td>
                      <td>{institute.employees}</td>
                      <td>{institute.issuesCertificates}</td>
                      <td onClick={() => copyWalletToClipboard(institute.walletAddress)}>
                        {institute.walletAddress}
                      </td>
                      <td>
                        {institute.isActive ? (
                          <Badge color="" className="badge-dot mr-4">
                            <i className="bg-success" />
                            {t("admin-panel.active")}
                          </Badge>
                        ) : (
                          <Badge color="" className="badge-dot mr-4">
                            <i className="bg-danger" />
                            {t("admin-panel.inactive")}
                          </Badge>
                        )}
                      </td>
                      <td>{institute.limit - institute.issuesCertificates}</td>
                      <td className="text-right">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            role="button"
                            size="sm"
                            color=""
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right container="body">
                            <DropdownItem onClick={() => openEditInstitutePopup(institute.id)}>
                              {t("admin-panel.edit")}
                            </DropdownItem>
                            <DropdownItem onClick={() => openEditLimitPopup(institute.id)}>
                              {t("admin-panel.edit-limit")}
                            </DropdownItem>
                            <DropdownItem onClick={() => openNewLogoPopup(institute.id)}>
                              {t("admin-panel.update-logo")}
                            </DropdownItem>
                            <DropdownItem onClick={() => showInstituteEmployees(institute.id)}>
                              {t("admin-panel.show-employees")}
                            </DropdownItem>
                            <DropdownItem onClick={() => showInstituteDiplomas(institute.id)}>
                              {t("admin-panel.show-certificates")}
                            </DropdownItem>
                            <DropdownItem onClick={() => showInstituteCareers(institute.id)}>
                              {t("admin-panel.careers")}
                            </DropdownItem>
                            <DropdownItem
                              onClick={
                                institute.isActive
                                  ? () => openDeactivateInstitutePopup(institute.id)
                                  : () => openActivateInstitutePopup(institute.id)
                              }
                            >
                              {institute.isActive
                                ? t("admin-panel.deactivate")
                                : t("admin-panel.activate")}
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel={<i className="fas fa-angle-right" />}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={0}
                    pageCount={pageCount}
                    previousLabel={<i className="fas fa-angle-left" />}
                    className="pagination justify-content-end mb-0"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    activeClassName="active"
                    disabledClassName="disabled"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    forcePage={tableRequest.page - 1}
                  />
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>

      {/* POPUPS */}
      <AddInstitutePopup
        newInstitutePopup={newInstitutePopup}
        setNewInstitutePopup={setNewInstitutePopup}
      />
      <EditInstitutionPopup
        editInstitutionPopup={editInstitutionPopup}
        setEditInstitutionPopup={setEditInstitutionPopup}
        selectedInstitution={selectedInstitute}
        selectedInstitutionName={selectedInstitutionName}
        selectedInstitutionEmail={selectedInstitutionEmail}
      />
      <EditLimitPopup
        editLimitPopup={editLimitPopup}
        setEditLimitPopup={setEditLimitPopup}
        selectedIntitute={selectedInstitute}
      />
      <ConfirmationPopup
        title={t("confirmation-popup.title.activate-institution")}
        text={t("confirmation-popup.text.activate-institution")}
        popupState={activateInstitutePopup}
        handlePopup={setActivateInstitutePopup}
        submitAction={activateInstitute}
        btnText={t("confirmation-popup.btn-text.activate")}
        btnColor="success"
        isFormSubmitting={isFormSubmitting}
      />
      <ConfirmationPopup
        title={t("confirmation-popup.title.deactivate-institution")}
        text={t("confirmation-popup.text.deactivate-institution")}
        popupState={deactivateInstitutePopup}
        handlePopup={setDeactivateInstitutePopup}
        submitAction={deactivateInstitute}
        btnText={t("confirmation-popup.btn-text.deactivate")}
        btnColor="primary"
        isFormSubmitting={isFormSubmitting}
      />
      <UploadInstituteLogoPopup
        newLogoPopup={newLogoPopup}
        setNewLogoPopup={setNewLogoPopup}
        selectedInstitute={selectedInstitute}
      />
    </>
  );
};

export default InstitutionsTable;
