import axiosWithToken from './request'

const getOverallStat = async () => {
  try {
    const response = await axiosWithToken.get('stats')
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export const statsService = { getOverallStat }