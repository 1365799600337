import "./logo.scss";

import classNames from "classnames";
import { Link } from "react-router-dom";

import logoBlack from "../../assets/icons/logoBlack-2.svg";
import logoBlue from "../../assets/icons/logoBlue.svg";
import logoWhite from "../../assets/icons/logoWhite.svg";

interface ILogoProps {
  variant?: "white" | "black" | "blue";
}

export function Logo({ variant = "white" }: ILogoProps) {
  let image = logoWhite;

  switch (variant) {
    case "black":
      image = logoBlack;
      break;
    case "blue":
      image = logoBlue;
      break;
    default:
      image = logoWhite;
      break;
  }

  return (
    <Link to={"/"} className={classNames("logo", `logo_${variant}`)}>
      <img src={image} alt="" />
      BitGraduate
    </Link>
  );
}
