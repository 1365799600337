import {
  ITableRequest,
  WsAction, WsFilterTypes,
  WsOrderTypes,
  WsOrderValues,
  WsResponseAction,
  WsTable,
} from "../models/ws.interface";
import { useOutletContext } from "react-router-dom";
import { useEffect, useState } from "react";

export const useAdminWebSocket = <T>(table: WsTable, responseAction: WsResponseAction) => {

  const limitPerPage = 10;

  const [tableIsConnected, setTableIsConnected] = useState(false);
  const [pageCount, setPageCount] = useState(1);

  const [data, setData] = useState<T[]>([]);

  const [filters, setFilters] = useState([
    { filterType: WsFilterTypes.byDefault, filterVariable: "" },
  ]);

  const [tableRequest, setTableRequest] = useState<ITableRequest>({
    page: 1,
    limit: limitPerPage,
    filters,
    orderBy: WsOrderValues.createdDate,
    orderType: WsOrderTypes.desc,
  });

  const socketRef: any = useOutletContext();
  const socket = socketRef.current;
  // Websocket
  useEffect(() => {
    if (socket === null) {
      return;
    }
    const { accessToken } = JSON.parse(localStorage.getItem("tokens"));
    const tableConnectMessage = {
      JWT: accessToken,
      table: table,
    };
    socket.emit(WsAction.tableConnect, tableConnectMessage);

    socket.on(WsResponseAction.systemMessages, msg => {
      if (msg.message === `Connected to table ${responseAction}`) {
        setTableIsConnected(true);
      }
    });

    const tableListener = data => {
      setData(data.tables);
      setPageCount(data.pages);
    };
    socket.on(responseAction, tableListener);

    return () => {
      socket.off(responseAction);
    };
  }, [socket]);

  useEffect(() => {
    if (tableIsConnected) {
      socket.emit(WsAction.getTable, tableRequest);
    }
  }, [tableRequest, tableIsConnected, socket]);

  return {
    pageCount,
    setFilters,
    setTableRequest,
    filters,
    tableRequest,
    data
  }
}