import React, { useState } from "react";
import { Modal, Form, Input, Button } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { institutesService } from "../../services/institutes.service";
import { useAppDispatch } from "../../app/hooks/hooks";
import { fetchOverallStats } from "../../app/state/adminPanelSlice";
import { useTranslation } from "react-i18next";

interface IEditLimitPopupProps {
  editLimitPopup: boolean;
  setEditLimitPopup: (arg: boolean) => void;
  selectedIntitute: number;
}

export const EditLimitPopup: React.FC<IEditLimitPopupProps> = ({
  editLimitPopup,
  setEditLimitPopup,
  selectedIntitute,
}) => {
  const dispatch = useAppDispatch();
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const {t} = useTranslation()

  const formikEditLimit = useFormik({
    initialValues: {
      newLimit: "",
    },
    validationSchema: Yup.object({
      newLimit: Yup.number()
        .typeError("Please enter a number")
        .required("Please enter the limit")
        .min(1, "Minimum 1")
        .max(99999999, "Eight-digit number is the maximum allowed limit"),
    }),
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: async values => {
      const toastLoader = toast.loading(t('admin-loading.update-limit'));
      setIsFormSubmitting(true);

      const resp = await institutesService.updateInstitute(selectedIntitute, {
        addLimit: +values.newLimit,
      });
      if (resp.error) {
        toast.update(toastLoader, {
          render: resp.error,
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
        setIsFormSubmitting(false);
        return;
      } else {
        toast.update(toastLoader, {
          render: t('edit-limit-popup.updated'),
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
        setIsFormSubmitting(false);
        dispatch(fetchOverallStats());
        closeAndClearPopup();
      }
    },
  });

  const closeAndClearPopup = () => {
    formikEditLimit.values.newLimit = "";
    formikEditLimit.touched.newLimit = false;
    setEditLimitPopup(false);
  };

  return (
    <Modal className="modal-dialog-centered" isOpen={editLimitPopup} toggle={closeAndClearPopup}>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          {t('edit-limit-popup.title')}
        </h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={closeAndClearPopup}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <Form onSubmit={formikEditLimit.handleSubmit}>
        <div className="modal-body">
          <p>{t('edit-limit-popup.description')}</p>
          <Input
            placeholder={t('edit-limit-popup.licenses')}
            type="text"
            className="pl-2"
            {...formikEditLimit.getFieldProps("newLimit")}
          />
          {formikEditLimit.touched.newLimit && formikEditLimit.errors.newLimit && (
            <small className="text-danger mb-1">{formikEditLimit.errors.newLimit}</small>
          )}
        </div>
        <div className="modal-footer">
          <Button color="secondary" type="button" onClick={closeAndClearPopup}>
            {t('admin-panel.cancel')}
          </Button>
          <Button color="primary" type="submit" disabled={isFormSubmitting}>
            {t('admin-panel.save')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};
