import React, { useState } from "react";
import { useFormik } from "formik";
import { Modal, Button, Form, Input } from "reactstrap";
import * as Yup from "yup";
import { adminsService } from "../../services/admins.service";
import { toast } from "react-toastify";
import { UserRole } from "../../app/models/role.interface";
import { useTranslation } from "react-i18next";

const validationAddAdmin = Yup.object({
  newAdminName: Yup.string()
    .required("Enter the name of the admin")
    .min(2, "Minimum string length is 2 characters")
    .max(64, "Maximum string length is 64 characters"),
  newAdminEmail: Yup.string()
    .required("Enter the email address")
    .email("Please enter a valid e-mail address."),
});

export const AddAdminPopup = props => {
  const { newAdminPopup, setNewAdminPopup } = props;
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const { t } = useTranslation();

  const formikAddAdmin = useFormik({
    initialValues: {
      newAdminName: "",
      newAdminEmail: "",
    },
    validationSchema: validationAddAdmin,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values, { resetForm }) => {
      const toastLoader = toast.loading(t("admin-loading.create-new-admin"));
      setIsFormSubmitting(true);

      const newAdmin = {
        name: values.newAdminName,
        email: values.newAdminEmail,
        role: UserRole.admin,
      };
      const resp = await adminsService.addNewAdmin(newAdmin);
      console.log(resp);
      if (resp.error) {
        toast.update(toastLoader, {
          render: resp.error,
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
        setIsFormSubmitting(false);
        return;
      }
      toast.update(toastLoader, {
        render: t("admin-panel.admin-created"),
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
      setIsFormSubmitting(false);
      resetForm();
      setNewAdminPopup(false);
    },
  });

  const closeAndClearPopup = () => {
    formikAddAdmin.values.newAdminName = "";
    formikAddAdmin.values.newAdminEmail = "";
    formikAddAdmin.touched.newAdminName = false;
    formikAddAdmin.touched.newAdminEmail = false;
    setNewAdminPopup(false);
  };

  return (
    <Modal className="modal-dialog-centered" isOpen={newAdminPopup} toggle={closeAndClearPopup}>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          {t("add-admin-popup.title")}
        </h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={closeAndClearPopup}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <Form onSubmit={formikAddAdmin.handleSubmit}>
        <div className="modal-body">
          <p>{t("add-admin-popup.description")}</p>
          <Input
            placeholder={t("add-admin-popup.form.new-name")}
            type="text"
            className="pl-2"
            {...formikAddAdmin.getFieldProps("newAdminName")}
          />
          {formikAddAdmin.touched.newAdminName && formikAddAdmin.errors.newAdminName && (
            <small className="text-danger mb-1">{formikAddAdmin.errors.newAdminName}</small>
          )}
          <Input
            placeholder={t("add-admin-popup.form.email")}
            type="email"
            className="pl-2 mt-3"
            {...formikAddAdmin.getFieldProps("newAdminEmail")}
          />
          {formikAddAdmin.touched.newAdminEmail && formikAddAdmin.errors.newAdminEmail && (
            <small className="text-danger mb-1">{formikAddAdmin.errors.newAdminEmail}</small>
          )}
        </div>
        <div className="modal-footer">
          <Button color="secondary" type="button" onClick={closeAndClearPopup}>
            {t("admin-panel.cancel")}
          </Button>
          <Button color="primary" type="submit" disabled={isFormSubmitting}>
            {t("admin-panel.add")}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};
