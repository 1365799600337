import { useFormik } from "formik";
import { ChangeEventHandler, useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  UncontrolledDropdown,
} from "reactstrap";
import * as Yup from "yup";
import { useAppDispatch, useAppSelector } from "../../app/hooks/hooks";
import { DocumentTypes, ICareer, IInstitute } from "../../app/models/adminPanel.interface";
import { UserRole } from "../../app/models/role.interface";
import { fetchOverallStats, setCreatedCertificateId } from "../../app/state/adminPanelSlice";
import { careersService } from "../../services/careers.service";
import { certificatesService } from "../../services/certificates.service";
import { useTranslation } from "react-i18next";
import { verificationService } from "../../services/verification.service";

const validationAddCertificate = Yup.object({
  newCertificateUserName: Yup.string()
    .required("Enter the name of the employee")
    .min(2, "Minimum string length is 2 characters")
    .max(64, "Maximum string length is 64 characters"),
  newCertificateUserEmail: Yup.string()
    .required("Enter the email address")
    .email("Please enter a valid e-mail address."),
  newCertificateDate: Yup.date().required("Select the graduation date"),
  newCertificateNumber: Yup.string()
    .required("Enter the physical number of the diploma")
    .min(4, "Minimum 4 numbers")
    .max(25, "Maximum 25 numbers")
    .matches(
      /^[a-zA-Z0-9-]+$/,
      "Entering special characters is prohibited. Please enter the correct physical number of the diploma"
    ),
  newShowDateCertificate: Yup.date(),
});

export const AddCertificatePopup = props => {
  const { newCertificatePopup, setNewCertificatePopup, allInstitutes } = props;
  const { role: currentUserRole } = useAppSelector(state => state.user);
  const dispatch = useAppDispatch();

  const [selectedInstitute, setSelectedInstitute] = useState<IInstitute>(null);
  const [selectedCareer, setSelectedCareer] = useState<ICareer>(null);
  const [selectedDocumentType, setSelectedDocumentType] = useState<DocumentTypes>(
    DocumentTypes.diploma
  );
  const [diplomaFile, setDiplomaFile] = useState(null);
  const [extractFile, setExtractFile] = useState(null);
  const [allCareers, setAllCareers] = useState<ICareer[]>([]);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [isReleaseScheduled, setIsReleaseScheduled] = useState(false);
  const { t } = useTranslation();

  const formikAddCertificate = useFormik({
    initialValues: {
      newCertificateUserName: "",
      newCertificateUserEmail: "",
      newCertificateNumber: "",
      newCertificateDate: "",
      newShowDateCertificate: "",
    },
    validationSchema: validationAddCertificate,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async values => {
      if (selectedInstitute === null) {
        toast.error(t("add-certificate-popup.select-institution"));
        return;
      }
      if (!selectedCareer) {
        toast.error(t("add-certificate-popup.select-career"));
        return;
      }
      if (values.newShowDateCertificate && new Date(values.newShowDateCertificate) < new Date()) {
        toast.error(t("add-certificate-popup.release-greater"));
        return;
      }
      if (diplomaFile === null) {
        toast.error(t("add-certificate-popup.add-diploma"));
        return;
      }
      if (selectedDocumentType === DocumentTypes.diploma && extractFile === null) {
        toast.error(t("add-certificate-popup.add-extract"));
        return;
      }
      const toastLoader = toast.loading(t("admin-loading.create-new-certificate"));
      setIsFormSubmitting(true);

      const verifyRequests = [verificationService.verify(diplomaFile)];
      if (selectedDocumentType === DocumentTypes.diploma) {
        verifyRequests.push(verificationService.verify(extractFile));
      }
      for (let verify of verifyRequests) {
        const result = await verify;
        if (result.isVerified) {
          toast.update(toastLoader, {
            render: t("add-certificate-popup.error-certificate-exists"),
            type: "error",
            isLoading: false,
            autoClose: 3000,
          });
          setIsFormSubmitting(false);
          return;
        }
      }

      const newCertificateFD = new FormData();
      newCertificateFD.append("studentName", values.newCertificateUserName);
      newCertificateFD.append("instituteId", String(selectedInstitute.id));
      newCertificateFD.append("diplomaNumber", String(values.newCertificateNumber));
      newCertificateFD.append("careerId", selectedCareer.id.toString());
      newCertificateFD.append("graduationDate", values.newCertificateDate);
      newCertificateFD.append("documentType", selectedDocumentType.toString());
      newCertificateFD.append("diplomaImage", diplomaFile);
      if (selectedDocumentType === DocumentTypes.diploma) {
        newCertificateFD.append("transcriptImage", extractFile);
      }
      newCertificateFD.append("email", values.newCertificateUserEmail);
      if (values.newShowDateCertificate) {
        newCertificateFD.append("showDate", values.newShowDateCertificate);
      }

      const resp = await certificatesService.addNewCertificate(newCertificateFD);
      if (resp.error && resp.error.length > 0) {
        toast.update(toastLoader, {
          render: t("add-certificate-popup.error"),
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
        setIsFormSubmitting(false);
        return;
      }
      toast.update(toastLoader, {
        render: t("add-certificate-popup.created"),
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
      setIsFormSubmitting(false);
      dispatch(setCreatedCertificateId(resp));
      dispatch(fetchOverallStats());
      closeAndClearPopup();
    },
  });

  const closeAndClearPopup = () => {
    formikAddCertificate.resetForm();
    setSelectedInstitute(null);
    setSelectedCareer(null);
    setDiplomaFile(null);
    setExtractFile(null);
    setNewCertificatePopup(false);
    setAllCareers([]);
    setSelectedDocumentType(DocumentTypes.diploma);
  };

  const handleFileInput = ({ target: { files, id } }) => {
    // console.log(files);
    switch (id) {
      case "diplomaPDF":
        setDiplomaFile(files[0]);
        break;
      case "extractPDF":
        setExtractFile(files[0]);
        break;
    }
  };

  // get careers list for selected institute
  useEffect(() => {
    const getInstituteCareers = async () => {
      const resp = await careersService.getAvaliableCareers(selectedInstitute.id);
      setAllCareers(resp);
      setSelectedCareer(resp[0]);
    };
    if (selectedInstitute) {
      getInstituteCareers();
    }
  }, [selectedInstitute]);

  useEffect(() => {
    if (newCertificatePopup && allInstitutes.length === 1) {
      setSelectedInstitute(allInstitutes[0]);
    }
  }, [allInstitutes, newCertificatePopup]);

  const handleScheduleReleaseCheck: ChangeEventHandler<HTMLInputElement> = event => {
    if (event.target.checked) {
      setIsReleaseScheduled(true);
      formikAddCertificate.setFieldValue("newShowDateCertificate", new Date().toISOString());
      return;
    }
    setIsReleaseScheduled(false);
    formikAddCertificate.setFieldValue("newShowDateCertificate", "");
  };

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={newCertificatePopup}
      toggle={closeAndClearPopup}
    >
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          {t("add-certificate-popup.title")}
        </h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={closeAndClearPopup}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <Form onSubmit={formikAddCertificate.handleSubmit}>
        <div className="modal-body">
          <p>{t("add-certificate-popup.description")}</p>
          <Input
            placeholder={t("add-certificate-popup.form.user")}
            type="text"
            className="pl-2"
            {...formikAddCertificate.getFieldProps("newCertificateUserName")}
          />
          {formikAddCertificate.touched.newCertificateUserName &&
            formikAddCertificate.errors.newCertificateUserName && (
              <small className="text-danger mb-1">
                {formikAddCertificate.errors.newCertificateUserName}
              </small>
            )}
          <Input
            placeholder={t("add-certificate-popup.form.email")}
            type="email"
            className="pl-2 mt-3"
            {...formikAddCertificate.getFieldProps("newCertificateUserEmail")}
          />
          {formikAddCertificate.touched.newCertificateUserEmail &&
            formikAddCertificate.errors.newCertificateUserEmail && (
              <small className="text-danger mb-1">
                {formikAddCertificate.errors.newCertificateUserEmail}
              </small>
            )}
          {/* Institution dropdown */}
          {currentUserRole <= UserRole.admin && (
            <UncontrolledDropdown className="mt-3 d-block">
              <DropdownToggle
                role="button"
                color="primary"
                outline
                caret
                style={{
                  maxWidth: "100%",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {selectedInstitute
                  ? selectedInstitute.name
                  : t("add-certificate-popup.form.institute")}
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow">
                {allInstitutes.length > 0 &&
                  allInstitutes.map(i => (
                    <DropdownItem key={i.id} onClick={() => setSelectedInstitute(i)}>
                      {i.name}
                    </DropdownItem>
                  ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          )}
          {/* Career dropdown */}
          <UncontrolledDropdown className="mt-3 d-block">
            <DropdownToggle
              role="button"
              color="primary"
              outline
              caret
              style={{
                maxWidth: "100%",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {selectedCareer ? selectedCareer.name : t("add-certificate-popup.form.career")}
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow">
              {allCareers.length > 0 &&
                allCareers.map(c => (
                  <DropdownItem key={c.id} onClick={() => setSelectedCareer(c)}>
                    {c.name}
                  </DropdownItem>
                ))}
            </DropdownMenu>
          </UncontrolledDropdown>
          {/* Document type dropdown */}
          <UncontrolledDropdown className="mt-3 d-block">
            <DropdownToggle
              role="button"
              color="primary"
              outline
              caret
              style={{
                maxWidth: "100%",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textTransform: "capitalize",
              }}
            >
              {selectedDocumentType === DocumentTypes.diploma
                ? t("add-certificate-popup.form.diploma")
                : t("add-certificate-popup.form.certificate")}
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow">
              <DropdownItem onClick={() => setSelectedDocumentType(DocumentTypes.diploma)}>
                {t("add-certificate-popup.form.diploma")}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>

          <Input
            placeholder={t("add-certificate-popup.form.number")}
            type="date"
            className="pl-2 mt-3"
            {...formikAddCertificate.getFieldProps("newCertificateDate")}
          />
          {formikAddCertificate.errors.newCertificateDate &&
            formikAddCertificate.touched.newCertificateDate && (
              <small className="text-danger mb-1">
                {formikAddCertificate.errors.newCertificateDate}
              </small>
            )}
          <Input
            placeholder={t("add-certificate-popup.form.number")}
            type="text"
            className="pl-2 mt-3"
            {...formikAddCertificate.getFieldProps("newCertificateNumber")}
          />
          {formikAddCertificate.touched.newCertificateNumber &&
            formikAddCertificate.errors.newCertificateNumber && (
              <small className="text-danger mb-1 d-block">
                {formikAddCertificate.errors.newCertificateNumber}
              </small>
            )}
          <FormGroup check inline className="mt-3">
            <Input
              type="checkbox"
              id="scheduleRelease"
              name="scheduleRelease"
              className="pl-2"
              onChange={handleScheduleReleaseCheck}
            />
            <Label check for="scheduleRelease" role="button" className="pl-2">
              {t("add-certificate-popup.form.schedule")}
            </Label>
          </FormGroup>
          {isReleaseScheduled && (
            <FormGroup>
              <Label check for="burned" role="button" className="mt-2">
                {t("add-certificate-popup.form.release")}
              </Label>
              <Input
                placeholder={t("add-certificate-popup.form.show-date")}
                type="date"
                className="pl-2 mt-2"
                {...formikAddCertificate.getFieldProps("newShowDateCertificate")}
              />
            </FormGroup>
          )}
          <FormGroup className="pl-2 mt-3">
            <Label for="diplomaPDF" role="button" className="d-flex align-items-center">
              <i className="ni ni-cloud-upload-96 text-blue mr-2" />
              {diplomaFile
                ? `${t("add-certificate-popup.form.document")}: ${diplomaFile.name}`
                : `${t("add-certificate-popup.form.diploma-certificate")} (PDF)`}
            </Label>
            <Input
              id="diplomaPDF"
              name="file"
              type="file"
              accept="application/pdf"
              onChange={e => handleFileInput(e)}
              className="d-none"
            />
          </FormGroup>
          {selectedDocumentType === DocumentTypes.diploma && (
            <FormGroup className="pl-2 mt-3">
              <Label for="extractPDF" role="button" className="d-flex align-items-center">
                <i className="ni ni-cloud-upload-96 text-blue mr-2" />
                {extractFile
                  ? `${t("add-certificate-popup.form.extract")}: ${extractFile.name}`
                  : `${t("add-certificate-popup.form.scan")} (PDF)`}
              </Label>
              <Input
                id="extractPDF"
                name="file"
                type="file"
                accept="application/pdf"
                onChange={e => handleFileInput(e)}
                className="d-none"
              />
            </FormGroup>
          )}
        </div>
        <div className="modal-footer">
          <Button color="secondary" type="button" onClick={closeAndClearPopup}>
            {t("admin-panel.cancel")}
          </Button>
          <Button color="primary" type="submit" disabled={isFormSubmitting}>
            {t("admin-panel.add")}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};
