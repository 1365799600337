import axiosWithToken from "./request";

const updateUserEmail = async (email: string, tfaCode: string = '') => {
  try {
    const response = await axiosWithToken.put('user/current/email', { email, tfaCode })
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const updateUserPassword = async (currentPassword: string, newPassword: string, tfaCode: string = '') => {
  try {
    return await axiosWithToken.put('user/current/password', { currentPassword, newPassword, tfaCode })
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.error)
    } else {
      throw new Error("Server error")
    }
  }
}

const updatePasswordByToken = async (token: string, password: string) => {
  try {
    const response = await axiosWithToken.put('user/password', { token, password })
    return response.data
  } catch (error) {
    throw new Error(error)
  }
}

const updatePreferredLanguage = async (langCode: string) => {
  try {
    await axiosWithToken.put('user/language', {language: langCode})
  } catch (e) {
    throw new Error(e)
  }
}

export const userService = { updatePreferredLanguage, updateUserEmail, updateUserPassword, updatePasswordByToken }
