import "./navigation.scss";

import { Link } from "react-router-dom";

import { navigationLinks } from "../../constants";

import upButtonIcon from "../../assets/icons/main-page/up-button-white.svg";

interface INavigationProps {
  onLinkClick?: () => void;
}

export function Navigation(props: INavigationProps) {
  const { onLinkClick } = props;
  return (
    <>
      <nav className="navigation">
        {navigationLinks.map(navigationLink => (
          <Link
            key={navigationLink.title}
            to={navigationLink.href + navigationLink.hash}
            className="navigation__link"
            onClick={onLinkClick}
          >
            {navigationLink.title}
          </Link>
        ))}
      </nav>

      <nav className="navigation_mobile">
        {navigationLinks.map(navigationLink => (
          <Link
            key={navigationLink.title}
            to={navigationLink.href + navigationLink.hash}
            className="navigation__link navigation__link_mobile"
            onClick={onLinkClick}
          >
            {navigationLink.title}
            <img src={upButtonIcon} alt="" />
          </Link>
        ))}
      </nav>
    </>
  );
}
