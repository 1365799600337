import type { MutableRefObject } from 'react'
import { useLayoutEffect, useRef } from 'react'

export function useLatest(value: unknown): MutableRefObject<any> {
  const valueReference = useRef(value)

  useLayoutEffect(() => {
    valueReference.current = value
  }, [value])

  return valueReference
}