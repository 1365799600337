export const formatDate = (isoDate: string, withHours = false) => {
  const date = new Date(isoDate)

  const options: Intl.DateTimeFormatOptions= {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  }

  if(withHours) {
    options['hour'] = "numeric"
    options['minute'] = "numeric"
    options['second'] = "numeric"
  }

  try {
    return new Intl.DateTimeFormat('en-UK', options).format(date)
  } catch {
    return isoDate
  }
};