import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardHeader,
  Form,
  FormGroup,
  Input,
  Modal,
  FormFeedback,
  InputGroupAddon,
  InputGroupText,
  InputGroup, Label,
} from "reactstrap";
import AdminHeader from "../../components/AdminComponents/AdminHeader";
import { useFormik } from "formik";
import * as Yup from "yup";
import { userService } from "../../services/user.service";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../app/hooks/hooks";
import { setUserEmail } from "../../app/state/userSlice";
import lockIcon from "../../assets/icons/student/lock.svg";
import { useTranslation } from "react-i18next";

const validationSchemaChangePass = Yup.object({
  currentPassword: Yup.string()
    .required("Field is required")
    .min(6, "The password you entered is too short, the minimum password length is 6 characters.")
    .max(15, "The password is too long. The maximum password length is 15 characters."),
  newPassword: Yup.string()
    .required("Field is required")
    .min(6, "The password you entered is too short, the minimum password length is 6 characters.")
    .max(15, "The password is too long. The maximum password length is 15 characters.")
    .matches(
      /(?=.*[0-9])(?=.*[~`!@#$%^&*().\/\\\[\]{}\-_=+><;:?,|'"])(?=.*[a-z])(?=.*[A-Z])/,
      "At least one uppercase English letter, one lower case, one digit and one special character."
    ),
  newPasswordConfirm: Yup.string()
    .required("Field is required")
    .min(6, "The password you entered is too short, the minimum password length is 6 characters.")
    .max(15, "The password is too long. The maximum password length is 15 characters.")
    .matches(
      /(?=.*[0-9])(?=.*[~`!@#$%^&*().\/\\\[\]{}\-_=+><;:?,|'"])(?=.*[a-z])(?=.*[A-Z])/,
      "At least one uppercase English letter, one lower case, one digit and one special character."
    )
    .oneOf([Yup.ref("newPassword"), null], "Should match the new password"),
  twoFactorToken: Yup.string()
    .required("Please, enter a token.")
    .matches(/[0-9]+/, "Token includes numbers only")
    .length(6, "Token must be 6 numbers long."),
});

export const Settings = () => {
  const { name: userName, email: userEmail } = useAppSelector(state => state.user);
  const dispatch = useAppDispatch();
  const [isEmailEditting, setIsEmailEditting] = useState(false);
  const [changeMailPopup, setChangeMailPopup] = useState(false);

  const {t} = useTranslation()

  const [showCurrentPass, setShowCurrentPass] = useState(false);
  const [showNewPass, setShowNewPass] = useState(false);
  const [showNewPassConfirm, setShowNewPassConfirm] = useState(false);

  const [passwordChangeError, setPasswordChangeError] = useState("");
  const [twoFactorError, setTwoFactorError] = useState("");
  const [is2FAFormSubmitting, setIs2FAFormSubmitting] = useState(false);
  const [is2FAModalOpen, setIs2FAModalOpen] = useState(false);

  const [twoFAType, setTwoFAType] = useState<'password' | 'email' | null>(null)

  const formikChangePassword = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      newPasswordConfirm: "",
      twoFactorToken: ""
    },
    validationSchema: validationSchemaChangePass,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: () => {}
  });

  const formikChangeEmail = useFormik({
    initialValues: {
      newEmail: userEmail,
      twoFactorToken: ''
    },
    validationSchema: Yup.object({
      newEmail: Yup.string().required("required").email("Please enter a valid e-mail address"),
      twoFactorToken: Yup.string()
        .required("Please, enter a token.")
        .matches(/[0-9]+/, "Token includes numbers only")
        .length(6, "Token must be 6 numbers long."),
    }),
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: () => {},
  });

  const handleChangeEmailButton = () => {
    if (isEmailEditting === false) {
      setIsEmailEditting(true);
    }
    if (isEmailEditting && !formikChangeEmail.errors.newEmail) {
      setChangeMailPopup(true);
    }
  };

  const changeEmail = async () => {
    const newEmail = formikChangeEmail.values.newEmail;
    const twoFactor = formikChangeEmail.values.twoFactorToken
    const resp = await userService.updateUserEmail(newEmail, twoFactor) ;
    if (resp && resp.error) {
      toast.error(resp.error);
      return;
    }
    dispatch(setUserEmail(newEmail));
    setIsEmailEditting(false);
    setChangeMailPopup(false);
    close2FAModal()
    toast.success(t('admin-settings.email-success'));
  };

  const cancelChangeEmail = () => {
    setIsEmailEditting(false);
    setChangeMailPopup(false);
  };

  const open2FAModal = () => {
    if(twoFAType === 'password') {
      if (formikChangePassword.values.currentPassword === formikChangePassword.values.newPassword) {
        setPasswordChangeError(t('admin-settings.password-error'));
        return;
      }
      setPasswordChangeError("");
      formikChangePassword.validateForm()
      const errors = Object.keys(formikChangePassword.errors).filter(item => item !== 'twoFactorToken').length
      if(errors > 0) {
        return
      }
    }
    setIs2FAModalOpen(true);
  };

  const close2FAModal = () => {
    if (!is2FAFormSubmitting) {
      setIs2FAModalOpen(false);
      setTwoFAType(null)
    }
  }

  const openPassword2FA = () => {
    setTwoFAType('password')
  }

  const openEmail2FA = () => {
    setChangeMailPopup(false)
    setTwoFAType('email')
  }

  const changePasswordHandler = async () => {
    setIs2FAFormSubmitting(true);

    try {
      await userService.updateUserPassword(
        formikChangePassword.values.currentPassword,
        formikChangePassword.values.newPassword,
        formikChangePassword.values.twoFactorToken
      );

      toast.success(t('admin-settings.password-success'), { autoClose: 3000 });
      setPasswordChangeError("");
      setTwoFactorError("");
      setIs2FAModalOpen(false);
    } catch (error) {
      if (error.message.includes("token")) {
        setTwoFactorError(error.message);
        return;
      }
      setPasswordChangeError(error.message);
      setIs2FAModalOpen(false);
    } finally {
      setIs2FAFormSubmitting(false);
    }
  }

  useEffect(() => {
    if(twoFAType) {
      open2FAModal()
    }
  }, [twoFAType])

  return (
    <div className="main-content">
      <AdminHeader />
      <div className="header pb-7 pt-6 pt-lg-8 d-flex align-items-center">
        <span className="mask bg-gradient-default opacity-8" />
        <Container className="d-flex align-items-center" fluid>
          <Row>
            <Col>
              <h1 className="display-2 text-white">{t('admin-settings.hello')} {userName}</h1>
              <p className="text-white mt-0 mb-5">{t('admin-settings.your-profile')}</p>
            </Col>
          </Row>
        </Container>
      </div>

      <Container className="mt--7 pb-4" fluid>
        <Row>
          <Col className="order-xl-1" xl="8">
            <Card className="shadow">
              <CardHeader className="bg-white">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">{t('admin-settings.user-profile')}</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div>
                  <div className="pl-lg-2">
                    <Row className="align-items-center">
                      <Col lg="6">
                        <Form onSubmit={formikChangeEmail.handleSubmit}>
                          <FormGroup>
                            <label className="form-control-label" htmlFor="input-email">
                              {t('admin-settings.email-address')}
                            </label>
                            {isEmailEditting ? (
                              <>
                                <Input
                                  className="form-control-alternative"
                                  placeholder={t('admin-settings.email')}
                                  type="email"
                                  {...formikChangeEmail.getFieldProps("newEmail")}
                                  invalid={!!formikChangeEmail.errors.newEmail}
                                />
                                <FormFeedback invalid="true">
                                  {formikChangeEmail.errors.newEmail}
                                </FormFeedback>
                              </>
                            ) : (
                              <p className="p-1">{userEmail}</p>
                            )}
                          </FormGroup>
                          <Button color="primary" type="submit" onClick={handleChangeEmailButton}>
                            {isEmailEditting ? t('admin-panel.save') : t('admin-settings.change-email')}
                          </Button>
                        </Form>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  <Form className="pl-lg-2" onSubmit={formikChangePassword.handleSubmit}>
                    <p>{t('admin-settings.password-description')}</p>
                    <Row className="pr-lg-8">
                      <Col className="lg-6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="currentPassword">
                            {t('admin-settings.current-password')}
                          </label>
                          <InputGroup className="input-group-alternative">
                            <Input
                              className="form-control-alternative"
                              type={showCurrentPass ? "text" : "password"}
                              id="currentPassword"
                              placeholder={t('admin-settings.enter-current-password')}
                              {...formikChangePassword.getFieldProps("currentPassword")}
                              invalid={!!formikChangePassword.errors.currentPassword}
                            />
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText
                                onClick={() => setShowCurrentPass(!showCurrentPass)}
                                style={{ cursor: "pointer" }}
                              >
                                <i className="fas fa-eye" />
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                          <FormFeedback invalid="true" className="d-block">
                            {formikChangePassword.errors.currentPassword}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="pr-lg-8">
                      <Col className="lg-6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="newPassword">
                            {t('admin-settings.new-password')}
                          </label>
                          <InputGroup className="input-group-alternative">
                            <Input
                              className="form-control-alternative"
                              placeholder={t('admin-settings.enter-new-password')}
                              type={showNewPass ? "text" : "password"}
                              id="newPassword"
                              {...formikChangePassword.getFieldProps("newPassword")}
                              invalid={
                                formikChangePassword.touched.newPassword &&
                                !!formikChangePassword.errors.newPassword
                              }
                            />
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText
                                onClick={() => setShowNewPass(!showNewPass)}
                                style={{ cursor: "pointer" }}
                              >
                                <i className="fas fa-eye" />
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                          <FormFeedback invalid="true" className="d-block">
                            {formikChangePassword.errors.newPassword}
                          </FormFeedback>
                        </FormGroup>
                        <FormGroup>
                          <label className="form-control-label" htmlFor="newPasswordConfirm">
                            {t('admin-settings.confirm-password')}
                          </label>
                          <InputGroup className="input-group-alternative">
                            <Input
                              className="form-control-alternative"
                              placeholder={t('admin-settings.enter-confirm-password')}
                              type={showNewPassConfirm ? "text" : "password"}
                              id="newPasswordConfirm"
                              {...formikChangePassword.getFieldProps("newPasswordConfirm")}
                              invalid={
                                formikChangePassword.touched.newPasswordConfirm &&
                                !!formikChangePassword.errors.newPasswordConfirm
                              }
                            />
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText
                                onClick={() => setShowNewPassConfirm(!showNewPassConfirm)}
                                style={{ cursor: "pointer" }}
                              >
                                <i className="fas fa-eye" />
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                          <FormFeedback invalid="true" className="d-block">
                            {passwordChangeError || formikChangePassword.errors.newPasswordConfirm}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Button color="primary" onClick={openPassword2FA} className="mb-4">
                      {t('admin-settings.chane-password')}
                    </Button>
                  </Form>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* CHANGE EMAIL POPUP */}
      <Modal
        className="modal-dialog-centered"
        isOpen={changeMailPopup}
        toggle={() => setChangeMailPopup(!changeMailPopup)}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            {t('admin-settings.change-email-popup.title')}
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setChangeMailPopup(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <p className="mb-0">
            {t('admin-settings.change-email-popup.description')}{" "}
            {formikChangeEmail.values.newEmail}?
          </p>
        </div>
        <div className="modal-footer">
          <Button color="secondary" data-dismiss="modal" type="button" onClick={cancelChangeEmail}>
            {t('admin-panel.cancel')}
          </Button>
          <Button color="primary" type="button" onClick={openEmail2FA}>
            {t('admin-settings.change')}
          </Button>
        </div>
      </Modal>

      {/* CHANGE PASSWORD 2FA */}
      <Modal
        isOpen={is2FAModalOpen}
        toggle={close2FAModal}
        centered
        className="student-password-form__modal"
      >
        <div className="student-password-form__modal-content">
          <div className="student-password-form__modal-header">
            <h3 className="student-password-form__modal-title">{t('admin-settings.change-password-popup.title')}</h3>
            <img className="student-password-form__modal-icon" src={lockIcon} alt="" />
          </div>
          <FormGroup>
            <Label for="token">{t('admin-settings.change-password-popup.label')}</Label>
            {
              twoFAType === 'password' ? <>
                <Input
                  id="token"
                  placeholder="000000"
                  className="student-password-form__input"
                  autoComplete="off"
                  {...formikChangePassword.getFieldProps("twoFactorToken")}
                />
                {formikChangePassword.touched.twoFactorToken && formikChangePassword.errors.twoFactorToken && (
                  <small className="text-danger mt-1">{formikChangePassword.errors.twoFactorToken}</small>
                )}</> : <>
                <Input
                  id="token"
                  placeholder="000000"
                  className="student-password-form__input"
                  autoComplete="off"
                  {...formikChangeEmail.getFieldProps("twoFactorToken")}
                />
                {formikChangeEmail.touched.twoFactorToken && formikChangeEmail.errors.twoFactorToken && (
                  <small className="text-danger mt-1">{formikChangeEmail.errors.twoFactorToken}</small>
                )}</>
            }
          </FormGroup>

          {twoFactorError && <small className="text-danger mb-1">{twoFactorError}</small>}

          <Button
            type="button"
            color="primary"
            className="student-password-form__modal-button"
            disabled={is2FAFormSubmitting}
            onClick={twoFAType === 'password' ? changePasswordHandler : changeEmail}
          >
            {t('admin-settings.change-password-popup.verify')}
          </Button>
        </div>
      </Modal>
    </div>
  );
};
