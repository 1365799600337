import "./verificationPage.scss";

import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";

import { Button } from "../../components/Button/Button";
import { Dropzone } from "../../components/Verification/Dropzone/Dropzone";
import { VerifiedFilesList } from "../../components/Verification/VerifiedFilesList/VerifiedFilesList";

import icon from "../../assets/icons/main-page/sections/section-1.svg";
import { verificationService } from "../../services/verification.service";
import { useTranslation } from "react-i18next";

export function VerificationPage() {
  const [files, setFiles] = useState<File[]>([]);
  const onDrop = useCallback(files => setFiles(prev => [...prev, ...files]), [setFiles]);
  const {t} = useTranslation()

  const { getRootProps, getInputProps, open, isDragReject } = useDropzone({
    onDrop,
    noClick: true,
    accept: { "application/pdf": [".pdf"] },
  });

  useEffect(() => {
    if (isDragReject) {
      toast.error(t("verificationPage.onlyPdf"), { autoClose: 3000 });
    }
  }, [isDragReject]);

  const handleDelete = (index: number) => {
    const filesArray = [...files];
    filesArray.splice(index, 1);
    setFiles(filesArray);
  };

  const [isVerifying, setIsVerifying] = useState(false);
  const [verifiedFiles, setVerifiedFiles] = useState<any[]>([]);

  const verify = async () => {
    setVerifiedFiles([]);
    setIsVerifying(true);

    const verificationQueue = files.map(file => verificationService.verify(file));

    try {
      let indexCounter = -1;
      for await (let response of verificationQueue) {
        indexCounter += 1;
        if (response.isVerified) {
          setVerifiedFiles(prev => [...prev, response]);
        } else {
          const uniqueId = new Date().getTime();
          const file = files[indexCounter];
          setVerifiedFiles(prev => [
            ...prev,
            {
              id: `${uniqueId}`,
              ownerName: `${file.name}`,
              careerName: "",
              graduationDate: "",
              isVerified: response.isVerified,
            },
          ]);
        }
      }
      setIsVerifying(false);
    } catch (error: any) {
      console.error(error);
      setIsVerifying(false);
    }
  };

  return (
    <main className="container verification-page">
      <section className="verification-page__load-files-section">
        <div className="verification-page__information">
          <div className="verification-page__name">
            <div className="verification-page__icon">
              <img src={icon} alt="" />
            </div>
            {t('verificationPage.title')}
          </div>

          <h1 className="verification-page__title">
            {t('verificationPage.quicklyVerify')}
          </h1>
          <p className="verification-page__subtitle">{t('verificationPage.easyAndFast')}</p>

          <Button variant="secondary" className="verification-page__button" onClick={open}>
            {t('verificationPage.choseFiles')}
          </Button>
        </div>

        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <Dropzone
            files={files}
            isVerifying={isVerifying}
            onDelete={handleDelete}
            onVerify={verify}
          />
        </div>
      </section>

      {(isVerifying || verifiedFiles.length > 0) && (
        <section className="verification-page__result-section">
          <h2>{t('verificationPage.results')}</h2>
          <VerifiedFilesList filesList={verifiedFiles} isVerifying={isVerifying} />
        </section>
      )}
    </main>
  );
}
