import "./mobileMenu.scss";

import { useNavigate } from "react-router-dom";

import { Button } from "../Button/Button";
import { Navigation } from "../Navigation/Navigation";

import joinButtonIcon from "../../assets/icons/main-page/header/join-button.svg";
import loginButtonIcon from "../../assets/icons/main-page/header/login-button.svg";
import verificationButtonIcon from "../../assets/icons/main-page/header/verification-button.svg";
import mobileCross from "../../assets/icons/mobile-cross.svg";
import { useTranslation } from "react-i18next";

interface IMobileMenuProps {
  onClose: () => void;
}

export function MobileMenu(props: IMobileMenuProps) {
  const { onClose } = props;
  const { t } = useTranslation();

  const navigate = useNavigate();

  const closeAndNavigate = (href: string) => {
    onClose();
    navigate(href);
  };

  return (
    <div className="mobile-menu__background">
      <div className="mobile-menu">
        <div className="mobile-menu__header">
          <h1>{t("mobileMenu.menu")}</h1>
          <button className="mobile-menu__close-button" onClick={onClose}>
            <img src={mobileCross} alt="" />
          </button>
        </div>

        <div className="mobile-menu__buttons-wrapper">
          <Button className="mobile-menu__button" onClick={() => closeAndNavigate("/join")}>
            <img src={joinButtonIcon} alt="" />
            <p>{t("mobileMenu.join")}</p>
          </Button>
          <Button
            className="mobile-menu__button"
            variant="green"
            onClick={() => closeAndNavigate("/verification")}
          >
            <img src={verificationButtonIcon} alt="" />
            <p>{t("mobileMenu.verificationPortal")}</p>
          </Button>
          <Button
            className="mobile-menu__button"
            variant="transparent"
            onClick={() => closeAndNavigate("/auth")}
          >
            <img src={loginButtonIcon} alt="" />
            <p>{t("mobileMenu.login")}</p>
          </Button>
        </div>

        <Navigation onLinkClick={() => onClose()} />
      </div>
    </div>
  );
}
