import axiosWithToken from './request'

interface INewEmployee {
  name: string
  email: string
  institute: number
}

interface IUpdateEmployee {
  name?: string
  email?: string
  isActive?: boolean
}

const addNewEmployee = async (newEmployee: INewEmployee) => {
  try {
    const response = await axiosWithToken.post('employee', newEmployee)
    return response.data
  } catch (error) {
    console.log(error.response)
    return error.response.data
  }
}

const updateEmployee = async (id: number, newData: IUpdateEmployee) => {
  try {
    const response = await axiosWithToken.put(`employee/${id}`, newData)
    return response.status
  } catch (error) {
    console.log(error.response)
    return error.response.data
  }
}

const deleteEmployee = async (id: number) => {
  return await axiosWithToken.delete(`employee/${id}`)
}

export const employeesService = { addNewEmployee, updateEmployee, deleteEmployee }