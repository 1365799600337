import React from "react";
import { useFormik } from "formik";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Nav,
  Navbar,
  UncontrolledDropdown,
} from "reactstrap";
import { useAppDispatch, useAppSelector } from "../../app/hooks/hooks";
import { setLoggedOut } from "../../app/state/userSlice";
import { authService } from "../../services/auth.service";
import * as Yup from "yup";
import { setSearchQuery } from "../../app/state/adminPanelSlice";
import { useTranslation } from "react-i18next";
import { userService } from "../../services/user.service";
import { toast } from "react-toastify";

const AdminHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isSettingsPage = location.pathname.includes("settings");
  const currentUserName = useAppSelector(state => state.user.name);
  const searchPlaceholder = useAppSelector(state => state.adminPanel.searchPlaceholder);

  const {t, i18n} = useTranslation()

  const formikSearch = useFormik({
    initialValues: {
      search: "",
    },
    validationSchema: Yup.object({
      search: Yup.string()
        .min(2, "The minimum number of characters is 2")
        .max(64, "Maximum number of characters - 64"),
    }),
    validateOnChange: true,
    onSubmit: values => {
      dispatch(setSearchQuery(values.search));
    },
  });

  const languageChangeHandler = async() => {
    const lang = i18n.language === 'en' ? 'ru' : 'en'
    try {
      await userService.updatePreferredLanguage(lang)
      await authService.refreshTokens()
      await i18n.changeLanguage(lang)
      localStorage.setItem('lang', lang)
    } catch {
      toast.error(t('admin-header.language-error'));
    }
  }

  const executeLogout = async () => {
    await authService.logout();
    navigate("/");
    dispatch(setLoggedOut());
  };

  return (
    <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
      <Container fluid>
        <Link className="h6 mb-0 text-white text-uppercase d-none d-lg-inline-block" to="/">
          {t('admin-header.return')}
        </Link>
        {!isSettingsPage && (
          <Form
            className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto"
            onSubmit={formikSearch.handleSubmit}
          >
            <FormGroup className="mb-0 position-relative">
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-search" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder={searchPlaceholder}
                  type="text"
                  {...formikSearch.getFieldProps("search")}
                  invalid={!!formikSearch.errors.search}
                />
              </InputGroup>
              <FormFeedback
                tooltip
                valid={!formikSearch.errors.search}
                className={`${formikSearch.errors.search && "d-block"}`}
              >
                {formikSearch.errors.search}
              </FormFeedback>
            </FormGroup>
            <Button type="submit" className="d-none"></Button>
          </Form>
        )}

        <Nav className="align-items-center d-none d-md-flex" navbar>
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle className="pr-0" nav>
              <p className="mb-0 text-sm font-weight-bold">{currentUserName}</p>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
              <DropdownItem className="noti-title" header tag="div">
                <h6 className="text-overflow m-0">Welcome!</h6>
              </DropdownItem>
              <DropdownItem to="/panel/settings" tag={Link}>
                <i className="ni ni-settings-gear-65" />
                <span>{t('admin-header.settings')}</span>
              </DropdownItem>
              <DropdownItem onClick={languageChangeHandler}>
                <i className="ni ni-world-2" />
                <span>{i18n.language === 'en' ? 'Русский' : 'English'}</span>
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem onClick={executeLogout}>
                <i className="ni ni-user-run" />
                <span>{t('admin-header.logout')}</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </Container>
    </Navbar>
  );
};

export default AdminHeader;
