import axios from 'axios'

const proxyIPFSrequest = async (link: string) => {
  try {
    const response = await axios.get(link)
    return response
  } catch (error) {
    return error.response.data
  }
}

export const ipfsService = { proxyIPFSrequest }