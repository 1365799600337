import i18n from "./i18n";

export let navigationLinks = [];

const fill = () => {
  navigationLinks = [
    {
      title: i18n.t("navigation.benefits"),
      href: "/",
      hash: "#benefits",
    },
    {
      title: i18n.t("navigation.features"),
      href: "/",
      hash: "#features",
    },
    {
      title: i18n.t("navigation.whatIs"),
      href: "/",
      hash: "#video",
    },
  ];
};

fill();

i18n.on("languageChanged init", () => {
  fill(); // fills myExport with new value for foo based on current i18n lng
});

export interface IJwt {
  id: number;
  email: string;
  name: string;
  role: number;
  preferredLanguage: any;
  isTwoFactorPassed: boolean;
  isTwoFactorEnabled: boolean;
}

export const enum VERIFY_REQUEST_STATUS {
  WAITING,
  APPROVED,
  REJECTED,
}

/*export const NAME_REGEXP = /^[A-Za-zÀ-ÖØ-öø-ÿ\s&,]+$/*/

export const videoUrl = `${process.env.REACT_APP_SERVER_URL}static/media/videos/bit-graduate-new.mp4`;
