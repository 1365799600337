import axiosWithToken from "./request";

interface INewInstitute {
  name: string;
  email: string;
}

interface IUpdateInstitute {
  name?: string;
  email?: string;
  isActive?: boolean;
  addLimit?: number;
}

const addNewInstitute = async (newInstitute: INewInstitute) => {
  try {
    const response = await axiosWithToken.post("institute", newInstitute);
    return response.data;
  } catch (error) {
    console.log(error.response);
    return error.response.data;
  }
};

const updateInstitute = async (id: number, newData: IUpdateInstitute) => {
  try {
    const response = await axiosWithToken.put(`institute/${id}`, newData);
    return response.status;
  } catch (error) {
    console.log(error.response);
    return error.response.data;
  }
};

const uploadInstituteLogo = async (id: number, logo: FormData) => {
  try {
    const response = await axiosWithToken.put(`institute/${id}/upload`, logo);
    return response.status;
  } catch (error) {
    console.log(error.response);
    return error.response.data;
  }
};

const getAvailableInstitutes = async () => {
  try {
    const response = await axiosWithToken.get("institute");
    return response.data;
  } catch (error) {
    console.log(error.response);
    return error.response.data;
  }
};

export const institutesService = {
  addNewInstitute,
  updateInstitute,
  getAvailableInstitutes,
  uploadInstituteLogo,
};
