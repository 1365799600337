import axiosWithToken from "./request";

interface IContactFormData {
  name: string;
  institute: string;
  email: string;
  comment: string;
}

const sendContactFormData = async (contactFormData: IContactFormData) => {
  try {
    const response = await axiosWithToken.post("support/access", { ...contactFormData });
    return response.data;
  } catch (error) {
    console.log(error.response);
    return error.response.data;
  }
};

export const contactService = { sendContactFormData };
