import "./button.scss";

import { ReactNode } from "react";
import classNames from "classnames";

interface IButtonProps {
  variant?: "primary" | "secondary" | "green" | "transparent" | "outline";
  image?: string;
  className?: string;
  disabled?: boolean;
  onClick?: (event: any) => void;
  children?: ReactNode;
}

export function Button(props: IButtonProps) {
  const { variant = "primary", image, className, disabled, onClick, children } = props;

  return (
    <button
      className={classNames("button", `button_${variant}`, className)}
      disabled={disabled}
      onClick={onClick}
    >
      {image && <img src={image} alt="" />}
      {children}
    </button>
  );
}
