import "./verifyTwoFactorForm.scss";

import { useFormik } from "formik";
import { useState } from "react";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import * as Yup from "yup";

import { authService } from "../../../services/auth.service";
import { useTranslation } from "react-i18next";

const verifyTwoFactorFormValidationSchema = Yup.object({
  authenticatorToken: Yup.string()
    .required("Please, enter a token")
    .matches(/[0-9]+/, "Token phrase includes numbers only")
    .length(6, "Token phrase must be 6 numbers long."),
});

interface IVerifyTwoFactorFormProps {
  onVerify: () => void;
}

export function VerifyTwoFactorForm({ onVerify }: IVerifyTwoFactorFormProps) {
  const [authError, setAuthError] = useState("");
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      authenticatorToken: "",
    },
    validationSchema: verifyTwoFactorFormValidationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: values => {
      twoFactorLogin(values.authenticatorToken);
    },
  });

  const twoFactorLogin = async (authenticatorToken: string) => {
    setIsFormSubmitting(true);

    const resp = await authService.twoFactorLogin(authenticatorToken);

    if (resp.error) {
      setAuthError(resp.error);
      setIsFormSubmitting(false);
      return;
    }
    if (!resp.data) {
      setAuthError(t("2fa.invalid"));
      setIsFormSubmitting(false);
      return;
    }

    onVerify();
  };

  return (
    <Form className="verify-two-factor-form" onSubmit={formik.handleSubmit}>
      <FormGroup className="verify-two-factor-form__form-group">
        <Label for="token">{t("2fa.token")}</Label>
        <Input
          id="token"
          placeholder={t("2fa.insertToken")}
          className="verify-two-factor-form__input"
          autoComplete="off"
          {...formik.getFieldProps("authenticatorToken")}
        />
        {formik.touched.authenticatorToken && formik.errors.authenticatorToken && (
          <small className="text-danger mt-1">{formik.errors.authenticatorToken}</small>
        )}
      </FormGroup>

      {authError && <small className="text-danger mb-1">{authError}</small>}
      <Button
        type="submit"
        color="primary"
        disabled={isFormSubmitting}
        className="verify-two-factor-form__submit-button"
      >
        {t("submit.verify")}
      </Button>
    </Form>
  );
}
