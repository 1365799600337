import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, Modal } from "reactstrap";
import info from "../../../assets/icons/info.svg";
import arrow from "../../../assets/icons/arrow.svg";
import Checkbox from "../../../components/Checkbox/Checkbox";
import { Button } from "../../../components/Button/Button";
import { IDiploma } from "../Diploma";
import './DeclineModal.scss'
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { certificatesService } from "../../../services/certificates.service";
import { careersService } from "../../../services/careers.service";
import { toast } from "react-toastify";

interface IDeclineModalProps {
  isDeclineModalOpen: boolean
  toggleDeclineModal: () => void
  certificate: IDiploma
  setCertificate: Dispatch<SetStateAction<IDiploma>>
}

const enum DeclineFields {
  OWNER = 'owner',
  CAREER = 'careerId',
  GRADUATION_DATE = 'graduationDate',
  ARE_DOCUMENTS_WRONG = 'areDocumentsWrong',
  NUMBER = 'number'
}

const DeclineModal = (props: IDeclineModalProps) => {
  const {isDeclineModalOpen, toggleDeclineModal, certificate, setCertificate} = props
  const {t} = useTranslation()
  const { id } = useParams();

  const [firstRender, setFirstRender] = useState(true)
  const [careerDropdown, setCareerDropdown] = useState(false)
  const [careersData, setCareersData] = useState([])

  const [modalState, setModalState] = useState({
    owner: false,
    careerId: false,
    graduationDate: false,
    number: false,
    areDocumentsWrong: false
  })

  const declineValidationSchema = Yup.object({
    owner: Yup.string(),
    careerId: Yup.number(),
    number: Yup.number(),
    graduationDate: Yup.string(),
    areDocumentsWrong: Yup.boolean()
  })

  const formikDecline = useFormik({
    initialValues: {
      owner: '',
      careerId: '',
      graduationDate: '',
      number: '',
      areDocumentsWrong: false
    },
    validationSchema: declineValidationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: () => {}
  })

  const [error, setError] = useState('')

  const checkHandler = (key: DeclineFields) => {
    if(key === DeclineFields.ARE_DOCUMENTS_WRONG) {
      formikDecline.setFieldValue(DeclineFields.ARE_DOCUMENTS_WRONG, !formikDecline.values.areDocumentsWrong)
    }
    setModalState(prevState => {
      const newState = {...prevState}
      newState[key] = !newState[key]
      return newState
    })
  }

  const submitHandler = async () => {
    const isEveryFieldEmpty = Object.values(formikDecline.values).every(item => !item)

    if(isEveryFieldEmpty) {
      setError(t('diploma.declineModal.emptyFields'))
      return
    }
    setError('')

    let finalObject = {
      areDocumentsWrong: false,
      certificateId: id,
    }

    Object.entries(modalState).forEach(([key, value]) => {
      if(!value) return

      finalObject[key] = formikDecline.values[key]
    })

    if(Object.values(finalObject).length === 2 && !finalObject.areDocumentsWrong) {
      setError(t('diploma.declineModal.emptyFields'))
      return
    }

    localStorage.removeItem('modalState')
    try {
      await certificatesService.declineCertificate(finalObject)
      toast.success(t('diploma.rejected'))
      setCertificate(prevState => {
        return {
          ...prevState,
          isApproved: true
        }
      })
      toggleDeclineModal()
    } catch (error) {
      if(error instanceof Error) {
        toast.error(error.message)
        return
      }

      toast.error(t('error.unexpected'))
    }
  }

  const choseCareer = (id: number) => {
    formikDecline.setFieldValue('careerId', id)
  }

  const toggle = () => setCareerDropdown((prevState) => !prevState);

  const getCareerNameById = (careerId: string) => {
    const career = careersData.find(item => item.id === careerId)

    return career.name
  }

  useEffect(() => {
    if(firstRender) return
    localStorage.setItem('modalState', JSON.stringify(modalState))
  }, [modalState])

  useEffect(() => {
    if(isDeclineModalOpen) {
      const prevState = localStorage.getItem('modalState')
      if(prevState) {
        setModalState(JSON.parse(prevState))
      }
    }
  }, [isDeclineModalOpen])

  useEffect(() => {
    setFirstRender(false)
    const getCareersHandler = async () => {
      try {
        const response = await careersService.getAvailableCertificateCareers(id)
        setCareersData(response)
      } catch {}
    }
    if(!certificate.isApproved && certificate.isOwner) {
      getCareersHandler()
    }

  }, [])

  return (
    <Modal
      isOpen={isDeclineModalOpen}
      toggle={toggleDeclineModal}
      centered
      className="decline-modal"
    >
      <div className="decline-modal__wrapper">
        <div className="decline-modal__header">
          <h5 className="decline-modal__header-title" id="exampleModalLabel">
            {t('diploma.declineModal.whatIs')}
          </h5>
          <img src={info} alt="info" />
        </div>
        <div className="decline-modal__body">
          <div>
            <div className="decline-modal__field">
              <Checkbox onChange={() => checkHandler(DeclineFields.OWNER)} isChecked={modalState.owner}/>
              <p>{t('diploma.declineModal.name')}</p>
            </div>
            {
              modalState.owner &&
              <div className="decline-modal__field">
                <input
                  type="text"
                  placeholder={`${t('diploma.declineModal.typeName')}${certificate.student})`}
                  {...formikDecline.getFieldProps(DeclineFields.OWNER)}/>
              </div>
            }
          </div>
          <div>
            <div className="decline-modal__field">
              <Checkbox onChange={() => checkHandler(DeclineFields.NUMBER)} isChecked={modalState.number}/>
              <p>{t('diploma.declineModal.physicalNumber')}</p>
            </div>
            {
              modalState.number &&
              <div className="decline-modal__field">
                <input
                  type="text"
                  placeholder={`${t('diploma.declineModal.typePhysical')}${certificate.physicalNumber})`}
                  {...formikDecline.getFieldProps(DeclineFields.NUMBER)}/>
              </div>
            }
          </div>
          <div>
            <div className="decline-modal__field">
              <Checkbox onChange={() => checkHandler(DeclineFields.CAREER)} isChecked={modalState.careerId}/>
              <p>{t('diploma.declineModal.career')}</p>
            </div>
            {
              modalState.careerId &&
              <div className="decline-modal__field">
                <Dropdown toggle={toggle} isOpen={careerDropdown} className="decline-modal__dropdown">
                  <DropdownToggle className="decline-modal__dropdown-toggle">
                    {formikDecline.values.careerId ? getCareerNameById(formikDecline.values.careerId) : t('diploma.declineModal.typeCareer')}
                    <img src={arrow} alt="" />
                  </DropdownToggle>
                  <DropdownMenu className="decline-modal__dropdown-menu">
                    {
                      careersData.map(item => {
                        return (
                          <DropdownItem key={item.id} onClick={() => choseCareer(item.id)} className="decline-modal__dropdown-item">
                            {item.name}
                          </DropdownItem>
                        )
                      })
                    }
                  </DropdownMenu>
                </Dropdown>
              </div>
            }
          </div>
          <div>
            <div className="decline-modal__field">
              <Checkbox onChange={() => checkHandler(DeclineFields.GRADUATION_DATE)} isChecked={modalState.graduationDate}/>
              <p>{t('diploma.declineModal.date')}</p>
            </div>
            {
              modalState.graduationDate &&
              <Input
                placeholder="Physical number of the diploma"
                type="date"
                className="pl-2 mb-3"
                {...formikDecline.getFieldProps(DeclineFields.GRADUATION_DATE)}
              />
            }
          </div>
          <div>
            <div className="decline-modal__field">
              <Checkbox onChange={() => checkHandler(DeclineFields.ARE_DOCUMENTS_WRONG)} isChecked={modalState.areDocumentsWrong}/>
              <p>{t('diploma.declineModal.wrong')}</p>
            </div>
          </div>
          <p className="decline-modal__error">{error}</p>
        </div>
        <div>
          <Button onClick={submitHandler} className="decline-modal__button">{t('submit.confirm')}</Button>
        </div>
      </div>
    </Modal>
  )
}

export default DeclineModal