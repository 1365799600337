import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { statsService } from "../../services/stats.service"

interface IAdminPanel {
  searchQuery: string | null
  searchPlaceholder: string
  requestedInstitute: string
  requestedEmployee: string
  statsData: {
    totalInstitutes?: number;
    totalEmployees?: number;
    totalCertificates: number;
    totalLimit: number;
  };
  createdCertificateId: string;
}

const initialState: IAdminPanel = {
  searchQuery: '',
  searchPlaceholder: 'Search...',
  requestedInstitute: '',
  requestedEmployee: '',
  statsData: {
    totalInstitutes: 0,
    totalEmployees: 0,
    totalCertificates: 0,
    totalLimit: 0,
  },
  createdCertificateId: null
}

export const fetchOverallStats = createAsyncThunk(
  'adminPanel/fetchOverallStats',
  async () => {
    const resp = await statsService.getOverallStat()
    // console.log(resp)
    return resp
  }
)

export const adminPanelSlice = createSlice({
  name: 'adminPanel',
  initialState,
  reducers: {
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload
    },
    setSearchPlaceholder: (state, action) => {
      state.searchPlaceholder = action.payload
    },
    setRequstedInstitute: (state, action) => {
      state.requestedInstitute = action.payload
    },
    setRequestedEmployee: (state, action) => {
      state.requestedEmployee = action.payload
    },
    setCreatedCertificateId: (state, action) => {
      state.createdCertificateId = action.payload
    }
  },
  extraReducers: {
    //@ts-ignore
    [fetchOverallStats.fulfilled]: (state: IAdminPanel, { payload }) => {
      state.statsData = payload
    }
  }
})

export const { setSearchQuery, setSearchPlaceholder, setRequstedInstitute, setRequestedEmployee, setCreatedCertificateId } = adminPanelSlice.actions

export default adminPanelSlice.reducer