import axiosWithToken from "./request";

const getDocuments = async () => {
  try {
    const response = await axiosWithToken.get("certificate");
    return response.data;
  } catch (error) {
    throw new Error(error.response.data);
  }
};

const sendDocumentByEmail = async (certificateId: string, email: string) => {
  try {
    const response = await axiosWithToken.post(`certificate/${certificateId}/send`, { email });
    return response.data;
  } catch (error) {
    console.log(error.response);
    return error.response.data;
  }
};

const checkEmail = async (email: string) => {
  try {
    const response = await axiosWithToken.post("user/email/check", { email });
    return response.data;
  } catch (error) {
    console.log(error.response);
    return error.response.data;
  }
};

const transferDocument = async (
  certificateId: string,
  email: string,
  authenticatorToken: string
) => {
  try {
    const response = await axiosWithToken.put(`certificate/${certificateId}/owner`, {
      email,
      authenticatorToken,
    });
    return response;
  } catch (error) {
    console.log(error.response);
    return error.response.data;
  }
};

export const studentService = { getDocuments, sendDocumentByEmail, checkEmail, transferDocument };
