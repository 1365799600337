import React, { useEffect, useState } from "react";
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Table,
  Container,
  Row,
  Button,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import AdminHeader from "../../components/AdminComponents/AdminHeader";
import AdminStatCards from "../../components/AdminComponents/AdminStatCards";
import { ConfirmationPopup } from "../../components/AdminComponents/ConfirmationPopup";
import { toast } from "react-toastify";
import {
  WsFilterTypes,
  WsOrderTypes,
  WsOrderValues,
  WsResponseAction,
  WsTable,
} from "../../app/models/ws.interface";
import ReactPaginate from "react-paginate";
import { useAppDispatch, useAppSelector } from "../../app/hooks/hooks";
import {
  setRequstedInstitute,
  setSearchPlaceholder,
  setSearchQuery,
  fetchOverallStats,
} from "../../app/state/adminPanelSlice";
import { AddEmployeePopup } from "../../components/AdminComponents/AddEmployeePopup";
import { EditEmployeePopup } from "../../components/AdminComponents/EditEmployeePopup";
import { employeesService } from "../../services/employees.service";
import { UserRole } from "../../app/models/role.interface";
import { institutesService } from "../../services/institutes.service";
import { IEmployee, IInstituteCheckbox } from "../../app/models/adminPanel.interface";
import { useAdminWebSocket } from "../../app/hooks/useAdminWebSocket";
import { useTranslation } from "react-i18next";

const EmployeesTable = () => {
  const dispatch = useAppDispatch();
  const {
    id: currentUserId,
    name: currentUserName,
    role: currentUserRole,
  } = useAppSelector(state => state.user);
  const { searchQuery, requestedInstitute } = useAppSelector(state => state.adminPanel);

  const [selectedEmployee, setSelectedEmployee] =
    useState<Pick<IEmployee, "id" | "name" | "instituteId" | "email" | "facultyName">>(null);
  const [newEmployeePopup, setNewEmployeePopup] = useState(false);
  const [deleteEmployeePopup, setDeleteEmployeePopup] = useState(false);
  const [activateEmployeePopup, setActivateEmployeePopup] = useState(false);
  const [deactivateEmployeePopup, setDeactivateEmployeePopup] = useState(false);
  const [editEmployeePopup, setEditEmployeePopup] = useState(false);

  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [isSorting, setIsSorting] = useState(false);
  const { t } = useTranslation();

  const [selectedFilterName, setSelectedFilterName] = useState(null);

  const [allInstitutes, setAllInstitutes] = useState<IInstituteCheckbox[]>([]);

  const { setTableRequest, setFilters, pageCount, filters, tableRequest, data } =
    useAdminWebSocket<IEmployee>(WsTable.employees, WsResponseAction.employeesTable);

  // Handlers
  const handlePageClick = (event: any) => {
    setTableRequest(prevState => ({ ...prevState, page: event.selected + 1 }));
  };

  const performFiltration = (
    orderBy: WsOrderValues,
    orderType: WsOrderTypes,
    filterName: string
  ) => {
    setTableRequest(prevState => ({ ...prevState, page: 1, orderBy, orderType }));
    setSelectedFilterName(filterName);
    setIsSorting(true);
  };

  const resetFiltration = () => {
    setTableRequest(prevState => ({
      ...prevState,
      page: 1,
      orderBy: WsOrderValues.createdDate,
      orderType: WsOrderTypes.desc,
    }));
    setSelectedFilterName(null);
    const uncheckedInstitutes = [...allInstitutes];
    for (let i of uncheckedInstitutes) {
      i.checked = false;
    }
    setAllInstitutes(uncheckedInstitutes);
    setIsSorting(false);
  };

  const openDeleteEmployeePopup = (id: number) => {
    setDeleteEmployeePopup(true);
    const editedEmployee = data.filter(a => a.id === id)[0];
    setSelectedEmployee(editedEmployee);
  };
  const openActivateEmployeePopup = (id: number) => {
    setActivateEmployeePopup(true);
    const editedEmployee = data.filter(a => a.id === id)[0];
    setSelectedEmployee(editedEmployee);
  };
  const openDeactivateEmployeePopup = (id: number) => {
    const editedEmployee = data.filter(a => a.id === id)[0];
    setSelectedEmployee(editedEmployee);
    setDeactivateEmployeePopup(true);
  };
  const openEditEmployeePopup = (id: number) => {
    const editedEmployee = data.filter(a => a.id === id)[0];
    setSelectedEmployee(editedEmployee);
    setEditEmployeePopup(true);
  };

  const deleteEmployee = async () => {
    const toastLoader = toast.loading(t("admin-loading.delete-employee"));
    // console.log(selectedAdmin);
    setIsFormSubmitting(true);
    await employeesService.deleteEmployee(selectedEmployee.id);
    toast.update(toastLoader, {
      render: t("employees-table.employee-deleted"),
      type: "success",
      isLoading: false,
      autoClose: 3000,
    });
    setDeleteEmployeePopup(false);
    dispatch(fetchOverallStats());
    setIsFormSubmitting(false);
  };

  const activateEmployee = async () => {
    setIsFormSubmitting(true);
    const resp = await employeesService.updateEmployee(selectedEmployee.id, { isActive: true });
    if (resp.error) {
      toast.error(resp.error);
      return;
    }
    toast.success(t("employees-table.employee-activated"));
    setActivateEmployeePopup(false);
    setIsFormSubmitting(false);
  };

  const deactivateEmployee = async () => {
    setIsFormSubmitting(true);
    const resp = await employeesService.updateEmployee(selectedEmployee.id, { isActive: false });
    if (resp.error) {
      toast.error(resp.error);
      return;
    }
    toast.success(t("employees-table.employee-deactivated"));
    setDeactivateEmployeePopup(false);
    setIsFormSubmitting(false);
  };

  const handleInstituteCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = event.target;
    const index = allInstitutes.findIndex(i => i.id === +id);
    const newArray = [...allInstitutes];
    newArray[index].checked = checked;
    setAllInstitutes(newArray);
    setIsSorting(true);

    if (requestedInstitute !== "" && requestedInstitute.toString() === id) {
      dispatch(setRequstedInstitute(""));
    }
  };

  const copyWalletToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(() => toast.success(t("employees-table.copied")));
  };

  // Search
  useEffect(() => {
    const fIndex = filters.findIndex(f => f.filterType === WsFilterTypes.byDefault);
    const newFilter = [...filters];
    newFilter[fIndex].filterVariable = searchQuery;
    setFilters(newFilter);
  }, [searchQuery]);

  useEffect(() => {
    dispatch(setSearchPlaceholder(t("employees-table.search-placeholder")));

    return () => {
      dispatch(setSearchQuery(""));
    };
  }, [dispatch]);

  // Get requested employee list from institutes table
  useEffect(() => {
    if (requestedInstitute !== "") {
      const newFilter = [...filters];
      newFilter.push({
        filterType: WsFilterTypes.byIntitutes,
        filterVariable: requestedInstitute.toString(),
      });
      setFilters(newFilter);
    }
    return () => {
      if (requestedInstitute !== "") {
        dispatch(setRequstedInstitute(""));
      }
    };
  }, [dispatch, requestedInstitute]);

  // Get institutes list for dropdown
  useEffect(() => {
    const getAllInstitutes = async () => {
      const resp = await institutesService.getAvailableInstitutes();
      if (!resp.error) {
        // add checked false to all entries
        const instWithChecks = resp.map(o => {
          return { ...o, checked: false };
        });
        if (requestedInstitute) {
          const idx = instWithChecks.findIndex(i => i.id === +requestedInstitute);
          instWithChecks[idx].checked = true;
        }
        setAllInstitutes(instWithChecks);
      }
    };
    if (currentUserRole <= UserRole.admin) {
      getAllInstitutes();
    } else {
      const currentInstitute = { id: currentUserId, name: currentUserName, checked: false };
      setAllInstitutes([currentInstitute]);
    }
  }, [currentUserId, currentUserName, currentUserRole, requestedInstitute]);

  // Perform checkbox filtration
  useEffect(() => {
    if (allInstitutes.length === 0) {
      return;
    }
    const selectedCheckboxes = [];
    for (let a of allInstitutes) {
      if (a.checked) {
        selectedCheckboxes.push(a.id);
      } else {
        selectedCheckboxes.splice(a.id, 1);
      }
    }

    const fIndex = filters.findIndex(f => f.filterType === WsFilterTypes.byIntitutes);
    const newFilter = [...filters];

    if (selectedCheckboxes.length > 0) {
      if (fIndex > -1) {
        newFilter[fIndex].filterVariable = selectedCheckboxes.join();
      } else {
        newFilter.push({
          filterType: WsFilterTypes.byIntitutes,
          filterVariable: selectedCheckboxes.join(),
        });
      }
    } else {
      if (fIndex > -1) {
        newFilter.splice(fIndex, 1);
      }
    }
    setFilters(newFilter);
  }, [allInstitutes]);

  // Observe filter change
  useEffect(() => {
    setTableRequest(prevState => ({ ...prevState, filters }));
  }, [filters]);

  return (
    <>
      <AdminHeader />

      <AdminStatCards />

      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="d-flex flex-column justify-content-between flex-md-row">
                  <h3 className="mb-1 mb-md-0">{t("employees-table.title")}</h3>
                  <div className="row justify-content-end">
                    <Button
                      color="primary"
                      className="mb-1 mb-lg-0"
                      onClick={() => setNewEmployeePopup(true)}
                    >
                      {t("employees-table.add")}
                    </Button>

                    {/* FILTER by institution */}
                    {currentUserRole <= UserRole.admin && (
                      <UncontrolledDropdown>
                        <DropdownToggle role="button" color="primary" outline caret>
                          {t("admin-panel.filters.institution")}
                        </DropdownToggle>
                        <DropdownMenu
                          className="dropdown-menu-arrow"
                          right
                          style={{ maxHeight: 500, overflowY: "auto" }}
                        >
                          {allInstitutes.length > 0 &&
                            allInstitutes.map(i => (
                              <DropdownItem key={i.id} toggle={false}>
                                <FormGroup check inline className="w-100">
                                  <Input
                                    type="checkbox"
                                    id={i.id.toString()}
                                    name={i.name}
                                    value={i.name}
                                    onChange={e => handleInstituteCheckbox(e)}
                                    defaultChecked={i.checked}
                                  />
                                  <Label
                                    check
                                    for={i.id.toString()}
                                    className="w-100"
                                    role="button"
                                  >
                                    {i.name}
                                  </Label>
                                </FormGroup>
                              </DropdownItem>
                            ))}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    )}

                    {/* FILTER */}
                    <UncontrolledDropdown>
                      <DropdownToggle
                        role="button"
                        color="primary"
                        outline
                        caret
                        style={{
                          maxWidth: 315,
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {selectedFilterName ? selectedFilterName : t("admin-panel.filters.base")}
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-arrow" right>
                        <DropdownItem
                          onClick={() =>
                            performFiltration(
                              WsOrderValues.alphabetically,
                              WsOrderTypes.asc,
                              t("admin-panel.filters.sortAZasc")
                            )
                          }
                        >
                          {t("admin-panel.filters.sortAZasc")}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            performFiltration(
                              WsOrderValues.alphabetically,
                              WsOrderTypes.desc,
                              t("admin-panel.filters.sortAZdesc")
                            )
                          }
                        >
                          {t("admin-panel.filters.sortAZdesc")}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            performFiltration(
                              WsOrderValues.numberOfEmployeeCerificates,
                              WsOrderTypes.desc,
                              t("employees-table.filter.byCertificatesNumberDESC")
                            )
                          }
                        >
                          {t("employees-table.filter.byCertificatesNumberDESC")}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            performFiltration(
                              WsOrderValues.numberOfEmployeeCerificates,
                              WsOrderTypes.asc,
                              t("employees-table.filter.byCertificatesNumberASC")
                            )
                          }
                        >
                          {t("employees-table.filter.byCertificatesNumberASC")}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            performFiltration(
                              WsOrderValues.createdDate,
                              WsOrderTypes.desc,
                              t("admin-panel.filters.dateNewest")
                            )
                          }
                        >
                          {t("admin-panel.filters.dateNewest")}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            performFiltration(
                              WsOrderValues.createdDate,
                              WsOrderTypes.asc,
                              t("admin-panel.filters.dateOldest")
                            )
                          }
                        >
                          {t("admin-panel.filters.dateOldest")}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            performFiltration(
                              WsOrderValues.activeStatus,
                              WsOrderTypes.asc,
                              t("employees-table.filter.deactivated")
                            )
                          }
                        >
                          {t("employees-table.filter.deactivated")}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            performFiltration(
                              WsOrderValues.activeStatus,
                              WsOrderTypes.desc,
                              t("employees-table.filter.activated")
                            )
                          }
                        >
                          {t("employees-table.filter.activated")}
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    {isSorting && (
                      <Button
                        color="secondary"
                        className="mb-1 mr-2 mb-lg-0"
                        onClick={resetFiltration}
                      >
                        {t("admin-panel.filters.reset")}
                      </Button>
                    )}
                  </div>
                </div>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">{t("admin-panel.name")}</th>
                    <th scope="col">{t("admin-panel.email")}</th>
                    <th scope="col">{t("admin-panel.certificates-issued")}</th>
                    {/* !!! CHECK ADMIN ONLY */}
                    {currentUserRole <= UserRole.admin && <th>{t("admin-panel.institution")}</th>}
                    {currentUserRole <= UserRole.insitute && <th>{t("admin-panel.faculty")}</th>}
                    <th scope="col">{t("admin-panel.wallet")}</th>
                    <th scope="col">{t("admin-panel.status")}</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {data.map(employee => (
                    <tr key={employee.id}>
                      <td>{employee.name}</td>
                      <td>{employee.email}</td>
                      <td>{employee.issuedCertificates}</td>
                      {currentUserRole <= UserRole.admin && <td>{employee.instituteName}</td>}
                      {currentUserRole <= UserRole.insitute && <td>{employee.facultyName}</td>}
                      <td onClick={() => copyWalletToClipboard(employee.walletAddress)}>
                        {employee.walletAddress}
                      </td>
                      <td>
                        {employee.isActive ? (
                          <Badge color="" className="badge-dot mr-4">
                            <i className="bg-success" />
                            {t("admin-panel.active")}
                          </Badge>
                        ) : (
                          <Badge color="" className="badge-dot mr-4">
                            <i className="bg-danger" />
                            {t("admin-panel.inactive")}
                          </Badge>
                        )}
                      </td>
                      <td className="text-right">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            role="button"
                            size="sm"
                            color=""
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right container="body">
                            <DropdownItem onClick={() => openEditEmployeePopup(employee.id)}>
                              {t("admin-panel.edit")}
                            </DropdownItem>
                            <DropdownItem
                              onClick={
                                employee.isActive
                                  ? () => openDeactivateEmployeePopup(employee.id)
                                  : () => openActivateEmployeePopup(employee.id)
                              }
                            >
                              {employee.isActive
                                ? t("admin-panel.deactivate")
                                : t("admin-panel.activate")}
                            </DropdownItem>
                            <DropdownItem onClick={() => openDeleteEmployeePopup(employee.id)}>
                              {t("admin-panel.delete")}
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel={<i className="fas fa-angle-right" />}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={0}
                    pageCount={pageCount}
                    previousLabel={<i className="fas fa-angle-left" />}
                    className="pagination justify-content-end mb-0"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    activeClassName="active"
                    disabledClassName="disabled"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    forcePage={tableRequest.page - 1}
                  />
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>

      {/* POPUPS */}
      <AddEmployeePopup
        newEmployeePopup={newEmployeePopup}
        setNewEmployeePopup={setNewEmployeePopup}
        allInstitutes={allInstitutes}
      />
      <EditEmployeePopup
        editEmployeePopup={editEmployeePopup}
        setEditEmployeePopup={setEditEmployeePopup}
        selectedEmployee={selectedEmployee}
      />
      <ConfirmationPopup
        title={t("confirmation-popup.title.activate-employee")}
        text={t("confirmation-popup.text.activate-employee")}
        popupState={activateEmployeePopup}
        handlePopup={setActivateEmployeePopup}
        submitAction={activateEmployee}
        btnText={t("confirmation-popup.btn-text.activate")}
        btnColor="success"
        isFormSubmitting={isFormSubmitting}
      />
      <ConfirmationPopup
        title={t("confirmation-popup.title.deactivate-employee")}
        text={t("confirmation-popup.text.deactivate-employee")}
        popupState={deactivateEmployeePopup}
        handlePopup={setDeactivateEmployeePopup}
        submitAction={deactivateEmployee}
        btnText={t("confirmation-popup.btn-text.deactivate")}
        btnColor="primary"
        isFormSubmitting={isFormSubmitting}
      />
      <ConfirmationPopup
        title={t("confirmation-popup.title.delete-employee")}
        text={t("confirmation-popup.text.delete-employee")}
        popupState={deleteEmployeePopup}
        handlePopup={setDeleteEmployeePopup}
        submitAction={deleteEmployee}
        btnText={t("confirmation-popup.btn-text.delete")}
        btnColor="danger"
        isFormSubmitting={isFormSubmitting}
      />
    </>
  );
};

export default EmployeesTable;
