import React, { useState } from "react";
import { NavLink as NavLinkRRD, Link, useNavigate } from "react-router-dom";
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  Input,
  InputGroup,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Button,
} from "reactstrap";
import { useAppDispatch, useAppSelector } from "../../app/hooks/hooks";
import { UserRole } from "../../app/models/role.interface";
import { setLoggedOut } from "../../app/state/userSlice";
import { authService } from "../../services/auth.service";
import logoIcon from "../../assets/icons/logoWhite.png";
import logoRed from "../../assets/icons/logo-new.svg";
import { setSearchQuery } from "../../app/state/adminPanelSlice";
import { useTranslation } from "react-i18next";

const Sidebar = () => {
  const dispatch = useAppDispatch();
  const searchPlaceholder = useAppSelector(state => state.adminPanel.searchPlaceholder);
  const userRole = useAppSelector(state => state.user.role);
  const navigate = useNavigate();
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [searchMobile, setSearchMobile] = useState("");
  const isSettingsPage = window.location.pathname.includes("settings");

  const { t } = useTranslation();

  const toggleCollapse = () => {
    setCollapseOpen(!collapseOpen);
  };

  const closeCollapse = () => {
    setCollapseOpen(false);
  };

  const handleSearchInputMobile = ({ target: { value } }) => {
    setSearchMobile(value);
  };

  const handleSearch = event => {
    event.preventDefault();
    dispatch(setSearchQuery(searchMobile));
  };

  const executeLogout = async () => {
    await authService.logout();
    navigate("/");
    dispatch(setLoggedOut());
  };

  return (
    <Navbar
      className="navbar-vertical fixed-left navbar-light bg-white"
      expand="md"
      id="sidenav-main"
    >
      <Container fluid>
        {/* Toggler */}
        <button className="navbar-toggler" type="button" onClick={toggleCollapse}>
          <span className="navbar-toggler-icon" />
        </button>
        {/* Brand */}
        <NavbarBrand className="d-flex align-items-center justify-content-center" style={{ fontFamily: "Value Sans Pro" }}>
          <img className="mr-2" src={logoRed} alt="" />
          BitGraduate
        </NavbarBrand>
        {/* User */}
        <Nav className="align-items-center d-md-none">
          <UncontrolledDropdown nav>
            <DropdownToggle nav>
              <Media className="align-items-center">
                <span className="avatar avatar-sm rounded-circle">
                  <img alt="..." src={logoIcon} />
                </span>
              </Media>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
              <DropdownItem className="noti-title" header tag="div">
                <h6 className="text-overflow m-0">{t("admin-sidebar.welcome")}</h6>
              </DropdownItem>
              <DropdownItem to="/panel/settings" tag={Link}>
                <i className="ni ni-settings-gear-65" />
                <span>{t("admin-sidebar.settings")}</span>
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem onClick={executeLogout}>
                <i className="ni ni-user-run" />
                <span>{t("admin-sidebar.logout")}</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
        {/* Collapse */}
        <Collapse navbar isOpen={collapseOpen}>
          {/* Collapse header */}
          <div className="navbar-collapse-header d-md-none">
            <div className="d-flex justify-content-center">
              <Button color="primary" outline close onClick={toggleCollapse}>
                &#x2716;
              </Button>
            </div>
          </div>
          {/* Search */}
          {!isSettingsPage && (
            <Form className="mt-4 mb-3 d-md-none" onSubmit={e => handleSearch(e)}>
              <InputGroup className="input-group-rounded input-group-merge">
                <Input
                  aria-label="Search"
                  className="form-control-rounded form-control-prepended"
                  placeholder={searchPlaceholder}
                  type="text"
                  onChange={e => handleSearchInputMobile(e)}
                />
              </InputGroup>
              <button type="submit" className="d-none"></button>
            </Form>
          )}
          {/* Navigation */}
          <Nav navbar>
            {userRole === UserRole.rootAdmin && (
              <NavItem>
                <NavLink
                  to="admins"
                  tag={NavLinkRRD}
                  onClick={closeCollapse}
                  activeclassname="active"
                >
                  <i className="ni ni-bullet-list-67 text-red" />
                  {t("admin-sidebar.admins")}
                </NavLink>
              </NavItem>
            )}
            {userRole <= UserRole.admin && (
              <NavItem>
                <NavLink
                  to="institutions"
                  tag={NavLinkRRD}
                  onClick={closeCollapse}
                  activeclassname="active"
                >
                  <i className="ni ni-hat-3 text-indigo" />
                  {t("admin-sidebar.institutes")}
                </NavLink>
              </NavItem>
            )}
            {userRole <= UserRole.insitute && (
              <NavItem>
                <NavLink
                  to="employees"
                  tag={NavLinkRRD}
                  onClick={closeCollapse}
                  activeclassname="active"
                >
                  <i className="ni ni-badge text-green" />
                  {t("admin-sidebar.employees")}
                </NavLink>
              </NavItem>
            )}
            {userRole <= UserRole.insitute && (
              <NavItem>
                <NavLink
                  to="faculties"
                  tag={NavLinkRRD}
                  onClick={closeCollapse}
                  activeclassname="active"
                >
                  <i className="ni ni-books text-black-50" />
                  {t("admin-sidebar.faculties")}
                </NavLink>
              </NavItem>
            )}
            {userRole <= UserRole.insitute && (
              <NavItem>
                <NavLink
                  to="careers"
                  tag={NavLinkRRD}
                  onClick={closeCollapse}
                  activeclassname="active"
                >
                  <i className="ni ni-briefcase-24 text-orange" />
                  {t("admin-sidebar.careers")}
                </NavLink>
              </NavItem>
            )}
            <NavItem>
              <NavLink
                to="certificates"
                tag={NavLinkRRD}
                onClick={closeCollapse}
                activeclassname="active"
              >
                <i className="ni ni-paper-diploma text-cyan" />
                {t("admin-sidebar.certificates")}
              </NavLink>
            </NavItem>
            {((userRole <= UserRole.employee && userRole > UserRole.admin) ||
              userRole === UserRole.rootAdmin) && (
              <NavItem>
                <NavLink
                  to="verify-requests"
                  tag={NavLinkRRD}
                  onClick={closeCollapse}
                  activeclassname="active"
                >
                  <i className="ni ni-send text-red" />
                  {t("admin-sidebar.requests")}
                </NavLink>
              </NavItem>
            )}
            <NavItem>
              <NavLink
                to="settings"
                tag={NavLinkRRD}
                onClick={closeCollapse}
                activeclassname="active"
              >
                <i className="ni ni-settings text-blue" />
                {t("admin-sidebar.settings")}
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
};

export default Sidebar;
