export enum WsAction {
  tableConnect = 'table_connect',
  getTable = 'get_table',
}

export enum WsResponseAction {
  systemMessages = 'system_messages',
  adminsTable = 'ADMINS',
  intitutesTable = 'INSTITUTES',
  facultiesTable = 'FACULTIES',
  employeesTable = 'EMPLOYEES',
  certificatesTable = 'CERTIFICATES',
  careersTable = 'CAREERS',
  verifyRequestsTable = 'VERIFY_REQUESTS',
}

export enum WsTable {
  admins = 0,
  institutions = 1,
  employees = 2,
  certificates = 3,
  careers = 4,
  verifyRequests = 5,
  faculties = 6
}

export enum WsOrderValues {
  alphabetically = 0,
  numberOfEmployees = 1,
  numberOfIntitutesCertificates = 2,
  numberOfEmployeeCerificates = 3,
  byLimit = 4,
  createdDate = 5,
  activeStatus = 6,
  diplomaNumber = 7,
  diplomaOwnerName = 8,
  certificateId = 9,
  id = 10,
  instituteId = 11
}

export enum WsOrderTypes {
  asc = "ASC",
  desc = "DESC"
}

export enum WsFilterTypes {
  byDefault = 0,
  byIntitutes = 1,
  byEmployees = 2,
  byBurnedCertificates = 3,
  getBurned = 4,
  byStatus
}

export interface ITableRequest {
  page: number;
  limit: number;
  filters: any[];
  // {
  //   filterType: number;
  //   filterVariable: string;
  // }
  orderBy: number;
  orderType: string;
}
