import axios from "axios";
import { setLoggedOut } from "../app/state/userSlice";
import { store } from "../app/store";

const API_URL = process.env.REACT_APP_SERVER_URL + 'api/'

const axiosWithToken = () => {
  const instance = axios.create({
    baseURL: API_URL,
  })

  instance.interceptors.request.use(config => {
    const tokens = JSON.parse(localStorage.getItem('tokens'))
    if (tokens) {
      config.headers.Authorization = `Bearer ${tokens.accessToken}`
    }
    return config
  })

  instance.interceptors.response.use(response => response.data)

  instance.interceptors.response.use((config) => {
   return config
  }, async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && error.config && !error.config._isRetry && originalRequest.url !== 'user/current/password') {
      originalRequest._isRetry = true;
      try {
        const { refreshToken } = JSON.parse(localStorage.getItem("tokens"));
        if (!refreshToken) return
        const response = await axios.post(`${API_URL}auth/refresh-token`, {
          refresh_token: refreshToken,
        });
        const userTokens = JSON.stringify(response.data.data);
        localStorage.setItem("tokens", userTokens);
        return instance.request(originalRequest);
      } catch (e) {
        console.log('Не авторизован');
        localStorage.removeItem("tokens");
        store.dispatch(setLoggedOut())
      }
    }

    throw error
  })

  return instance
}

export default axiosWithToken()