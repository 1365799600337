import type { RefObject } from 'react'
import { useEffect } from 'react'

import { useLatest } from './useLatest'

export function useOutsideClick(
  elementReference: RefObject<any>,
  handler: () => void,
  attached = true
) {
  const latestHandler = useLatest(handler)

  useEffect(() => {
    if (!attached) return
    const handleClick = (e: MouseEvent) => {
      if (!elementReference.current) return
      if (!elementReference.current.contains(e.target)) {
        latestHandler.current()
      }
    }

    document.addEventListener('click', handleClick)

    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [elementReference, latestHandler, attached])
}