import "./newUserSettings.scss";

import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Form,
  FormGroup,
  Input,
  Button,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";

import { userService } from "../../services/user.service";
import { authService } from "../../services/auth.service";

import landscapeLogo from "../../assets/images/landscape-logo-blue.png";

import { useTranslation } from "react-i18next";

const newUserSettingsValidationSchema = Yup.object({
  newUserPassword: Yup.string()
    .required("Field is required")
    .min(6, "Minimum password length is 6 characters.")
    .max(15, "Maximum password length is 15 characters.")
    .matches(
      /(?=.*[0-9])(?=.*[~`!@#$%^&*().\/\\\[\]{}\-_=+><;:?,|'"])(?=.*[a-z])(?=.*[A-Z])/,
      "At least one uppercase English letter, one lower case, one digit and one special character."
    ),
  newUserPasswordConfirm: Yup.string()
    .required("Field is required")
    .min(6, "Minimum password length is 6 characters.")
    .max(15, "Maximum password length is 15 characters.")
    .matches(
      /(?=.*[0-9])(?=.*[~`!@#$%^&*().\/\\\[\]{}\-_=+><;:?,|'"])(?=.*[a-z])(?=.*[A-Z])/,
      "At least one uppercase English letter, one lower case, one digit and one special character."
    )
    .test("passwords-match", "Passwords must match", function (value) {
      return this.parent.newUserPassword === value;
    }),
});

const NewUserSettings = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [showInvalidTokenMsg, setShowInvalidTokenMsg] = useState(false);

  const [showNewPass, setShowNewPass] = useState(false);
  const [showNewPassConfirm, setShowNewPassConfirm] = useState(false);
  const {t} = useTranslation()

  const formik = useFormik({
    initialValues: {
      newUserPassword: "",
      newUserPasswordConfirm: "",
    },
    validationSchema: newUserSettingsValidationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: values => {
      updatePassword(values.newUserPassword);
    },
  });

  const updatePassword = async (newPassword: string) => {
    await userService.updatePasswordByToken(token, newPassword);
    toast.success(t("newUserSetting.success"));
    navigate("/auth");
  };

  useEffect(() => {
    const verifyToken = async () => {
      const resp = await authService.verifyPasswordToken(token);
      if (resp.error || resp.isValid === false) {
        setShowInvalidTokenMsg(true);
      }
    };
    verifyToken();
  }, []);

  return (
    <>
      <main className="new-user-settings">
        <div className="auth-page__form-wrapper">
          <Link to={"/"} className="new-user-settings__logo">
            <img src={landscapeLogo} alt="BitGraduate" />
          </Link>

          {showInvalidTokenMsg ? (
            <>
              <p>{t('newUserSetting.tokenIncorrect')}</p>
              <Link to="/">{t('newUserSetting.goBack')}</Link>
            </>
          ) : (
            <Form className="new-user-settings__form" onSubmit={formik.handleSubmit}>
              <FormGroup className="new-user-settings__form-group">
                <Label for="newUserPassword">{t('newUserSetting.newPassword')}</Label>
                <InputGroup>
                  <Input
                    id="newUserPassword"
                    type={showNewPass ? "text" : "password"}
                    placeholder={t("newUserSetting.enterPassword")}
                    className="new-user-settings__input"
                    {...formik.getFieldProps("newUserPassword")}
                  />
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowNewPass(!showNewPass)}
                    >
                      <i className="fas fa-eye" />
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
                {formik.touched.newUserPassword && formik.errors.newUserPassword && (
                  <small className="text-danger mt-1">{formik.errors.newUserPassword}</small>
                )}
              </FormGroup>

              <FormGroup className="new-user-settings__form-group">
                <Label for="newUserPasswordConfirm">{t('newUserSetting.confirmPassword')}</Label>
                <InputGroup>
                  <Input
                    id="newUserPasswordConfirm"
                    type={showNewPassConfirm ? "text" : "password"}
                    placeholder={t('newUserSetting.confirmPassword')}
                    className="new-user-settings__input"
                    {...formik.getFieldProps("newUserPasswordConfirm")}
                  />
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowNewPassConfirm(!showNewPassConfirm)}
                    >
                      <i className="fas fa-eye" />
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
                {formik.touched.newUserPasswordConfirm && formik.errors.newUserPasswordConfirm && (
                  <small className="text-danger mt-1">{formik.errors.newUserPasswordConfirm}</small>
                )}
              </FormGroup>

              <Button type="submit" color="primary" className="new-user-settings__submit-button">
                {t('submit.savePassword')}
              </Button>
            </Form>
          )}

          <p className="new-user-settings__owner">© 2022 BitGraduate</p>
        </div>
      </main>

      {/* <div className="main-content">
<div className="header pb-7 pt-6 pt-lg-8 d-flex align-items-center">
  <span className="mask bg-gradient-default opacity-8" />
  <Container className="d-flex align-items-center" fluid>
    <Row className="ml-lg-4">
      <Col>
        <h1 className="display-2 text-white">Welcome New User!</h1>
        <p className="text-white mt-0 mb-5">Here you can set your password</p>
      </Col>
    </Row>
  </Container>
</div>

<Container className="mt--7" fluid>
  <Row className="ml-lg-8">
    <Col className="order-xl-1" xl="6">
      <Card className="shadow px-4 py-4">
        {showInvalidTokenMsg ? (
          <CardBody>
            <p>Your password change token has expired or is incorrect</p>
            <Link to="/">Go to main page</Link>
          </CardBody>
        ) : (
          <CardBody>
            <Form onSubmit={formikNewUserPass.handleSubmit}>
              <Row>
                <Col className="lg-6">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-new-password">
                      New password
                    </label>
                    <InputGroup className="input-group-alternative">
                      <Input
                        className="form-control-alternative"
                        placeholder="Enter new password"
                        type={showNewPass ? "text" : "password"}
                        id="newUserPassword"
                        {...formikNewUserPass.getFieldProps("newUserPassword")}
                        invalid={!!formikNewUserPass.errors.newUserPassword}
                      />
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText
                          onClick={() => setShowNewPass(!showNewPass)}
                          style={{ cursor: "pointer" }}
                        >
                          <i className="fas fa-eye" />
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                    <FormFeedback invalid="true" className="d-block">
                      {formikNewUserPass.errors.newUserPassword}
                    </FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-confirm-password">
                      Confirm new password
                    </label>
                    <InputGroup className="input-group-alternative">
                      <Input
                        className="form-control-alternative"
                        placeholder="Confirm new password"
                        type={showNewPassConfirm ? "text" : "password"}
                        id="newUserPasswordConfirm"
                        {...formikNewUserPass.getFieldProps("newUserPasswordConfirm")}
                        invalid={!!formikNewUserPass.errors.newUserPasswordConfirm}
                      />
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText
                          onClick={() => setShowNewPassConfirm(!showNewPassConfirm)}
                          style={{ cursor: "pointer" }}
                        >
                          <i className="fas fa-eye" />
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                    <FormFeedback invalid="true" className="d-block">
                      {formikNewUserPass.errors.newUserPasswordConfirm}
                    </FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
              <Button color="primary" type="submit" className="mb-4">
                Save password
              </Button>
            </Form>
          </CardBody>
        )}
      </Card>
    </Col>
  </Row>
</Container>
    </div> */}
    </>
  );
};

export default NewUserSettings;
