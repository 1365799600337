import "./verifiedFilesList.scss";

import { TailSpin } from "react-loader-spinner";

import { IVerifiedFileCardProps, VerifiedFileCard } from "../VerifiedFileCard/VerifiedFileCard";
import { Swiper, SwiperSlide } from "swiper/react";

interface IVerifiedFilesListProps {
  filesList: IVerifiedFileCardProps[];
  isVerifying?: boolean;
}

export function VerifiedFilesList(props: IVerifiedFilesListProps) {
  const { filesList, isVerifying } = props;

  return (
    <>
      <div className="files-list">
        {filesList.map(file => (
          <VerifiedFileCard key={file.id} {...file} />
        ))}
        {isVerifying && (
          <div className="files-list__loading">
            <TailSpin ariaLabel="loading-indicator" color="#3c7cf6" />
          </div>
        )}
      </div>

      <Swiper spaceBetween={50} slidesPerView={1.2} className="files-list_mobile">
        {filesList.map(file => (
          <SwiperSlide key={file.id}>
            <VerifiedFileCard {...file} />
          </SwiperSlide>
        ))}
        {isVerifying && (
          <SwiperSlide>
            <div className="files-list__loading">
              <TailSpin ariaLabel="loading-indicator" color="#3c7cf6" />
            </div>
          </SwiperSlide>
        )}
      </Swiper>
    </>
  );
}
