import { useEffect, useRef } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Sidebar from "../components/AdminComponents/Sidebar";
import io from "socket.io-client";
import { toast } from "react-toastify";
import { WsResponseAction } from "../app/models/ws.interface";
import { useAppDispatch } from "../app/hooks/hooks";
import { fetchOverallStats } from "../app/state/adminPanelSlice";
const backendWs = process.env.REACT_APP_SERVER_URL;

const Admin = () => {
  const socketRef = useRef(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect((): any => {
    socketRef.current = io(backendWs, { reconnectionDelay: 3000 });

    socketRef.current.on("connect", () => {
      toast.success("Connected");
    });

    socketRef.current.on("disconnect", reason => {
      console.log(`socket disconnected: ${reason}`);
      toast.warning("Disconnected");
      navigate("/");
    });

    socketRef.current.on("connect_error", reason => {
      console.log(`socket connect_error: ${reason}`);
      toast.error("Connection error");
    });

    socketRef.current.on(WsResponseAction.systemMessages, message => {
      // console.log(message);
    });

    return () => socketRef.current.close();
  }, []);

  // Get stats
  useEffect(() => {
    dispatch(fetchOverallStats());
  }, [dispatch]);

  return (
    <>
      <Sidebar />
      <div className="main-content">{socketRef && <Outlet context={socketRef} />}</div>
    </>
  );
};

export default Admin;
