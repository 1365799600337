import "./mainPageHeader.scss";

import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Button } from "../../Button/Button";
import { Logo } from "../../Logo/Logo";
import { MobileMenu } from "../../MobileMenu/MobileMenu";

import burger from "../../../assets/icons/burger.svg";
import joinButtonIcon from "../../../assets/icons/main-page/header/join-button.svg";
import loginButtonIcon from "../../../assets/icons/main-page/header/login-button.svg";
import verificationButtonIcon from "../../../assets/icons/main-page/header/verification-button.svg";
import { useTranslation } from "react-i18next";
import ChangeLanguage from "../../ChangeLanguage/changeLanguage";
import { navigationLinks } from "../../../constants";
import { scrollToElement } from "../../../utils/scrollTo";

export function MainPageHeader() {
  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);
  const { t } = useTranslation();

  const openMobileMenu = () => {
    setIsMobileMenuVisible(true);
    document.body.style.overflow = "hidden";
  };

  const closeMobileMenu = () => {
    setIsMobileMenuVisible(false);
    document.body.style.overflow = "auto";
  };

  const navigate = useNavigate();

  return (
    <header className="container main-page-header">
      <div className="main-page-header__navigation-wrapper">
        <Logo />
        <nav className="main-page-header__navigation">
          {navigationLinks.map(link => (
            <Link key={link.title} to={link.href + link.hash}>
              {link.title}
            </Link>
          ))}
        </nav>
      </div>

      <div className="main-page-header__buttons-wrapper">
        <Button className="mr-2" onClick={() => scrollToElement("contact-form")}>
          <img src={joinButtonIcon} alt="" />
          <p>{t("mobileMenu.join")}</p>
        </Button>
        <Button className="mr-2" variant="green" onClick={() => navigate("/verification")}>
          <img src={verificationButtonIcon} alt="" />
          <p>{t("mobileMenu.verificationPortal")}</p>
        </Button>
        <Button variant="transparent" onClick={() => navigate("/auth")}>
          <img src={loginButtonIcon} alt="" />
          <p>{t("mobileMenu.login")}</p>
        </Button>
        <ChangeLanguage />
      </div>

      <button className="main-page-header__mobile-menu-button" onClick={openMobileMenu}>
        <img src={burger} alt="" />
      </button>

      {isMobileMenuVisible && <MobileMenu onClose={closeMobileMenu} />}
    </header>
  );
}
