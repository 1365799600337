export interface IAdmin {
  id: number;
  name: string;
  email: string;
  walletAddress: string;
  createdAt: string;
  isActive: boolean;
}

export interface IInstitute {
  id: number;
  name: string;
  employees: number;
  issuesCertificates: number;
  walletAddress: string;
  createdAt: string;
  isActive: boolean;
  limit: number;
  email: string;
}

export interface ICareer {
  id: number;
  name: string;
  instituteName: string;
  createdAt: string;
  facultyName: string
}

export interface IEmployee {
  id: number;
  name: string;
  email: string;
  issuedCertificates: number;
  walletAddress: string;
  createdAt: string;
  isActive: boolean;
  instituteId: number;
  instituteName: string;
  facultyName: string
}

export interface ICertificate {
  id: string;
  career: {
    id: number;
    name: string;
  };
  createdAt: string;
  creator: {
    id: number;
    name: string;
    walletAddress: string;
  };
  institute: {
    id: number;
    name: string;
    walletAddress: string;
  };
  isDeleted: boolean;
  number: number;
  owner: string;
  graduationDate: string;
}

export interface IVerifyRequests {
  certificate: string
  id: number,
  createdAt: string
  status: 0 | 1 | 2
  areDocumentsWrong: boolean
  career: {
    id: number
    name: string
  }
  graduationDate: string
  number: number
  owner: string
}

export interface IFaculty {
  id: number,
  name: string,
  institute: Pick<IInstitute, "id" | "name">
  createdAt: string
}

export interface IInstituteCheckbox {
  id: number;
  name: string;
  checked: boolean;
}

export interface IStats {
  totalInstitutes?: number;
  totalEmployees?: number;
  totalCertificates: number;
  totalLimit: number;
}

export enum DocumentTypes {
  diploma = 0,
  certificate = 1,
}
