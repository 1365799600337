import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { careersService } from "../../services/careers.service";
import { facultyService } from "../../services/faculty.service";
import { IFaculty } from "../../app/models/adminPanel.interface";
import { useTranslation } from "react-i18next";

const validationEditCareer = Yup.object({
  editCareerName: Yup.string()
    .required("Enter the name of the career")
    .min(2, "Minimum string length is 2 characters")
    .max(64, "Maximum string length is 64 characters")
    .matches(
      /^[aA-zZ\s]+$/,
      "Entering special characters is prohibited. Please enter the correct name of the career"
    ),
});

export const EditCareerPopup = ({
  editCareerPopup,
  setEditCareerPopup,
  selectedCareer,
  allInstitutes,
}) => {
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [currentFaculty, setCurrentFaculty] = useState<IFaculty>(null);
  const [faculties, setFaculties] = useState<IFaculty[]>([]);
  const { t } = useTranslation();

  const formikEditCareer = useFormik({
    initialValues: {
      editCareerName: selectedCareer ? selectedCareer.name : "",
    },
    validationSchema: validationEditCareer,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: async values => {
      const toastLoader = toast.loading(t("admin-loading.update-career"));
      setIsFormSubmitting(true);
      let newData: any;

      if (values.editCareerName !== selectedCareer.name) {
        newData = { name: values.editCareerName };
      }

      if (currentFaculty.name !== selectedCareer.facultyName) {
        newData = { ...newData, facultyId: currentFaculty.id };
      }

      const resp = await careersService.updateCareer(selectedCareer.id, newData);
      if (resp.error) {
        toast.update(toastLoader, {
          render: resp.error,
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
        setIsFormSubmitting(false);
        return;
      }
      toast.update(toastLoader, {
        render: t("edit-career-popup.updated"),
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
      setIsFormSubmitting(false);
      closeAndClearPopup();
    },
  });

  const closeAndClearPopup = () => {
    formikEditCareer.values.editCareerName = "";
    formikEditCareer.touched.editCareerName = false;
    setEditCareerPopup(false);
  };

  useEffect(() => {
    if (selectedCareer && selectedCareer.instituteName) {
      const getFaculties = async () => {
        try {
          const institute = allInstitutes.find(item => item.name === selectedCareer.instituteName);

          const response = await facultyService.getFacultiesByInstituteId(institute.id);
          setFaculties(response);

          const defaultFaculty = response.find(item => item.name === selectedCareer.facultyName);

          setCurrentFaculty(defaultFaculty);
        } catch (error) {
          toast.error(error.message);
          setEditCareerPopup(false);
          return;
        }
      };

      getFaculties();
    }
  }, [selectedCareer]);

  return (
    <Modal className="modal-dialog-centered" isOpen={editCareerPopup} toggle={closeAndClearPopup}>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          {t("edit-career-popup.title")}
        </h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={closeAndClearPopup}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <Form onSubmit={formikEditCareer.handleSubmit}>
        <div className="modal-body">
          <p>{t("edit-career-popup.description")}</p>
          <Input
            placeholder={t("edit-career-popup.name")}
            type="text"
            className="pl-2"
            {...formikEditCareer.getFieldProps("editCareerName")}
          />
          {formikEditCareer.touched.editCareerName && formikEditCareer.errors.editCareerName && (
            <small className="text-danger mb-1">{formikEditCareer.errors.editCareerName}</small>
          )}

          <UncontrolledDropdown className="mt-3 d-block">
            <DropdownToggle
              role="button"
              color="primary"
              outline
              caret
              style={{
                maxWidth: "100%",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {currentFaculty ? currentFaculty.name : t("edit-career-popup.faculty")}
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow">
              {faculties.length > 0 &&
                faculties.map(i => (
                  <DropdownItem key={i.id} onClick={() => setCurrentFaculty(i)}>
                    {i.name}
                  </DropdownItem>
                ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
        <div className="modal-footer">
          <Button color="secondary" type="button" onClick={closeAndClearPopup}>
            {t("admin-panel.cancel")}
          </Button>
          <Button color="primary" type="submit" disabled={isFormSubmitting}>
            {t("admin-panel.save")}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};
