import "./benefits.scss";

interface IBenefitsCardProps {
  benefits: {
    id: string;
    icon: string;
    background: string;
    title: string;
    text: string;
  };
}

export function BenefitsCard({ benefits }: IBenefitsCardProps) {
  return (
    <div className="benefits-card" key={benefits.id}>
      <div
        className="benefits-card__background"
        style={{ backgroundImage: `url(${benefits.background})` }}
      >
        <div className="benefits-card__icon-wrapper">
          <img src={benefits.icon} alt={benefits.title} className="benefits-card__icon" />
        </div>
      </div>
      <div className="benefits-card__content">
        <h3 className="benefits-card__title">{benefits.title}</h3>
        <p className="benefits-card__text">{benefits.text}</p>
      </div>
      <p className="benefits-card__id">{benefits.id}</p>
    </div>
  );
}
