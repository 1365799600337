import "./why.scss";
import { useTranslation } from "react-i18next";

export function WhyList() {

  const {t} = useTranslation()

  const whyList = [
    {
      id: 0,
      title: t("whyList.trust"),
      subtitle: t("whyList.practicalBlockchain"),
      text: t("whyList.hasBeenCreated"),
    },
    {
      id: 1,
      title: t("whyList.security"),
      subtitle: t("whyList.priority"),
      text: t("whyList.secureNetwork"),
    },
    {
      id: 2,
      title: t("whyList.reliability"),
      subtitle: t("whyList.provideData"),
      text: t("whyList.decentralizedData"),
    },
  ];

  return (
    <ul className="why-list">
      {whyList.map(why => (
        <li className="why-item" key={why.id}>
          <div className="why-item__title-wrapper">
            <div className="why-item__marker" />
            <h3 className="why-item__title">{why.title}</h3>
          </div>
          <div>
            <h4 className="why-item__subtitle">{why.subtitle}</h4>
            <p className="why-item__text">{why.text}</p>
          </div>
        </li>
      ))}
    </ul>
  );
}
