import React, { useState } from "react";
import './Checkbox.scss'

import checked from '../../assets/icons/approve.svg'
import classNames from "classnames";

interface ICheckboxProps {
  onChange?: () => void
  isChecked?: boolean
  className?: string
}
const Checkbox = (props: ICheckboxProps) => {
  const {onChange, isChecked: defaultChecked = false, className} = props
  const [isChecked, setIsChecked] = useState(defaultChecked)

  const changeHandler = () => {
    setIsChecked(prevState => !prevState)
    if(onChange) {
      onChange()
    }
  }

  return (
    <label className={classNames("checkbox", isChecked && 'checkbox_checked', className)}>
      <input type="checkbox" onChange={changeHandler} checked={isChecked}/>
      {isChecked && <img src={checked} alt="checked" />}
    </label>
  );
};

export default Checkbox;