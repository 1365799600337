import React from "react";
import accept from "../../../assets/icons/approve.svg";
import decline from "../../../assets/icons/decline.svg";
import './DiplomaButtons.scss'
import { useTranslation } from "react-i18next";

interface IDiplomaButtonsProps {
  declineHandler: () => void
  approveHandler: () => void
}

const DiplomaButtons = (props: IDiplomaButtonsProps) => {
  const {declineHandler, approveHandler} = props
  const {t} = useTranslation()

  return (
    <div className="diploma-buttons">
      <h2>{t('diploma.confirm')}</h2>
      <div className="diploma-buttons__wrapper">
        <button className="diploma-buttons__button diploma-buttons__button_approve" onClick={approveHandler}>
          <p>{t('diploma.confirmDetails')}</p>
          <div>
            <img src={accept} alt="accept" className="diploma-buttons__button-icon"/>
          </div>
        </button>
        <button className="diploma-buttons__button diploma-buttons__button_decline" onClick={declineHandler}>
          <p>{t('diploma.declineDetails')}</p>
          <div>
            <img src={decline} alt="decline" className="diploma-buttons__button-icon"/>
          </div>
        </button>
      </div>
    </div>
  );
};

export default DiplomaButtons;