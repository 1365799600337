import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";

import { Footer } from "../components/Footer/Footer";
import { Header } from "../components/Header/Header";
import { MainPageHeader } from "../components/MainPage/MainPageHeader/MainPageHeader";

const Main = () => {
  const location = useLocation();
  const [isMainPage, setIsMainPage] = useState(true);

  useEffect(() => {
    if (location.pathname === "/") {
      setIsMainPage(true);
    } else {
      setIsMainPage(false);
    }
  }, [location]);

  return (
    <div className="main-app">
      {isMainPage ? <MainPageHeader /> : <Header />}
      <Outlet />
      <Footer />
    </div>
  );
};

export default Main;
