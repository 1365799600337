import "./searchbar.scss";

import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

// import QrScanner from "../QrScanner";
import { certificatesService } from "../../services/certificates.service";

// import qrIcon from "../../assets/icons/qr.png";
import searchIconBlue from "../../assets/icons/search-blue.svg";
import searchIcon from "../../assets/icons/search.svg";
import { useTranslation } from "react-i18next";

const serverUrl = process.env.REACT_APP_SERVER_URL;

interface ISearchbarProps {
  isInHeader?: boolean;
  isStudentPage?: boolean;
}

const Searchbar = (props: ISearchbarProps) => {
  const { isStudentPage, isInHeader } = props;
  const {t} = useTranslation()

  const navigate = useNavigate();
  // const [showScanner, setShowScanner] = useState(false);
  const [scannerResult, setScannerResult] = useState(null);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const formikSearchbar = useFormik({
    initialValues: {
      searchAddress: "",
    },
    validationSchema: Yup.object({
      searchAddress: Yup.string()
        .required(t("searchBar.certificateId"))
        .matches(
          /^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/,
          t("searchBar.invalidCharacters")
        ),
    }),
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: async (values, { resetForm }) => {
      setIsFormSubmitting(true);
      const resp = await certificatesService.getCertificateStatus(values.searchAddress);
      if (resp.error) {
        toast.error(t("searchBar.idNotFound"));
        setIsFormSubmitting(false);
        return;
      }
      if (resp.isBurned === false) {
        navigate(`/diploma/${values.searchAddress}`);
      } else {
        navigate("/invalid-diploma");
      }
      resetForm();
      setIsFormSubmitting(false);
    },
  });

  useEffect(() => {
    if (scannerResult) {
      if (!scannerResult.includes(`${serverUrl}diploma`)) {
        toast.error(t("searchBar.invalidQR"));
      } else {
        navigate(`/${scannerResult.slice(scannerResult.indexOf("diploma"))}`);
      }
    }
    return () => {
      setScannerResult(null);
    };
  }, [navigate, scannerResult]);

  return (
    <>
      <form
        className={`searchbar ${isInHeader ? "searchbar_header" : ""}`}
        onSubmit={formikSearchbar.handleSubmit}
      >
        <input
          className={`searchbar__input ${isStudentPage ? "searchbar__input_student" : ""}`}
          type="text"
          placeholder={t("searchBar.certificateOrDiplomaId")}
          autoComplete="off"
          {...formikSearchbar.getFieldProps("searchAddress")}
        />
        {formikSearchbar.touched.searchAddress && formikSearchbar.errors.searchAddress && (
          <small className="text-danger searchbar__error">
            {formikSearchbar.errors.searchAddress}
          </small>
        )}

        {/* {isInHeader && (
          <img
            className="searchbar__qr"
            src={qrIcon}
            alt="qr btn"
            onClick={() => setShowScanner(true)}
          />
        )} */}
        <button
          type="submit"
          className={`custom-button custom-button_full searchbar__btn ${
            isInHeader ? "searchbar__btn_header" : ""
          } ${isStudentPage ? "searchbar__btn_student" : ""}`}
          disabled={isFormSubmitting}
        >
          {!isInHeader && t("searchBar.search")}
          <img src={isStudentPage ? searchIconBlue : searchIcon} alt="" />
        </button>
      </form>
      {/* {!isInHeader && (
        <button className="searchbar__searchpage-qr" onClick={() => setShowScanner(true)}>
          <img src={qrIcon} alt="qr" />
          <span>Have a QR code?</span>
        </button>
      )}

      <QrScanner
        showScanner={showScanner}
        setShowScanner={setShowScanner}
        setScannerResult={setScannerResult}
      /> */}
    </>
  );
};

export default Searchbar;
