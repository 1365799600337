import { Button, Form, FormGroup, Input, Label, Modal } from "reactstrap";
import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { certificatesService } from "../../services/certificates.service";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const validationAddCertificate = Yup.object({
  certificateDate: Yup.date().required("Select the graduation date"),
  isVisible: Yup.boolean()
    .required("Set visible")
});
const EditCertificatePopup = (props) => {
  const { newCertificatePopup, setNewCertificatePopup, id } = props;
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const {t} = useTranslation()

  const closeAndClearPopup = () => {
    // formikAddCertificate.resetForm();
    // setSelectedInstitute(null);
    setNewCertificatePopup(false);
  };
  const formikAddCertificate = useFormik({
    initialValues: {
      certificateDate: "",
      isVisible: false,
    },
    validationSchema: validationAddCertificate,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async values => {
      setIsFormSubmitting(true);
      const toastLoader = toast.loading(t('admin-loading.update-certificate'));
      setIsFormSubmitting(true);

      const obj = {
        isVisible: values.isVisible,
        showDate:values.certificateDate
      }

      const resp = await certificatesService.editCertificate(obj, id);
      console.log(resp)
      if (resp?.error && resp?.error?.length > 0) {
        toast.update(toastLoader, {
          render: t('edit-certificate-popup.error'),
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
        setIsFormSubmitting(false);
        return;
      }
      toast.update(toastLoader, {
        render: t('edit-certificate-popup.updated'),
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
      setIsFormSubmitting(false);
      closeAndClearPopup();
    },
  });

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={newCertificatePopup}
      toggle={closeAndClearPopup}
    >
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          {t('edit-certificate-popup.title')}
        </h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={closeAndClearPopup}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <Form onSubmit={formikAddCertificate.handleSubmit}>
        <div className="modal-body">
          <FormGroup>
            <Label check for="burned" role="button" className="mt-2">
              {t('edit-certificate-popup.form.date')}
            </Label>
            <Input
              placeholder={t('edit-certificate-popup.form.date')}
              type="date"
              className="pl-2 mt-2"
              {...formikAddCertificate.getFieldProps("certificateDate")}
            />
            {formikAddCertificate.errors.certificateDate &&
              formikAddCertificate.touched.certificateDate && (
                <small className="text-danger mb-1">
                  {formikAddCertificate.errors.certificateDate}
                </small>
              )}
          </FormGroup>
          <FormGroup check inline>
            <Input
              type="checkbox"
              id="editVisable"
              name="editVisable"
              className="pl-2"
              {...formikAddCertificate.getFieldProps("isVisible")}
            />
            <Label check for="editVisable" role="button" className="pl-2">
              Visible to users?
            </Label>
          </FormGroup>
          {formikAddCertificate.errors.isVisible &&
            formikAddCertificate.touched.isVisible && (
              <small className="text-danger mb-1">
                {formikAddCertificate.errors.isVisible}
              </small>
            )}
          <div className="modal-footer">
            <Button color="secondary" type="button" onClick={closeAndClearPopup}>
              {t('admin-panel.cancel')}
            </Button>
            <Button color="primary" type="submit" disabled={isFormSubmitting}>
              {t('admin-panel.save')}
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default EditCertificatePopup;
