import React from "react";
import { Modal } from "reactstrap";
import { IVerifyRequests } from "../../app/models/adminPanel.interface";
import { Button } from "../Button/Button";
import { verificationService } from "../../services/verification.service";
import { VERIFY_REQUEST_STATUS } from "../../constants";
import { toast } from "react-toastify";
import { formatDate } from "../../utils/formatDate";
import { useTranslation } from "react-i18next";

interface ICheckModalProps {
  isApproveModalOpen: boolean
  toggleApproveModal: (id?: number) => void
  data: IVerifyRequests
}

const VerifyRequestPopup = (props: ICheckModalProps) => {
  const {isApproveModalOpen, toggleApproveModal, data} = props
  const {t} = useTranslation()

  const keyFormatter = (key: string) => {
    switch (key) {
      case 'owner':
        return t('verify-request-popup.name')
      case 'graduationDate':
        return t('verify-request-popup.date')
      case 'areDocumentsWrong':
        return t('verify-request-popup.wrong-documents')
      case 'career':
        return t('verify-request-popup.career')
      case 'number':
        return t('verify-request-popup.number')

    }
  }

  const handleRequestStatus = async (status: VERIFY_REQUEST_STATUS) => {
    try {
      await verificationService.updateVerifyRequestStatus(status, data.id)
      toast.success(t('verify-request-popup.updated'))
      toggleApproveModal()
    } catch (error) {
      toast.error(error.message)
    }
  }

  const valuesArray = (): {key: keyof IVerifyRequests, value: any}[] => {
    return Object.entries(data).map(([key, value]) => {
      if(key === 'createdAt' || key === 'id' || key === 'status' || key === 'certificate') return null
      if(!value) return null

      let verifyRequestKey = key as keyof IVerifyRequests

      return { key: verifyRequestKey, value }
    })
  }

  const renderValue = (key: keyof IVerifyRequests) => {
    switch (key) {
      case "areDocumentsWrong":
        return ''
      case "career":
        return `: ${data[key].name}`
      case "graduationDate":
        return `: ${formatDate(data[key])}`
      default:
        return `: ${data[key]}`
    }
  }

  if(!data) return null

  return (
    <Modal isOpen={isApproveModalOpen} toggle={() => toggleApproveModal()} className="modal-dialog-centered">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          {t('verify-request-popup.title')}
        </h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => toggleApproveModal()}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="decline-table-modal p-4">
        <div className="mt-2">
          {
            valuesArray().map((item) => {
              if(!item) return null
              return (
                <div className="d-flex" key={item.key}>
                  <p className="m-0">{keyFormatter(item.key)}</p>
                  <p className="m-0">{renderValue(item.key)}</p>
                </div>
              )
            })
          }
        </div>
        <div className="d-flex justify-content-center mt-4">
          {
            data.status !== 2 && data.status !== 1 && <>
              <Button variant="secondary" onClick={() => handleRequestStatus(VERIFY_REQUEST_STATUS.REJECTED)}>{t('verify-request-popup.reject')}</Button>
              <Button className="ml-4" variant="green" onClick={() => handleRequestStatus(VERIFY_REQUEST_STATUS.APPROVED)}>{t('verify-request-popup.approve')}</Button></>
          }
        </div>
      </div>
    </Modal>
  );
};

export default VerifyRequestPopup
