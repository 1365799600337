import "./transferDocumentModal.scss";

import { useState } from "react";
import { Modal, ModalProps } from "reactstrap";

import { TransferModalEmailForm } from "./TransferModalEmailForm/TransferModalEmailForm";
import { TransferModalTwoFactorForm } from "./TransferModalTwoFactorForm/TransferModalTwoFactorForm";

enum FormType {
  Email,
  TwoFactor,
}

interface ITransferModalProps extends ModalProps {
  certificateId: string | null | undefined;
  onTransfer: () => Promise<void>;
}

export const TransferDocumentModal = (props: ITransferModalProps) => {
  const { certificateId, onTransfer, toggle, ...modalProps } = props;

  const [currentForm, setCurrentForm] = useState<FormType>(FormType.Email);
  const [verifiedEmail, setVerifiedEmail] = useState("");

  const handleToggle = (event: any) => {
    setCurrentForm(FormType.Email);
    toggle(event);
  };

  const handleEmailSubmit = (email: string) => {
    setVerifiedEmail(email);
    setCurrentForm(FormType.TwoFactor);
  };

  return (
    <Modal toggle={handleToggle} {...modalProps}>
      {currentForm === FormType.Email && <TransferModalEmailForm onFormSubmit={handleEmailSubmit} toggle={toggle}/>}
      {currentForm === FormType.TwoFactor && (
        <TransferModalTwoFactorForm
          certificateId={certificateId}
          email={verifiedEmail}
          onTransfer={onTransfer}
        />
      )}
    </Modal>
  );
};
