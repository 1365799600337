import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import {
  Modal,
  Button,
  Form,
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useAppSelector } from "../../app/hooks/hooks";
import { careersService } from "../../services/careers.service";
import { UserRole } from "../../app/models/role.interface";
import { IFaculty, IInstitute, IInstituteCheckbox } from "../../app/models/adminPanel.interface";
import { facultyService } from "../../services/faculty.service";
import { useTranslation } from "react-i18next";

interface IAddCareerPopupProps {
  newCareerPopup: boolean;
  setNewCareerPopup: (arg: boolean) => void;
  allInstitutes: IInstituteCheckbox[];
}

const validationAddCareer = Yup.object({
  newCareerName: Yup.string()
    .required("Enter the name of the career")
    .min(2, "Minimum string length is 2 characters")
    .max(64, "Maximum string length is 64 characters")
});

export const AddCareerPopup: React.FC<IAddCareerPopupProps> = props => {
  const { newCareerPopup, setNewCareerPopup, allInstitutes } = props;
  const { id: currentId, role: userRole, name: currentName } = useAppSelector(state => state.user);
  const [selectedInstitute, setSelectedInstitute] = useState<Pick<IInstitute, "id" | "name">>(
    userRole === UserRole.insitute ? { id: currentId, name: currentName } : null
  );
  const isAbleToChangeFaculty =
    (userRole <= UserRole.admin && selectedInstitute) || userRole === UserRole.insitute;
  const [selectedFaculty, setSelectedFaculty] = useState<IFaculty>(null);
  const [faculties, setFaculties] = useState<IFaculty[]>([]);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const { t } = useTranslation();

  const formikAddCareer = useFormik({
    initialValues: {
      newCareerName: "",
    },
    validationSchema: validationAddCareer,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async values => {
      if (!selectedInstitute) {
        toast.error(t("add-career-popup.select-institution"));
        return;
      }

      if (!selectedFaculty) {
        toast.error(t("add-career-popup.select-faculty"));
        return;
      }

      const toastLoader = toast.loading(t("admin-loading.create-new-career"));
      setIsFormSubmitting(true);

      const newCareer = {
        name: values.newCareerName,
        instituteId: selectedInstitute.id,
        facultyId: selectedFaculty.id,
      };
      const resp = await careersService.addNewCareer(newCareer);
      // console.log(resp);
      if (resp.status === 400 || resp.error) {
        toast.update(toastLoader, {
          render: t("add-career-popup.creating-error"),
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
        setIsFormSubmitting(false);
        return;
      }
      toast.update(toastLoader, {
        render: t("add-career-popup.created"),
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
      setIsFormSubmitting(false);
      closeAndClearPopup();
    },
  });

  const closeAndClearPopup = () => {
    formikAddCareer.resetForm();
    setNewCareerPopup(false);
    if (userRole < UserRole.insitute) {
      setSelectedInstitute(null);
    }
  };

  // Auto set institution for roles lower than admin
  useEffect(() => {
    if (userRole >= UserRole.insitute) {
      setSelectedInstitute({ id: currentId, name: currentName });
    }
  }, [currentId, currentName, userRole]);

  useEffect(() => {
    if (selectedInstitute) {
      const getFaculties = async () => {
        try {
          const response = await facultyService.getFacultiesByInstituteId(selectedInstitute.id);
          setFaculties(response);
        } catch (error) {
          toast.error(error.message);
          setNewCareerPopup(false);
          return;
        }
      };

      getFaculties();
    }
  }, [selectedInstitute]);

  return (
    <Modal className="modal-dialog-centered" isOpen={newCareerPopup} toggle={closeAndClearPopup}>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          {t("add-career-popup.title")}
        </h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={closeAndClearPopup}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <Form onSubmit={formikAddCareer.handleSubmit}>
        <div className="modal-body">
          <p>{t("add-career-popup.description")}</p>
          <Input
            placeholder={t("add-career-popup.form.name")}
            type="text"
            className="pl-2"
            {...formikAddCareer.getFieldProps("newCareerName")}
          />
          {formikAddCareer.touched.newCareerName && formikAddCareer.errors.newCareerName && (
            <small className="text-danger mb-1">{formikAddCareer.errors.newCareerName}</small>
          )}
          {userRole <= UserRole.admin && (
            <UncontrolledDropdown className="mt-3 d-block">
              <DropdownToggle
                role="button"
                color="primary"
                outline
                caret
                style={{
                  maxWidth: "100%",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {selectedInstitute ? selectedInstitute.name : t("add-career-popup.form.institute")}
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow">
                {allInstitutes.map(i => (
                  <DropdownItem key={i.id} onClick={() => setSelectedInstitute(i)}>
                    {i.name}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          )}
          {isAbleToChangeFaculty && (
            <UncontrolledDropdown className="mt-3 d-block">
              <DropdownToggle
                role="button"
                color="primary"
                outline
                caret
                style={{
                  maxWidth: "100%",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {selectedFaculty ? selectedFaculty.name : t("add-career-popup.form.faculty")}
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow">
                {faculties.map(i => (
                  <DropdownItem key={i.id} onClick={() => setSelectedFaculty(i)}>
                    {i.name}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          )}
        </div>
        <div className="modal-footer">
          <Button color="secondary" type="button" onClick={closeAndClearPopup}>
            {t("admin-panel.cancel")}
          </Button>
          <Button color="primary" type="submit" disabled={isFormSubmitting}>
            {t("admin-panel.add")}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};
