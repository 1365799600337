import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import './changeLanguage.scss'
import arrow from '../../assets/icons/arrow.svg'
import classNames from "classnames";
import { useOutsideClick } from "../../app/hooks/useOutsideClick";
import { userService } from "../../services/user.service";
import { authService } from "../../services/auth.service";
import { toast} from "react-toastify";

interface IChangeLanguageProps {
  isBlack?: boolean
  isStudentPage?: boolean
}

const ChangeLanguage = (props: IChangeLanguageProps) => {
  const {isBlack = false, isStudentPage = false} = props
  const {i18n} = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const buttonRef = useRef<HTMLButtonElement>(null)

  const availableLanguages = [
    'en', 'ru'
  ]

  const toggleHandler = () => {
    setIsOpen(prevState => !prevState)
  }

  const changeLanguage = async (language: string) => {
    if(isStudentPage) {
      try {
        await userService.updatePreferredLanguage(language)
        await authService.refreshTokens()
      } catch {
        toast.error('Unexpected error')
      }
    }

    await i18n.changeLanguage(language)
    localStorage.setItem('lang', language)
    setIsOpen(false)
  }

  useOutsideClick(buttonRef, () => {
    setIsOpen(false)
  })

  return (
    <div className={classNames('change-language', isBlack && 'change-language_black')}>
      <button className='change-language__main-button' onClick={toggleHandler} ref={buttonRef}>
        <p>{i18n.language}</p>
        <img src={arrow} alt="arrow" />
      </button>
      <div className={classNames('change-language__languages', isOpen && 'change-language__languages_open')}>
        {availableLanguages.map((item) => {
          if(item === i18n.language) return null
          return <button key={item} onClick={() => changeLanguage(item)}>{item}</button>
        })}
      </div>
    </div>
  );
};

export default ChangeLanguage;
