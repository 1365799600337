import React, { useState } from "react";
import { toast } from "react-toastify";
import { Modal, Form, Input, FormGroup, Label, Button } from "reactstrap";
import { institutesService } from "../../services/institutes.service";
import { useTranslation } from "react-i18next";

const UploadInstituteLogoPopup = ({ selectedInstitute, newLogoPopup, setNewLogoPopup }) => {
  const [editLogoFile, setEditLogoFile] = useState(null);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const {t} = useTranslation()

  const handleFileInput = ({ target: { files } }) => {
    const selectedFile = files[0];
    if (editLogoFile && files.length === 0) {
      return;
    }
    if (selectedFile.size > 2000000) {
      toast.error(t('upload-logo-popup.image-big'));
      return;
    }
    setEditLogoFile(selectedFile);
  };

  const handleUpload = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsFormSubmitting(true);
    if (!editLogoFile) {
      toast.error(t('upload-logo-popup.select-logo'));
      return;
    }
    const formData = new FormData();
    formData.append("logo", editLogoFile);
    const resp = await institutesService.uploadInstituteLogo(selectedInstitute, formData);
    // console.log(resp);
    if (resp.error) {
      toast.error(resp.error);
      setIsFormSubmitting(false);
      return;
    }
    toast.success(t('upload-logo-popup.uploaded'));
    setIsFormSubmitting(false);
    closeAndClearPopup();
  };

  const closeAndClearPopup = () => {
    setNewLogoPopup(false);
    setEditLogoFile(null);
  };

  return (
    <Modal className="modal-dialog-centered" isOpen={newLogoPopup} toggle={closeAndClearPopup}>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          {t('upload-logo-popup.title')}
        </h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={closeAndClearPopup}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <Form onSubmit={e => handleUpload(e)}>
        <div className="modal-body">
          <p>{t('upload-logo-popup.description')}</p>
          <FormGroup className="input-group mt-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="inputGroupFileAddon01">
                <i className="ni ni-image" />
              </span>
            </div>
            <div className="custom-file">
              <Input
                name="file"
                type="file"
                accept="image/png, image/svg+xml"
                onChange={e => handleFileInput(e)}
                className="custom-file-input"
                id="updateLogoFileInput"
                aria-describedby="inputGroupFileAddon01"
              />
              <Label className="custom-file-label" htmlFor="updateLogoFileInput">
                {editLogoFile ? editLogoFile.name : t('upload-logo-popup.upload')}
              </Label>
            </div>
          </FormGroup>
        </div>
        <div className="modal-footer">
          <Button color="secondary" type="button" onClick={closeAndClearPopup}>
            {t('admin-panel.cancel')}
          </Button>
          <Button color="primary" type="submit" disabled={isFormSubmitting}>
            {t('admin-panel.update')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default UploadInstituteLogoPopup;
