import "./student.scss";

import classNames from "classnames";
import { useEffect, useState } from "react";

import { useAppSelector } from "../../app/hooks/hooks";
import { DocumentTypes } from "../../app/models/adminPanel.interface";
import { IDocument } from "../../components/Student/DocumentCard/DocumentCard";
import { DocumentsList } from "../../components/Student/DocumentsList/DocumentsList";
import { ShareDocumentModal } from "../../components/Student/ShareDocumentModal/ShareDocumentModal";
import { StudentHero } from "../../components/Student/StudentHero/StudentHero";
import { TransferDocumentModal } from "../../components/Student/TransferDocumentModal/TransferDocumentModal";
import { studentService } from "../../services/student.service";
import { useTranslation } from "react-i18next";

export enum ModalType {
  Transfer,
  Share,
}

export interface ISelectedDocument {
  id: string;
  link: string;
}

export const StudentPage = () => {
  const isUserLogged = useAppSelector(state => state.user.isLogged);
  const {t} = useTranslation()
  // const navigate = useNavigate();

  // useEffect(() => {
  //   if (!isUserLogged) {
  //     navigate("/");
  //   }
  // }, []);

  const [documents, setDocuments] = useState<IDocument[]>([]);
  const [documentsType, setDocumentsType] = useState<DocumentTypes>(DocumentTypes.diploma);
  const [filteredDocuments, setFilteredDocuments] = useState<IDocument[]>([]);
  const [documentsAmount, setDocumentsAmount] = useState({
    certificates: 0,
    diplomas: 0,
  });

  const getUserDocuments = async () => {
    try {
      const userDocuments = await studentService.getDocuments();
      setDocuments(userDocuments);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!isUserLogged) {
      return;
    }
    getUserDocuments();
  }, []);

  const countDocuments = () => {
    if (documents.length === 0) {
      return;
    }
    let certificates = 0;
    documents.forEach(document => {
      if (document.documentType === DocumentTypes.certificate) {
        certificates += 1;
      }
    });
    let diplomas = 0;
    documents.forEach(document => {
      if (document.documentType === DocumentTypes.diploma) {
        diplomas += 1;
      }
    });
    setDocumentsAmount({ certificates, diplomas });
  };

  const filterDocuments = (type: DocumentTypes) => {
    if (documents.length === 0) {
      return;
    }
    const filteredDocumentsArray = documents.filter(document => document.documentType === type);
    setFilteredDocuments(filteredDocumentsArray);
  };

  useEffect(() => {
    countDocuments();
    filterDocuments(documentsType);
  }, [documents]);

  const handleToggleClick = (type: DocumentTypes) => {
    setDocumentsType(type);
    filterDocuments(type);
  };

  const [modal, setModal] = useState<ModalType | null>(null);
  const [selectedDocument, setSelectedDocument] = useState<ISelectedDocument | null>(null);

  const openModal = (modalType: ModalType, document: ISelectedDocument) => {
    setSelectedDocument(document);
    setModal(modalType);
  };

  const closeModal = () => {
    setModal(null);
  };

  const handleTransfer = async () => {
    await getUserDocuments();
    closeModal();
  };

  return (
    <>
      <StudentHero />

      <main className="container student-page">
        <div className="student-page__content">
          <div className="student-page__toggle-wrapper">
            <button
              className={classNames(
                "student-page__toggle left",
                documentsType === DocumentTypes.diploma && "active"
              )}
              onClick={() => handleToggleClick(DocumentTypes.diploma)}
            >
              {t('student.diplomas')} ({documentsAmount.diplomas})
            </button>
            <button
              className={classNames(
                "student-page__toggle right",
                documentsType === DocumentTypes.certificate && "active"
              )}
              onClick={() => handleToggleClick(DocumentTypes.certificate)}
            >
              {t('student.certificates')} ({documentsAmount.certificates})
            </button>
          </div>
          <DocumentsList
            documents={filteredDocuments}
            documentsType={documentsType}
            onOpenModal={openModal}
          />
        </div>
      </main>

      <TransferDocumentModal
        isOpen={modal === ModalType.Transfer}
        toggle={closeModal}
        centered
        certificateId={selectedDocument?.id}
        onTransfer={handleTransfer}
      />

      <ShareDocumentModal
        isOpen={modal === ModalType.Share}
        toggle={closeModal}
        centered
        certificateId={selectedDocument?.id}
        onShare={closeModal}
      />
    </>
  );
};
