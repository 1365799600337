import axiosWithToken from './request'

const addNewCertificate = async (newCertificate: FormData) => {
  try {
    const response = await axiosWithToken.post('certificate', newCertificate)
    return response.data
  } catch (error) {
    console.log('error:', error)
    return error.response.data
  }
}

const deleteCertificate = async (id: string) => {
  const resp = await axiosWithToken.delete(`certificate/${id}`)
  return resp
}

const findCertificate = async (certificateId: string) => {
  try {
    const response = await axiosWithToken.get(`/certificate/${certificateId}`);
    return response.data
  } catch (error) {
    console.log(error.response)
    return error.response.data
  }
}

const getCertificateStatus = async (certificateId: string) => {
  try {
    const response = await axiosWithToken.get(`certificate/${certificateId}/status`)
    return response.data
  } catch (error) {
    console.log(error.response)
    return error.response.data
  }
}

const getCertificateQr = async (certificateId: string) => {
  try {
    const response = await axiosWithToken.get(`certificate/${certificateId}/qr`)
    return response.data
  } catch (error) {
    console.log(error.response)
    return error.response.data
  }
}
const editCertificate = async (changeCertificate: {isVisible: boolean, showDate: string}, id) => {
  try {
    const response = await axiosWithToken.put(`/certificate/${id}/visibility`, changeCertificate)
    return response.data
  } catch (error) {
    console.log(error.response)
    return error.response.data
  }
}

const approveCertificate = async (certificateId: string) => {
  try {
    const response = await axiosWithToken.post(`certificate/${certificateId}/approve-details`)
    return response.data
  } catch (error) {
    throw new Error(error.message)
  }
}

const declineCertificate = async (data) => {
  try {
    await axiosWithToken.post('verify-request/create', data)
  } catch (error) {
    throw new Error(error)
  }
}

export const certificatesService = { addNewCertificate, declineCertificate, deleteCertificate, findCertificate, getCertificateStatus, getCertificateQr, editCertificate, approveCertificate }