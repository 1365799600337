import axiosWithToken from './request'

interface INewCareer {
  name: string
  instituteId: number
}

const addNewCareer = async (newCareer: INewCareer) => {
  try {
    const response = await axiosWithToken.post('career', newCareer)
    return response.data
  } catch (error) {
    console.log(error.response)
    return error.response.data
  }
}

const updateCareer = async (id: number, data: {name: string, facultyId: number}) => {
  try {
    const response = await axiosWithToken.put(`career/${id}`, data)
    return response
  } catch (error) {
    console.log(error.response)
    return error.response.data
  }
}

const deleteCareer = async (id: number) => {
  return await axiosWithToken.delete(`career/${id}`)
}

const getAvaliableCareers = async (instituteId: number) => {
  try {
    const response = await axiosWithToken.get(`career/${instituteId}`)
    return response.data
  } catch (error) {
    console.log(error.response)
    return error.response.data
  }
}

const getAvailableCertificateCareers = async (certificateId: string) => {
  try {
    const response = await axiosWithToken.get(`career/${certificateId}/certificate`)
    return response.data
  } catch (error) {
    console.log(error.response)
    return error.response.data
  }
}

export const careersService = { addNewCareer, updateCareer, deleteCareer, getAvaliableCareers, getAvailableCertificateCareers }