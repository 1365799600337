import axiosWithToken from './request'

interface INewAdmin {
  name: string;
  email: string;
  role: number;
}

interface IUpdateAdmin {
  name?: string;
  email?: string;
  isActive?: boolean;
}

const addNewAdmin = async (newAdmin: INewAdmin) => {
  try {
    const response = await axiosWithToken.post('admin', newAdmin)
    return response.data
  } catch (error) {
    console.log(error.response)
    return error.response.data
  }
}

const updateAdmin = async (id: number, newData: IUpdateAdmin) => {
  try {
    const response = await axiosWithToken.put(`admin/${id}`, newData)
    return response.status
  } catch (error) {
    console.log(error.response)
    return error.response.data
  }
}

const deleteAdmin = async (id: number) => {
  return await axiosWithToken.delete(`admin/${id}`)
}

export const adminsService = { addNewAdmin, updateAdmin, deleteAdmin }