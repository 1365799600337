import { createSlice } from "@reduxjs/toolkit";
import jwt from 'jwt-decode'

interface UserSlice {
  isLogged: boolean
  name: string
  email: string
  role: number
  id: number
}

const initialState: UserSlice = {
  isLogged: false,
  name: null,
  email: null,
  role: null,
  id: null,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLoggedIn: (state) => {
      state.isLogged = true
    },
    setLoggedOut: (state) => {
      state.isLogged = false
      state.name = null
      state.email = null
      state.role = null
    },
    setUserData: (state) => {
      const { accessToken } = JSON.parse(localStorage.getItem('tokens'))
      if (accessToken) {
        const decoded: any = jwt(accessToken)

        state.name = decoded.name
        state.email = decoded.email
        state.role = decoded.role
        state.id = decoded.id
      }
    },
    setUserEmail: (state, action) => {
      state.email = action.payload
    },
  }
})

export const { setLoggedIn, setLoggedOut, setUserData, setUserEmail } = userSlice.actions

export default userSlice.reducer