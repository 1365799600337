import "./auth.scss";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../app/hooks/hooks";
import { UserRole } from "../../app/models/role.interface";
import { setLoggedIn, setUserData } from "../../app/state/userSlice";
import { LoginForm } from "../../components/Auth/LoginForm/LoginForm";
import { SetTwoFactorForm } from "../../components/Auth/SetTwoFactorForm/SetTwoFactorForm";
import { VerifyTwoFactorForm } from "../../components/Auth/VerifyTwoFactorForm/VerifyTwoFactorForm";
import Loader from "../../components/Loader/Loader";
import { Logo } from "../../components/Logo/Logo";
import { authService } from "../../services/auth.service";
import { useTranslation } from "react-i18next";

export function AuthPage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const userRole = useAppSelector(state => state.user.role);
  const {t} = useTranslation()

  async function refresh() {
    setIsLoading(true);
    const resp = await authService.refreshTokens().finally(() => setIsLoading(false));
    if (resp.status === 403) {
      navigate("/auth/deactivated");
    }
    if (!resp.error) {
      dispatch(setLoggedIn());
      dispatch(setUserData());
    }
  }

  // CHECK TOKENS
  useEffect(() => {
    const tokens = localStorage.getItem("tokens");
    if (tokens) {
      refresh();
    }
  }, []);

  // Redirect to related table
  useEffect(() => {
    if (userRole === null) {
      return;
    }
    switch (userRole) {
      case UserRole.rootAdmin:
        navigate("/panel/admins");
        break;
      case UserRole.admin:
        navigate("/panel/institutions");
        break;
      case UserRole.insitute:
        navigate("/panel/employees");
        break;
      case UserRole.employee:
        navigate("/panel/certificates");
        break;
      default:
        navigate("/student");
        break;
    }
  }, [navigate, userRole]);

  const [currentForm, setCurrentForm] = useState<"login" | "setTwoFactor" | "verifyTwoFactor">(
    "login"
  );

  const onSet = () => {
    setCurrentForm("login");
  };

  const onVerify = () => {
    dispatch(setLoggedIn());
    dispatch(setUserData());
  };

  const onLogin = (isTwoFactorEnabled: boolean, isTwoFactorPassed: boolean) => {
    const is2FAEnabled = process.env.REACT_APP_ENABLE_2FA === "true";
    if (!is2FAEnabled) {
      onVerify();
      return;
    }

    if (!isTwoFactorEnabled) {
      setCurrentForm("setTwoFactor");
      return;
    }

    if (isTwoFactorEnabled && !isTwoFactorPassed) {
      setCurrentForm("verifyTwoFactor");
      return;
    }
  };

  if (isLoading) return <Loader />;

  return (
    <main className="auth-page">
      <div className="auth-page__form-wrapper">
        <Logo variant="blue" />

        {currentForm === "login" && <LoginForm onLogin={onLogin} />}
        {currentForm === "setTwoFactor" && <SetTwoFactorForm onSet={onSet} />}
        {currentForm === "verifyTwoFactor" && <VerifyTwoFactorForm onVerify={onVerify} />}

        <small className="auth-page__small-text">
          {t('login.terms')}
        </small>

        <p className="auth-page__owner">© 2022 BitGraduate</p>
      </div>
    </main>
  );
}
