import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Table,
  Container,
  Row,
  Button,
  Label,
  FormGroup,
  Input,
} from "reactstrap";
import AdminHeader from "../../components/AdminComponents/AdminHeader";
import AdminStatCards from "../../components/AdminComponents/AdminStatCards";
import { ConfirmationPopup } from "../../components/AdminComponents/ConfirmationPopup";
import { toast } from "react-toastify";
import {
  WsFilterTypes,
  WsOrderTypes,
  WsOrderValues,
  WsResponseAction,
  WsTable,
} from "../../app/models/ws.interface";
import ReactPaginate from "react-paginate";
import { useAppDispatch, useAppSelector } from "../../app/hooks/hooks";
import {
  setRequstedInstitute,
  setSearchPlaceholder,
  setSearchQuery,
} from "../../app/state/adminPanelSlice";
import { AddCareerPopup } from "../../components/AdminComponents/AddCareerPopup";
import { EditCareerPopup } from "../../components/AdminComponents/EditCareerPopup";
import { careersService } from "../../services/careers.service";
import { ICareer, IInstituteCheckbox } from "../../app/models/adminPanel.interface";
import { UserRole } from "../../app/models/role.interface";
import { institutesService } from "../../services/institutes.service";
import { useAdminWebSocket } from "../../app/hooks/useAdminWebSocket";
import { useTranslation } from "react-i18next";

const CareersTable = () => {
  const dispatch = useAppDispatch();
  const {
    id: currentUserId,
    name: currentUserName,
    role: currentUserRole,
  } = useAppSelector(state => state.user);
  const { searchQuery, requestedInstitute } = useAppSelector(state => state.adminPanel);
  const [selectedCareer, setSelectedCareer] =
    useState<Pick<ICareer, "id" | "name" | "instituteName" | "facultyName">>(null);
  const [newCareerPopup, setNewCareerPopup] = useState(false);
  const [deleteCareerPopup, setDeleteCareerPopup] = useState(false);
  const [editCareerPopup, setEditCareerPopup] = useState(false);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [isSorting, setIsSorting] = useState(false);
  const { t } = useTranslation();

  const [selectedFilterName, setSelectedFilterName] = useState(null);
  const [allInstitutes, setAllInstitutes] = useState<IInstituteCheckbox[]>([]);
  const { setTableRequest, setFilters, pageCount, filters, tableRequest, data } =
    useAdminWebSocket<ICareer>(WsTable.careers, WsResponseAction.careersTable);

  // HANDLERS
  const handlePageClick = (event: any) => {
    setTableRequest(prevState => ({ ...prevState, page: event.selected + 1 }));
  };

  const performFiltration = (
    orderBy: WsOrderValues,
    orderType: WsOrderTypes,
    filterName: string
  ) => {
    setTableRequest(prevState => ({ ...prevState, page: 1, orderBy, orderType }));
    setSelectedFilterName(filterName);
    setIsSorting(true);
  };

  const resetFiltration = () => {
    setTableRequest(prevState => ({
      ...prevState,
      page: 1,
      orderBy: WsOrderValues.createdDate,
      orderType: WsOrderTypes.desc,
    }));
    setSelectedFilterName(null);
    const uncheckedInstitutes = [...allInstitutes];
    for (let i of uncheckedInstitutes) {
      i.checked = false;
    }
    setAllInstitutes(uncheckedInstitutes);
    setIsSorting(false);
  };

  const openDeleteCareerPopup = (id: number) => {
    setDeleteCareerPopup(true);
    const editedCareer = data.filter(a => a.id === id)[0];
    setSelectedCareer(editedCareer);
  };

  const openEditCareerPopup = (id: number) => {
    const editedCareer = data.filter(a => a.id === id)[0];
    setSelectedCareer(editedCareer);
    setEditCareerPopup(true);
  };

  const deleteCareer = async () => {
    setIsFormSubmitting(true);
    await careersService.deleteCareer(selectedCareer.id);
    toast.success(t("careers-table.career-deleted"));
    setDeleteCareerPopup(false);
    setIsFormSubmitting(false);
  };

  const handleInstituteCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = event.target;
    const index = allInstitutes.findIndex(i => i.id === +id);
    const newArray = [...allInstitutes];
    newArray[index].checked = checked;
    setAllInstitutes(newArray);
    setIsSorting(true);

    if (requestedInstitute !== "" && requestedInstitute.toString() === id) {
      dispatch(setRequstedInstitute(""));
    }
  };

  // SEARCH
  useEffect(() => {
    const fIndex = filters.findIndex(f => f.filterType === WsFilterTypes.byDefault);
    const newFilter = [...filters];
    newFilter[fIndex].filterVariable = searchQuery;
    setFilters(newFilter);
  }, [searchQuery]);

  useEffect(() => {
    dispatch(setSearchPlaceholder(t("careers-table.search-placeholder")));

    return () => {
      dispatch(setSearchQuery(""));
      dispatch(setRequstedInstitute(""));
    };
  }, [dispatch]);

  // Get requsted career list from institutes table
  useEffect(() => {
    if (requestedInstitute !== "") {
      const newFilter = [...filters];
      newFilter.push({
        filterType: WsFilterTypes.byIntitutes,
        filterVariable: requestedInstitute.toString(),
      });
      setFilters(newFilter);
    }
  }, [requestedInstitute]);

  // Get institutes list for dropdown
  useEffect(() => {
    const getAllInstitutes = async () => {
      const resp = await institutesService.getAvailableInstitutes();
      if (!resp.error) {
        // add checked false to all entries
        const instWithChecks = resp.map(o => {
          return { ...o, checked: false };
        });
        if (requestedInstitute) {
          const idx = instWithChecks.findIndex(i => i.id === +requestedInstitute);
          instWithChecks[idx].checked = true;
        }
        setAllInstitutes(instWithChecks);
      }
    };
    if (currentUserRole <= UserRole.admin) {
      getAllInstitutes();
    } else {
      const currentInstitute = { id: currentUserId, name: currentUserName, checked: false };
      setAllInstitutes([currentInstitute]);
    }
  }, [currentUserId, currentUserName, currentUserRole, requestedInstitute]);

  // Perform institution checkbox filtration
  useEffect(() => {
    if (allInstitutes.length === 0) {
      return;
    }
    const selectedCheckboxes = [];
    for (let a of allInstitutes) {
      if (a.checked) {
        selectedCheckboxes.push(a.id);
      } else {
        selectedCheckboxes.splice(a.id, 1);
      }
    }

    const fIndex = filters.findIndex(f => f.filterType === WsFilterTypes.byIntitutes);
    const newFilter = [...filters];

    if (selectedCheckboxes.length > 0) {
      if (fIndex > -1) {
        newFilter[fIndex].filterVariable = selectedCheckboxes.join();
      } else {
        newFilter.push({
          filterType: WsFilterTypes.byIntitutes,
          filterVariable: selectedCheckboxes.join(),
        });
      }
    } else {
      if (fIndex > -1) {
        newFilter.splice(fIndex, 1);
      }
    }
    setFilters(newFilter);
  }, [allInstitutes]);

  // Observe filter change
  useEffect(() => {
    setTableRequest(prevState => ({ ...prevState, filters }));
  }, [filters]);

  return (
    <>
      <AdminHeader />

      <AdminStatCards />

      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="d-flex flex-column justify-content-between flex-md-row">
                  <h3 className="mb-1 mb-md-0">{t("careers-table.title")}</h3>
                  <div className="row justify-content-end">
                    {/* FILTER by institution */}
                    {currentUserRole <= UserRole.admin && (
                      <UncontrolledDropdown>
                        <DropdownToggle role="button" color="primary" outline caret>
                          {t("admin-panel.filters.institution")}
                        </DropdownToggle>
                        <DropdownMenu
                          className="dropdown-menu-arrow"
                          right
                          style={{ maxHeight: 500, overflowY: "auto" }}
                        >
                          {allInstitutes.length > 0 &&
                            allInstitutes.map(i => (
                              <DropdownItem key={i.id} toggle={false}>
                                <FormGroup check inline className="w-100">
                                  <Input
                                    type="checkbox"
                                    id={i.id.toString()}
                                    name={i.name}
                                    value={i.name}
                                    onChange={e => handleInstituteCheckbox(e)}
                                    defaultChecked={i.checked}
                                  />
                                  <Label
                                    check
                                    for={i.id.toString()}
                                    className="w-100"
                                    role="button"
                                  >
                                    {i.name}
                                  </Label>
                                </FormGroup>
                              </DropdownItem>
                            ))}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    )}

                    <Button
                      color="primary"
                      className="mb-1 mb-lg-0"
                      onClick={() => setNewCareerPopup(true)}
                    >
                      {t("careers-table.add")}
                    </Button>

                    <UncontrolledDropdown>
                      <DropdownToggle role="button" color="primary" outline caret>
                        {selectedFilterName ? selectedFilterName : t("admin-panel.filters.base")}
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-arrow" right>
                        <DropdownItem
                          onClick={() =>
                            performFiltration(
                              WsOrderValues.alphabetically,
                              WsOrderTypes.asc,
                              t("admin-panel.filters.sortAZasc")
                            )
                          }
                        >
                          {t("admin-panel.filters.sortAZasc")}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            performFiltration(
                              WsOrderValues.alphabetically,
                              WsOrderTypes.desc,
                              t("admin-panel.filters.sortAZdesc")
                            )
                          }
                        >
                          {t("admin-panel.filters.sortAZdesc")}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            performFiltration(
                              WsOrderValues.createdDate,
                              WsOrderTypes.desc,
                              t("admin-panel.filters.dateNewest")
                            )
                          }
                        >
                          {t("admin-panel.filters.dateNewest")}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            performFiltration(
                              WsOrderValues.createdDate,
                              WsOrderTypes.asc,
                              t("admin-panel.filters.dateOldest")
                            )
                          }
                        >
                          {t("admin-panel.filters.dateOldest")}
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    {isSorting && (
                      <Button
                        color="secondary"
                        className="mb-1 mr-2 mb-lg-0"
                        onClick={resetFiltration}
                      >
                        {t("admin-panel.filters.reset")}
                      </Button>
                    )}
                  </div>
                </div>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">{t("admin-panel.career")}</th>
                    <th scope="col">{t("admin-panel.faculty")}</th>
                    {currentUserRole <= UserRole.admin && (
                      <th scope="col">{t("admin-panel.institution")}</th>
                    )}
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {data.map(career => (
                    <tr key={career.id}>
                      <td>{career.name}</td>
                      <td>{career.facultyName}</td>
                      {currentUserRole <= UserRole.admin && <td>{career.instituteName}</td>}
                      <td className="text-right">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            role="button"
                            size="sm"
                            color=""
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right container="body">
                            <DropdownItem onClick={() => openEditCareerPopup(career.id)}>
                              {t("admin-panel.edit")}
                            </DropdownItem>
                            <DropdownItem onClick={() => openDeleteCareerPopup(career.id)}>
                              {t("admin-panel.delete")}
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel={<i className="fas fa-angle-right" />}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={0}
                    pageCount={pageCount}
                    previousLabel={<i className="fas fa-angle-left" />}
                    className="pagination justify-content-end mb-0"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    activeClassName="active"
                    disabledClassName="disabled"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    forcePage={tableRequest.page - 1}
                  />
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>

      {/* POPUPS */}
      <AddCareerPopup
        newCareerPopup={newCareerPopup}
        setNewCareerPopup={setNewCareerPopup}
        allInstitutes={allInstitutes}
      />
      <EditCareerPopup
        editCareerPopup={editCareerPopup}
        setEditCareerPopup={setEditCareerPopup}
        selectedCareer={selectedCareer}
        allInstitutes={allInstitutes}
      />
      <ConfirmationPopup
        title={t("confirmation-popup.title.delete-career")}
        text={t("confirmation-popup.text.delete-career")}
        popupState={deleteCareerPopup}
        handlePopup={setDeleteCareerPopup}
        submitAction={deleteCareer}
        btnText={t("confirmation-popup.btn-text.delete")}
        btnColor="danger"
        isFormSubmitting={isFormSubmitting}
      />
    </>
  );
};

export default CareersTable;
