import React, { useState } from "react";
import { Modal, Form, Input, Button } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { adminsService } from "../../services/admins.service";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const validationEditAdmin = Yup.object({
  editAdminName: Yup.string()
    .required("Enter the name of the admin")
    .min(2, "Minimum string length is 2 characters")
    .max(64, "Maximum string length is 64 characters")
    .matches(
      /^[aA-zZ\s]+$/,
      "Entering special characters is prohibited. Please enter the correct name of the admin"
    ),
  editAdminEmail: Yup.string()
    .required("Enter the email address")
    .email("Please enter a valid e-mail address."),
});

export const EditAdminPopup = ({
  editAdminPopup,
  setEditAdminPopup,
  selectedAdminEmail,
  selectedAdminName,
  selectedAdmin,
}) => {
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const { t } = useTranslation();

  const formikEditAdmin = useFormik({
    initialValues: {
      editAdminName: selectedAdminName,
      editAdminEmail: selectedAdminEmail,
    },
    validationSchema: validationEditAdmin,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: async values => {
      const toastLoader = toast.loading(t("admin-loading.update-admin"));
      setIsFormSubmitting(true);

      let newData;
      if (values.editAdminName !== selectedAdminName) {
        newData = { name: values.editAdminName };
      }
      if (values.editAdminEmail !== selectedAdminEmail) {
        newData = { ...newData, email: values.editAdminEmail };
      }
      const resp = await adminsService.updateAdmin(selectedAdmin, newData);
      if (resp.error) {
        toast.update(toastLoader, {
          render: resp.error,
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
        setIsFormSubmitting(false);
        return;
      } else {
        toast.update(toastLoader, {
          render: t("edit-admin-popup.updated"),
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
        setIsFormSubmitting(false);
        closeAndClearPopup();
      }
    },
  });

  const closeAndClearPopup = () => {
    formikEditAdmin.values.editAdminName = "";
    formikEditAdmin.values.editAdminEmail = "";
    formikEditAdmin.touched.editAdminName = false;
    formikEditAdmin.touched.editAdminEmail = false;
    setEditAdminPopup(false);
  };

  return (
    <Modal className="modal-dialog-centered" isOpen={editAdminPopup} toggle={closeAndClearPopup}>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          {t("edit-admin-popup.title")}
        </h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={closeAndClearPopup}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <Form onSubmit={formikEditAdmin.handleSubmit}>
        <div className="modal-body">
          <p>{t("edit-admin-popup.description")}</p>
          <Input
            placeholder={t("edit-admin-popup.form.name")}
            type="text"
            className="pl-2"
            {...formikEditAdmin.getFieldProps("editAdminName")}
          />
          {formikEditAdmin.touched.editAdminName && formikEditAdmin.errors.editAdminName && (
            <small className="text-danger mb-1">{formikEditAdmin.errors.editAdminName}</small>
          )}
          <Input
            placeholder={t("edit-admin-popup.form.email")}
            type="email"
            className="pl-2 mt-3"
            {...formikEditAdmin.getFieldProps("editAdminEmail")}
          />
          {formikEditAdmin.touched.editAdminEmail && formikEditAdmin.errors.editAdminEmail && (
            <small className="text-danger mb-1">{formikEditAdmin.errors.editAdminEmail}</small>
          )}
        </div>
        <div className="modal-footer">
          <Button color="secondary" type="button" onClick={closeAndClearPopup}>
            {t("admin-panel.cancel")}
          </Button>
          <Button color="primary" type="submit" disabled={isFormSubmitting}>
            {t("admin-panel.save")}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};
