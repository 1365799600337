import logo from "../../assets/icons/logo-new.svg";
import Searchbar from "../../components/Searchbar/Searchbar";
import VideoJS from "../../components/VideoJS/VideoJS";
import { videoUrl } from "../../constants";
import "./searchPage.scss";
import { useTranslation } from "react-i18next";

const SearchPage = () => {
  const {t} = useTranslation()
  const videoJsOptions = {
    autoplay: false,
    controls: true,
    responsive: true,
    fluid: true,
    sources: [
      {
        src: videoUrl,
        type: "video/mp4",
      },
    ],
  };

  return (
    <main className="container search-page">
      <div className="search-page__logo">
        <img src={logo} alt="logo" /> BitGraduate
      </div>
      <div className="search-page__search-wrapper">
        <Searchbar isInHeader={false} />
      </div>
      <div className="search-page__descr">
        <div className="search-page__about">
          <h2 className="title">{t('searchPage.title')}</h2>
          <p>
            {t("searchPage.about")}
          </p>
        </div>
        <div className="search-page__iframe-container">
          <VideoJS options={videoJsOptions} />
        </div>
      </div>
    </main>
  );
};

export default SearchPage;
