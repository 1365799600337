import "./uploadedFileIcon.scss";

import pdfIcon from "../../../assets/icons/pdf-icon-2.png";
import deleteIcon from "../../../assets/icons/verification/delete.svg";
import { useMemo } from "react";

interface IUploadedFileIconProps {
  file: File;
  onDelete: () => void;
}

export function UploadedFileIcon(props: IUploadedFileIconProps) {
  const { file, onDelete } = props;

  const croppedFileName = useMemo(() => {
    const { name } = file;
    const nameArray = name.split("");

    if (nameArray.length > 20) {
      return nameArray.slice(0, 19).join("").concat("...");
    }

    return name;
  }, [file]);

  return (
    <div className="uploaded-file">
      <div className="uploaded-file__icon">
        <img src={pdfIcon} alt="" />
      </div>
      <small>{croppedFileName}</small>

      <button className="uploaded-file__delete-button" onClick={onDelete}>
        <img src={deleteIcon} alt="" />
      </button>
    </div>
  );
}
