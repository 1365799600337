import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Input,
  Form,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { fetchOverallStats } from "../../app/state/adminPanelSlice";
import { facultyService } from "../../services/faculty.service";
import { useAppDispatch, useAppSelector } from "../../app/hooks/hooks";
import { useTranslation } from "react-i18next";
import { IInstitute } from "../../app/models/adminPanel.interface";
import { UserRole } from "../../app/models/role.interface";

interface IAddFacultyPopupProps {
  isAddFacultyOpen: boolean;
  toggleAddFacultyPopup: () => void;
  allInstitutes: IInstitute[];
}

const validationAddFaculty = Yup.object({
  name: Yup.string()
    .required("Enter name of the faculty")
    .max(50, "Maximum string length is 50 characters")
});

const AddFacultyPopup = (props: IAddFacultyPopupProps) => {
  const { isAddFacultyOpen, toggleAddFacultyPopup, allInstitutes } = props;
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const { role: userRole } = useAppSelector(state => state.user);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [selectedInstitute, setSelectedInstitute] = useState<IInstitute>(null);
  const isAdmin = userRole < UserRole.insitute;

  const formikAddFaculty = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: validationAddFaculty,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async values => {
      if (isAdmin && !selectedInstitute) {
        toast.error(t("add-employee-popup.select-institute"));
        return;
      }

      const newFaculty = {
        name: values.name,
      };
      if (isAdmin) {
        newFaculty["instituteId"] = selectedInstitute.id;
      }

      const toastLoader = toast.loading(t("admin-loading.create-new-faculty"));
      setIsFormSubmitting(true);

      const resp = await facultyService.addNewFaculty(newFaculty);
      if (resp.error) {
        toast.update(toastLoader, {
          render: resp.error,
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
        setIsFormSubmitting(false);
        return;
      }
      toast.update(toastLoader, {
        render: t("add-faculty-popup.created"),
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
      setIsFormSubmitting(false);
      dispatch(fetchOverallStats());
      closeAndClearPopup();
    },
  });

  const closeAndClearPopup = () => {
    formikAddFaculty.resetForm();
    toggleAddFacultyPopup();
  };

  // Auto select institution if it is only one
  useEffect(() => {
    if (isAddFacultyOpen && allInstitutes.length === 1) {
      setSelectedInstitute(allInstitutes[0]);
    }
  }, [allInstitutes, isAddFacultyOpen]);

  return (
    <Modal
      isOpen={isAddFacultyOpen}
      toggle={toggleAddFacultyPopup}
      className="modal-dialog-centered"
    >
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          {t("add-faculty-popup.title")}
        </h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={toggleAddFacultyPopup}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="p-4">
        <Form onSubmit={formikAddFaculty.handleSubmit}>
          <div>
            <p>{t("add-faculty-popup.description")}</p>
            <Input
              placeholder={t("add-faculty-popup.form.name")}
              type="text"
              className="pl-2"
              {...formikAddFaculty.getFieldProps("name")}
            />
            {formikAddFaculty.touched.name && formikAddFaculty.errors.name && (
              <small className="text-danger mb-1">{formikAddFaculty.errors.name}</small>
            )}
          </div>
          {isAdmin && (
            <UncontrolledDropdown className="mt-3 d-block">
              <DropdownToggle
                role="button"
                color="primary"
                outline
                caret
                style={{
                  maxWidth: "100%",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {selectedInstitute
                  ? selectedInstitute.name
                  : t("add-employee-popup.form.institute")}
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow">
                {allInstitutes?.length > 0 &&
                  allInstitutes.map(i => (
                    <DropdownItem key={i.id} onClick={() => setSelectedInstitute(i)}>
                      {i.name}
                    </DropdownItem>
                  ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          )}
          <div className="d-flex justify-content-end mt-4">
            <Button color="secondary" type="button" onClick={toggleAddFacultyPopup}>
              {t("admin-panel.cancel")}
            </Button>
            <Button color="primary" type="submit" disabled={isFormSubmitting}>
              {t("admin-panel.add")}
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default AddFacultyPopup;
