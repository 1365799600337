import "./studentPasswordChangeForm.scss";

import { useFormik } from "formik";
import { useState } from "react";
import { toast } from "react-toastify";
import { Button, Form, FormGroup, Input, Label, Modal } from "reactstrap";
import * as Yup from "yup";

import { userService } from "../../../services/user.service";

import lockIcon from "../../../assets/icons/student/lock.svg";
import { useTranslation } from "react-i18next";

const studentPasswordChangeFormValidationSchema = Yup.object({
  password: Yup.string()
    .required()
    .min(6, "The password is too short. The minimum password length is 6 characters.")
    .max(15, "The password is too long. The maximum password length is 15 characters."),
  newPassword: Yup.string()
    .required()
    .min(6, "The password is too short. The minimum password length is 6 characters.")
    .max(15, "The password is too long. The maximum password length is 15 characters.")
    .matches(
      /(?=.*[0-9])(?=.*[~`!@#$%^&*().\/\\\[\]{}\-_=+><;:?,|'"])(?=.*[a-z])(?=.*[A-Z])/,
      "At least one uppercase English letter, one lower case, one digit and one special character."
    ),
  confirmPassword: Yup.string()
    .required()
    .min(6, "The password is too short. The minimum password length is 6 characters.")
    .max(15, "The password is too long. The maximum password length is 15 characters.")
    .matches(
      /(?=.*[0-9])(?=.*[~`!@#$%^&*().\/\\\[\]{}\-_=+><;:?,|'"])(?=.*[a-z])(?=.*[A-Z])/,
      "At least one uppercase English letter, one lower case, one digit and one special character."
    )
    .test("passwords-match", "Passwords must match", function (value) {
      return this.parent.newPassword === value;
    }),
  twoFactorToken: Yup.string()
    .required("Please, enter a token.")
    .matches(/[0-9]+/, "Token includes numbers only")
    .length(6, "Token must be 6 numbers long."),
});

export const StudentPasswordChangeForm = () => {
  const [passwordChangeError, setPasswordChangeError] = useState("");
  const [twoFactorError, setTwoFactorError] = useState("");
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {t} = useTranslation()

  const formik = useFormik({
    initialValues: {
      password: "",
      newPassword: "",
      confirmPassword: "",
      twoFactorToken: "",
    },
    validationSchema: studentPasswordChangeFormValidationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: () => null,
  });

  const openModal = () => {
    if (formik.values.password === formik.values.newPassword) {
      setPasswordChangeError(t('studentSettings.equal'));
      return;
    }
    setPasswordChangeError("");
    formik.validateForm()
    const errors = Object.keys(formik.errors).filter(item => item !== 'twoFactorToken').length
    if(errors > 0) {
      return
    }
    setIsModalOpen(true);
  };

  const changePassword = async () => {
    setIsFormSubmitting(true);

    try {
      await userService.updateUserPassword(
        formik.values.password,
        formik.values.newPassword,
        formik.values.twoFactorToken
      );

      toast.success(t('studentSettings.success'), { autoClose: 3000 });
      setPasswordChangeError("");
      setTwoFactorError("");
      setIsModalOpen(false);
    } catch (error) {
      if (error.message.includes("token")) {
        setTwoFactorError(error.message);
        return;
      }
      setPasswordChangeError(error.message);
      setIsModalOpen(false);
    } finally {
      setIsFormSubmitting(false);
    }
  };

  const toggleModal = () => {
    if (!isFormSubmitting) {
      setIsModalOpen(false);
    }
  };

  return (
    <>
      <Form className="student-password-form" onSubmit={formik.handleSubmit}>
        <FormGroup className="student-password-form__form-group">
          <Label for="password">{t('studentSettings.password')}</Label>
          <Input
            id="password"
            type="password"
            className="student-password-form__input"
            autoComplete="off"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password && (
            <small className="text-danger mt-1">{formik.errors.password}</small>
          )}
        </FormGroup>
        <FormGroup className="student-password-form__form-group">
          <Label for="newPassword">{t('studentSettings.newPassword')}</Label>
          <Input
            id="newPassword"
            type="password"
            className="student-password-form__input"
            autoComplete="off"
            {...formik.getFieldProps("newPassword")}
          />
          {formik.touched.newPassword && formik.errors.newPassword && (
            <small className="text-danger mt-1">{formik.errors.newPassword}</small>
          )}
        </FormGroup>
        <FormGroup className="student-password-form__form-group">
          <Label for="confirmPassword">{t('studentSettings.confirmPassword')}</Label>
          <Input
            id="confirmPassword"
            type="password"
            className="student-password-form__input"
            autoComplete="off"
            {...formik.getFieldProps("confirmPassword")}
          />
          {formik.touched.confirmPassword && formik.errors.confirmPassword && (
            <small className="text-danger mt-1">{formik.errors.confirmPassword}</small>
          )}
        </FormGroup>
        <Button
          type="button"
          color="primary"
          className="student-password-form__submit-button"
          disabled={isFormSubmitting}
          onClick={openModal}
        >
          {t('submit.basic')}
        </Button>
        {passwordChangeError && <small className="text-danger mb-1">{passwordChangeError}</small>}
      </Form>

      <Modal
        isOpen={isModalOpen}
        toggle={toggleModal}
        centered
        className="student-password-form__modal"
      >
        <div className="student-password-form__modal-content">
          <div className="student-password-form__modal-header">
            <h3 className="student-password-form__modal-title">{t('studentSettings.confirmChange')}</h3>
            <img className="student-password-form__modal-icon" src={lockIcon} alt="" />
          </div>
          <FormGroup>
            <Label for="token">{t('studentSettings.google')}</Label>
            <Input
              id="token"
              placeholder="000000"
              className="student-password-form__input"
              autoComplete="off"
              {...formik.getFieldProps("twoFactorToken")}
            />
            {formik.touched.twoFactorToken && formik.errors.twoFactorToken && (
              <small className="text-danger mt-1">{formik.errors.twoFactorToken}</small>
            )}
          </FormGroup>

          {twoFactorError && <small className="text-danger mb-1">{twoFactorError}</small>}

          <Button
            type="button"
            color="primary"
            className="student-password-form__modal-button"
            disabled={isFormSubmitting}
            onClick={changePassword}
          >
            {t('submit.verify')}
          </Button>
        </div>
      </Modal>
    </>
  );
};
