import "./verifiedFileCard.scss";

import pdfIcon from "../../../assets/icons/pdf-icon-2.png";
import filesPlaceholder from "../../../assets/icons/verification/files-placeholder.svg";
import { useTranslation } from "react-i18next";

export interface IVerifiedFileCardProps {
  id: string;
  ownerName: string;
  careerName: string;
  graduationDate: string;
  isVerified: boolean;
}

export function VerifiedFileCard(props: IVerifiedFileCardProps) {
  const { id, ownerName, careerName, graduationDate, isVerified } = props;
  const {t} = useTranslation()

  return (
    <>
      {isVerified ? (
        <div className="file-card">
          <div className="file-card__header">
            <span>{t('verifiedFileCard.verified')}</span>
          </div>

          <div className="file-card__props-wrapper">
            <p className="file-card__prop">
              {t('verifiedFileCard.name')}
              <br />
              <span>{ownerName}</span>
            </p>

            <p className="file-card__prop">
              {t('verifiedFileCard.certificateId')}
              <br />
              <span>{id}</span>
            </p>

            <p className="file-card__prop">
              {t('verifiedFileCard.career')}
              <br />
              <span>{careerName}</span>
            </p>

            <p className="file-card__prop">
              {t('verifiedFileCard.date')}
              <br />
              <span>{new Date(graduationDate).toLocaleDateString()}</span>
            </p>
          </div>

          <a
            href={`/diploma/${id}`}
            target="_blank"
            rel="noreferrer noopener"
            className="file-card__link"
          >
            {t('verifiedFileCard.moreDetails')} <img src={filesPlaceholder} alt="" />
          </a>
        </div>
      ) : (
        <div className="file-card file-card_error">
          <div className="file-card__header file-card__header_error">
            <span>{t('verifiedFileCard.notFound')}</span>
          </div>

          <div className="file-card__error-wrapper">
            <div className="file-card__icon-wrapper">
              <div className="file-card__icon">
                <img src={pdfIcon} alt="" />
              </div>
            </div>
            <p>{ownerName}</p>
          </div>
        </div>
      )}
    </>
  );
}
