import "./benefits.scss";

import { Swiper, SwiperSlide } from "swiper/react";
import { BenefitsCard } from "./BenefitsCard";

import firstInstitutionsBg from "../../../assets/images/main-page/benefits/institutions-bg-1.svg";
import secondInstitutionsBg from "../../../assets/images/main-page/benefits/institutions-bg-2.svg";
import thirdInstitutionsBg from "../../../assets/images/main-page/benefits/institutions-bg-3.svg";
import firstInstitutionsIcon from "../../../assets/images/main-page/benefits/institutions-icon-1.svg";
import secondInstitutionsIcon from "../../../assets/images/main-page/benefits/institutions-icon-2.svg";
import thirdInstitutionsIcon from "../../../assets/images/main-page/benefits/institutions-icon-3.svg";

import firstCompaniesBg from "../../../assets/images/main-page/benefits/companies-bg-1.svg";
import secondCompaniesBg from "../../../assets/images/main-page/benefits/companies-bg-2.svg";
import thirdCompaniesBg from "../../../assets/images/main-page/benefits/companies-bg-3.svg";
import firstCompaniesIcon from "../../../assets/images/main-page/benefits/companies-icon-1.svg";
import secondCompaniesIcon from "../../../assets/images/main-page/benefits/companies-icon-2.svg";
import thirdCompaniesIcon from "../../../assets/images/main-page/benefits/companies-icon-3.svg";
import { useTranslation } from "react-i18next";


interface IBenefitsListProps {
  benefitsTarget: "institutions" | "companies";
}

export function BenefitsList({ benefitsTarget }: IBenefitsListProps) {
  const {t} = useTranslation()

  const companiesBenefitsList = [
    {
      id: "01",
      icon: firstInstitutionsIcon,
      background: firstInstitutionsBg,
      title: t("benefitsList.verification"),
      text: t("benefitsList.noMore"),
    },
    {
      id: "02",
      icon: secondInstitutionsIcon,
      background: secondInstitutionsBg,
      title: t("benefitsList.actualInformation"),
      text: t("benefitsList.onlyByAcademic"),
    },
    {
      id: "03",
      icon: thirdInstitutionsIcon,
      background: thirdInstitutionsBg,
      title: t("benefitsList.productivity"),
      text: t("benefitsList.truthful"),
    },
  ];

  const institutionsBenefitsList = [
    {
      id: "01",
      icon: firstCompaniesIcon,
      background: firstCompaniesBg,
      title: t("benefitsList.moreControl"),
      text: t("benefitsList.simple"),
    },
    {
      id: "02",
      icon: secondCompaniesIcon,
      background: secondCompaniesBg,
      title: t("benefitsList.timeEfficiency"),
      text: t("benefitsList.verify"),
    },
    {
      id: "03",
      icon: thirdCompaniesIcon,
      background: thirdCompaniesBg,
      title: t("benefitsList.imageProtection"),
      text: t("benefitsList.protect"),
    },
  ];

  const benefitsList =
    benefitsTarget === "institutions" ? institutionsBenefitsList : companiesBenefitsList;

  return (
    <>
      <div className="benefits-list">
        {benefitsList.map(benefits => (
          <BenefitsCard key={benefits.id} benefits={benefits} />
        ))}
      </div>

      <Swiper spaceBetween={25} slidesPerView={1.1} className="benefits-slider">
        {benefitsList.map(benefits => (
          <SwiperSlide key={benefits.id}>
            <BenefitsCard benefits={benefits} />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}
