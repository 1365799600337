import "./studentEmailChangeForm.scss";

import { useFormik } from "formik";
import { useState } from "react";
import { toast } from "react-toastify";
import { Button, Form, FormGroup, Input, Label, Modal } from "reactstrap";
import * as Yup from "yup";

import { useAppSelector } from "../../../app/hooks/hooks";
import { userService } from "../../../services/user.service";

import lockIcon from "../../../assets/icons/student/lock.svg";
import { useTranslation } from "react-i18next";

const studentEmailChangeFormValidationSchema = Yup.object({
  email: Yup.string().required().email("Please enter a valid e-mail address"),
  twoFactorToken: Yup.string()
    .required("Please, enter a token.")
    .matches(/[0-9]+/, "Token includes numbers only")
    .length(6, "Token must be 6 numbers long."),
});

export const StudentEmailChangeForm = () => {
  const userEmail = useAppSelector(state => state.user.email);
  const [emailChangeError, setEmailChangeError] = useState("");
  const [twoFactorError, setTwoFactorError] = useState("");
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {t} = useTranslation()

  const formik = useFormik({
    initialValues: {
      email: userEmail || "",
      twoFactorToken: "",
    },
    validationSchema: studentEmailChangeFormValidationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: () => null,
  });

  const changeEmail = async () => {
    setIsFormSubmitting(true);

    const resp = await userService.updateUserEmail(
      formik.values.email,
      formik.values.twoFactorToken
    );

    if (resp && resp.error) {
      if (resp.error.includes("token")) {
        setTwoFactorError(resp.error);
        setIsFormSubmitting(false);
        return;
      }
      setEmailChangeError(resp.error);
      setIsFormSubmitting(false);
      setIsModalOpen(false);
      return;
    }

    toast.success(t('studentSettings.successEmail'), { autoClose: 3000 });
    setEmailChangeError("");
    setTwoFactorError("");
    setIsFormSubmitting(false);
    setIsModalOpen(false);
    return;
  };

  const toggleModal = () => {
    if (!isFormSubmitting) {
      setIsModalOpen(false);
    }
  };

  return (
    <>
      <Form className="student-email-form" onSubmit={formik.handleSubmit}>
        <Label for="email">{t('studentSettings.email')}</Label>
        <FormGroup className="student-email-form__form-group">
          <Input
            id="email"
            className="student-email-form__input"
            autoComplete="off"
            {...formik.getFieldProps("email")}
          />
          <Button
            type="button"
            color="primary"
            disabled={isFormSubmitting}
            onClick={() => setIsModalOpen(true)}
          >
            {t("studentSettings.change")}
          </Button>
        </FormGroup>
        {formik.touched.email && formik.errors.email && (
          <small className="text-danger mb-1">{formik.errors.email}</small>
        )}
        {emailChangeError && <small className="text-danger mb-1">{emailChangeError}</small>}
      </Form>

      <Modal
        isOpen={isModalOpen}
        toggle={toggleModal}
        centered
        className="student-email-form__modal"
      >
        <div className="student-email-form__modal-content">
          <div className="student-email-form__modal-header">
            <h3 className="student-email-form__modal-title">{t("studentSettings.confirmEmailChange")}</h3>
            <img className="student-email-form__modal-icon" src={lockIcon} alt="" />
          </div>
          <FormGroup>
            <Label for="token">{t("studentSettings.google")}</Label>
            <Input
              id="token"
              placeholder="000000"
              className="student-email-form__input"
              autoComplete="off"
              {...formik.getFieldProps("twoFactorToken")}
            />
            {formik.touched.twoFactorToken && formik.errors.twoFactorToken && (
              <small className="text-danger mt-1">{formik.errors.twoFactorToken}</small>
            )}
          </FormGroup>

          {twoFactorError && <small className="text-danger mb-1">{twoFactorError}</small>}

          <Button
            type="button"
            color="primary"
            className="student-email-form__modal-button"
            disabled={isFormSubmitting}
            onClick={changeEmail}
          >
            {t('submit.confirm')}
          </Button>
        </div>
      </Modal>
    </>
  );
};
