import React from "react";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
import { useAppSelector } from "../../app/hooks/hooks";
import { UserRole } from "../../app/models/role.interface";
import { useTranslation } from "react-i18next";

const AdminStatCards = () => {
  const { role: currentUserRole } = useAppSelector(state => state.user);
  const { statsData } = useAppSelector(state => state.adminPanel);
  const {t} = useTranslation()

  return (
    <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
      <Container fluid>
        <div className="header-body">
          <Row>
            {currentUserRole <= UserRole.admin && (
              <Col lg="6" xl="3" className="mb-4 mb-xl-0">
                <Card className="card-stats h-100">
                  <CardBody>
                    <Row className="flex-nowrap">
                      <div className="col">
                        <CardTitle tag="h6" className="text-uppercase text-muted mb-0">
                          {t('admin-stats.institutes')}
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {statsData.totalInstitutes}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                          <i className="ni ni-hat-3" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            )}
            {currentUserRole <= UserRole.insitute && (
              <Col lg="6" xl="3" className="mb-4 mb-xl-0">
                <Card className="card-stats h-100">
                  <CardBody>
                    <Row className="flex-nowrap">
                      <div className="col">
                        <CardTitle tag="h6" className="text-uppercase text-muted mb-0">
                          {currentUserRole <= UserRole.admin
                            ? t('admin-stats.total-employees')
                            : t('admin-stats.employees')}
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">{statsData.totalEmployees}</span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                          <i className="fas fa-users" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            )}
            <Col lg="6" xl="3" className="mb-4 mb-xl-0">
              <Card className="card-stats h-100">
                <CardBody>
                  <Row className="flex-nowrap">
                    <div className="col">
                      <CardTitle tag="h6" className="text-uppercase text-muted mb-0">
                        {currentUserRole <= UserRole.admin
                          ? t('admin-stats.total-certificates')
                          : t('admin-stats.certificates')}
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0">
                        {statsData.totalCertificates}
                      </span>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-pink text-white rounded-circle shadow">
                        <i className="ni ni-paper-diploma" />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col lg="6" xl="3" className="mb-4 mb-xl-0">
              <Card className="card-stats h-100">
                <CardBody>
                  <Row className="flex-nowrap">
                    <div className="col">
                      <CardTitle tag="h6" className="text-uppercase text-muted mb-0">
                        {/* {currentUserRole <= UserRole.admin
                          ? "Avaliable licenses"
                          : "Limit on the issuance of certificates and diplomas"} */}
                        {t('admin-stats.licenses')}
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0">
                        {statsData.totalLimit - statsData.totalCertificates}
                      </span>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                        <i className="fas fa-chart-pie" />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default AdminStatCards;
