import axiosWithToken from "./request";

interface IFacultyData {
  name: string;
  instituteId?: number;
}

const addNewFaculty = async (data: IFacultyData) => {
  try {
    const response = await axiosWithToken.post("faculty", data);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const editFaculty = async (data: IFacultyData, id: number) => {
  try {
    await axiosWithToken.put(`faculty/${id}`, data);
  } catch (error) {
    throw error.response.data;
  }
};

const deleteFaculty = async (id: number) => {
  try {
    await axiosWithToken.delete(`faculty/${id}`);
  } catch (error) {
    throw error.response.data;
  }
};

const getFacultiesByInstituteId = async (instituteId: number) => {
  try {
    const response = await axiosWithToken.get(`faculty/${instituteId}/institute`);
    return response.data;
  } catch {
    throw new Error("Unexpected error");
  }
};

export const facultyService = {
  addNewFaculty,
  editFaculty,
  deleteFaculty,
  getFacultiesByInstituteId,
};
