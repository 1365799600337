import React, { Dispatch, SetStateAction, useState } from "react";
import { Modal } from "reactstrap";
import info from "../../../assets/icons/info.svg";
import { Button } from "../../../components/Button/Button";
import './ConfirmModal.scss'
import { certificatesService } from "../../../services/certificates.service";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IDiploma } from "../Diploma";


interface IConfirmModalProps {
  isOpen: boolean
  toggleModal: () => void
  setCertificate: Dispatch<SetStateAction<IDiploma>>
}

const ConfirmModal = (props: IConfirmModalProps) => {

  const { id } = useParams();
  const {toggleModal, isOpen, setCertificate} = props
  const {t} = useTranslation()
  const [isLoading, setIsLoading] = useState(false)

  const confirmHandler = async () => {
    setIsLoading(true)
    try {
      await certificatesService.approveCertificate(id)
      toast.success(t('diploma.approved'))
      setCertificate(prevState => {
        return {
          ...prevState,
          isApproved: true
        }
      })
      toggleModal()
    } catch (error) {
      if(error instanceof Error) {
        toast.error(error.message)
        return
      }

      toast.error(t('error.unexpected'))
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal isOpen={isOpen} toggle={toggleModal} centered>
      <div className="confirm-modal">
        <div className="confirm-modal__header">
          <p>{t('confirm-details.sure')}</p>
          <img src={info} alt="info" />
        </div>
        <div className="confirm-modal__content">
          <p>{t('confirm-details.once')}</p>
          <p>{t('confirm-details.correct')}</p>
        </div>
        <div className="confirm-modal__buttons">
          <Button className="confirm-modal__button" onClick={confirmHandler} disabled={isLoading}>{t('submit.confirm')}</Button>
          <Button variant="outline" className="confirm-modal__button" onClick={toggleModal} disabled={isLoading}>{t('submit.back')}</Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
