import React, { useEffect, useState } from "react";
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Table,
  Container,
  Row,
  Button,
} from "reactstrap";
import { AddAdminPopup } from "../../components/AdminComponents/AddAdminPopup";
import AdminHeader from "../../components/AdminComponents/AdminHeader";
import AdminStatCards from "../../components/AdminComponents/AdminStatCards";
import { ConfirmationPopup } from "../../components/AdminComponents/ConfirmationPopup";
import { EditAdminPopup } from "../../components/AdminComponents/EditAdminPopup";
import { adminsService } from "../../services/admins.service";
import { toast } from "react-toastify";
import {
  WsFilterTypes,
  WsOrderTypes,
  WsOrderValues,
  WsResponseAction,
  WsTable,
} from "../../app/models/ws.interface";
import ReactPaginate from "react-paginate";
import { useAppDispatch, useAppSelector } from "../../app/hooks/hooks";
import { setSearchPlaceholder, setSearchQuery } from "../../app/state/adminPanelSlice";
import { IAdmin } from "../../app/models/adminPanel.interface";
import { useAdminWebSocket } from "../../app/hooks/useAdminWebSocket";
import { useTranslation } from "react-i18next";

const AdminsTable = () => {
  const dispatch = useAppDispatch();
  const searchQuery = useAppSelector(state => state.adminPanel.searchQuery);
  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [newAdminPopup, setNewAdminPopup] = useState(false);
  const [deleteAdminPopup, setDeleteAdminPopup] = useState(false);
  const [activateAdminPopup, setActivateAdminPopup] = useState(false);
  const [deactivateAdminPopup, setDeactivateAdminPopup] = useState(false);
  const [editAdminPopup, setEditAdminPopup] = useState(false);
  const [selectedAdminName, setSelectedAdminName] = useState("");
  const [selectedAdminEmail, setSeletedAdminEmail] = useState("");
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const { t } = useTranslation();

  const [selectedFilterName, setSelectedFilterName] = useState(null);

  const { setTableRequest, pageCount, tableRequest, data } = useAdminWebSocket<IAdmin>(
    WsTable.admins,
    WsResponseAction.adminsTable
  );

  // HANDLERS
  const handlePageClick = (event: any) => {
    setTableRequest(prevState => ({ ...prevState, page: event.selected + 1 }));
  };

  const performFiltration = (
    orderBy: WsOrderValues,
    orderType: WsOrderTypes,
    filterName: string
  ) => {
    setTableRequest(prevState => ({ ...prevState, page: 1, orderBy, orderType }));
    setSelectedFilterName(filterName);
  };

  const resetFiltration = () => {
    setTableRequest(prevState => ({
      ...prevState,
      page: 1,
      orderBy: WsOrderValues.createdDate,
      orderType: WsOrderTypes.desc,
    }));
    setSelectedFilterName(null);
  };

  const openDeleteAdminPopup = (id: number) => {
    setDeleteAdminPopup(true);
    setSelectedAdmin(id);
  };
  const openActivateAdminPopup = (id: number) => {
    setActivateAdminPopup(true);
    setSelectedAdmin(id);
  };
  const openDeactivateAdminPopup = (id: number) => {
    setDeactivateAdminPopup(true);
    setSelectedAdmin(id);
  };
  const openEditAdminPopup = (id: number) => {
    setSelectedAdmin(id);
    const editedAdmin = data.filter(a => a.id === id)[0];
    setSelectedAdminName(editedAdmin.name);
    setSeletedAdminEmail(editedAdmin.email);
    setEditAdminPopup(true);
  };

  const deleteAdmin = async () => {
    const toastLoader = toast.loading(t("admin-loading.delete-admin"));
    // console.log(selectedAdmin);
    setIsFormSubmitting(true);
    await adminsService.deleteAdmin(selectedAdmin);
    toast.update(toastLoader, {
      render: t("admins-table.admin-deleted"),
      type: "success",
      isLoading: false,
      autoClose: 3000,
    });
    setDeleteAdminPopup(false);
    setIsFormSubmitting(false);
  };

  const activateAdmin = async () => {
    setIsFormSubmitting(true);
    const resp = await adminsService.updateAdmin(selectedAdmin, { isActive: true });
    if (resp.error) {
      toast.error(resp.error);
      return;
    }
    toast.success(t("admins-table.admin-activated"));
    setActivateAdminPopup(false);
    setIsFormSubmitting(false);
  };

  const deactivateAdmin = async () => {
    setIsFormSubmitting(true);
    const resp = await adminsService.updateAdmin(selectedAdmin, { isActive: false });
    if (resp.error) {
      toast.error(resp.error);
      return;
    }
    toast.success(t("admins-table.admin-deactivated"));
    setDeactivateAdminPopup(false);
    setIsFormSubmitting(false);
  };

  const copyWalletToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(() => toast.success(t("admins-table.copied")));
  };

  // SEARCH
  useEffect(() => {
    const newFilter = {
      filterType: WsFilterTypes.byDefault,
      filterVariable: searchQuery,
    };
    setTableRequest(prevState => ({ ...prevState, filters: [newFilter] }));
  }, [searchQuery]);

  useEffect(() => {
    dispatch(setSearchPlaceholder(t("admins-table.search-placeholder")));

    return () => {
      dispatch(setSearchQuery(""));
    };
  }, [dispatch]);

  return (
    <>
      <AdminHeader />

      <AdminStatCards />

      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="d-flex flex-column justify-content-between flex-md-row">
                  <h3 className="mb-1 mb-md-0"></h3>
                  <div className="row justify-content-end">
                    <Button
                      color="primary"
                      className="mb-1 mb-lg-0"
                      onClick={() => setNewAdminPopup(true)}
                    >
                      {t("admins-table.add")}
                    </Button>
                    <UncontrolledDropdown>
                      <DropdownToggle role="button" color="primary" outline caret>
                        {selectedFilterName ? selectedFilterName : t("admin-panel.filters.base")}
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-arrow" right>
                        <DropdownItem
                          onClick={() =>
                            performFiltration(
                              WsOrderValues.alphabetically,
                              WsOrderTypes.asc,
                              t("admin-panel.filters.sortAZasc")
                            )
                          }
                        >
                          {t("admin-panel.filters.sortAZasc")}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            performFiltration(
                              WsOrderValues.alphabetically,
                              WsOrderTypes.desc,
                              t("admin-panel.filters.sortAZdesc")
                            )
                          }
                        >
                          {t("admin-panel.filters.sortAZdesc")}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            performFiltration(
                              WsOrderValues.createdDate,
                              WsOrderTypes.desc,
                              t("admin-panel.filters.dateNewest")
                            )
                          }
                        >
                          {t("admin-panel.filters.dateNewest")}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            performFiltration(
                              WsOrderValues.createdDate,
                              WsOrderTypes.asc,
                              t("admin-panel.filters.dateOldest")
                            )
                          }
                        >
                          {t("admin-panel.filters.dateOldest")}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            performFiltration(
                              WsOrderValues.activeStatus,
                              WsOrderTypes.asc,
                              t("admin-panel.filters.deactivated")
                            )
                          }
                        >
                          {t("admin-panel.filters.deactivated")}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            performFiltration(
                              WsOrderValues.activeStatus,
                              WsOrderTypes.desc,
                              t("admin-panel.filters.activated")
                            )
                          }
                        >
                          {t("admin-panel.filters.activated")}
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    {selectedFilterName && (
                      <Button
                        color="secondary"
                        className="mb-1 mr-2 mb-lg-0"
                        onClick={resetFiltration}
                      >
                        {t("admin-panel.filters.reset")}
                      </Button>
                    )}
                  </div>
                </div>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">{t("admin-panel.name")}</th>
                    <th scope="col">{t("admin-panel.email")}</th>
                    <th scope="col">{t("admin-panel.wallet")}</th>
                    <th scope="col">{t("admin-panel.status")}</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {data.map(admin => (
                    <tr key={admin.id}>
                      <td>{admin.name}</td>
                      <td>{admin.email}</td>
                      <td onClick={() => copyWalletToClipboard(admin.walletAddress)}>
                        {admin.walletAddress}
                      </td>
                      <td>
                        {admin.isActive ? (
                          <Badge color="" className="badge-dot mr-4">
                            <i className="bg-success" />
                            {t("admins-table.active")}
                          </Badge>
                        ) : (
                          <Badge color="" className="badge-dot mr-4">
                            <i className="bg-danger" />
                            {t("admins-table.inactive")}
                          </Badge>
                        )}
                      </td>
                      <td className="text-right">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            role="button"
                            size="sm"
                            color=""
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right container="body">
                            <DropdownItem onClick={() => openEditAdminPopup(admin.id)}>
                              {t("admin-panel.edit")}
                            </DropdownItem>
                            <DropdownItem
                              onClick={
                                admin.isActive
                                  ? () => openDeactivateAdminPopup(admin.id)
                                  : () => openActivateAdminPopup(admin.id)
                              }
                            >
                              {admin.isActive
                                ? t("admins-table.deactivate")
                                : t("admins-table.activate")}
                            </DropdownItem>
                            <DropdownItem onClick={() => openDeleteAdminPopup(admin.id)}>
                              {t("admin-panel.delete")}
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel={<i className="fas fa-angle-right" />}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={0}
                    pageCount={pageCount}
                    previousLabel={<i className="fas fa-angle-left" />}
                    className="pagination justify-content-end mb-0"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    activeClassName="active"
                    disabledClassName="disabled"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    forcePage={tableRequest.page - 1}
                  />
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>

      {/* POPUPS */}
      <AddAdminPopup newAdminPopup={newAdminPopup} setNewAdminPopup={setNewAdminPopup} />
      <EditAdminPopup
        editAdminPopup={editAdminPopup}
        setEditAdminPopup={setEditAdminPopup}
        selectedAdminName={selectedAdminName}
        selectedAdminEmail={selectedAdminEmail}
        selectedAdmin={selectedAdmin}
      />
      <ConfirmationPopup
        title={t("confirmation-popup.title.activate")}
        text={t("confirmation-popup.text.activate")}
        popupState={activateAdminPopup}
        handlePopup={setActivateAdminPopup}
        submitAction={activateAdmin}
        btnText={t("confirmation-popup.btn-text.activate")}
        btnColor="success"
        isFormSubmitting={isFormSubmitting}
      />
      <ConfirmationPopup
        title={t("confirmation-popup.title.deactivate")}
        text={t("confirmation-popup.text.deactivate")}
        popupState={deactivateAdminPopup}
        handlePopup={setDeactivateAdminPopup}
        submitAction={deactivateAdmin}
        btnText={t("confirmation-popup.btn-text.deactivate")}
        btnColor="primary"
        isFormSubmitting={isFormSubmitting}
      />
      <ConfirmationPopup
        title={t("confirmation-popup.title.delete")}
        text={t("confirmation-popup.text.delete")}
        popupState={deleteAdminPopup}
        handlePopup={setDeleteAdminPopup}
        submitAction={deleteAdmin}
        btnText={t("confirmation-popup.btn-text.delete")}
        btnColor="danger"
        isFormSubmitting={isFormSubmitting}
      />
    </>
  );
};

export default AdminsTable;
