import { useFormik } from "formik";
import { useState } from "react";
import { toast } from "react-toastify";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import * as Yup from "yup";

import "./contactForm.scss";

import { contactService } from "../../../services/contact.service";
import { useTranslation } from "react-i18next";

const contactFormValidationSchema = Yup.object({
  name: Yup.string()
    .required("Name is required")
    .min(2, "Minimum string length is 2 characters")
    .max(64, "Maximum string length is 64 characters"),
  institute: Yup.string().required("Institute is required"),
  email: Yup.string().required("Email is required").email("Please enter a valid email address"),
  comment: Yup.string(),
});

export function ContactForm() {
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const { t } = useTranslation();

  const formikContact = useFormik({
    initialValues: {
      name: "",
      institute: "",
      email: "",
      comment: "",
    },
    validationSchema: contactFormValidationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values, { resetForm }) => {
      setIsFormSubmitting(true);
      const toastLoader = toast.loading(t("loading.sending"));

      const response = await contactService.sendContactFormData(values);

      if (response && response.error) {
        toast.update(toastLoader, {
          render: response.error,
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
        setIsFormSubmitting(false);
        return;
      }

      toast.update(toastLoader, {
        render: t("mainPageContact.success"),
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });

      setIsFormSubmitting(false);
      resetForm();
    },
  });

  return (
    <Form className="contact-form" onSubmit={formikContact.handleSubmit}>
      <h2
        className="contact-form__title"
        dangerouslySetInnerHTML={{ __html: t("mainPageContact.readyTo") }}
      />

      <p className="contact-form__text">{t("mainPageContact.begin")}</p>

      <div className="contact-form__row">
        <div className="contact-form__col">
          <Label for="name" className="contact-form__label">
            {t("mainPageContact.name")}
          </Label>
          <Input
            id="name"
            placeholder={t("mainPageContact.name")}
            className="contact-form__input"
            {...formikContact.getFieldProps("name")}
          />
          {formikContact.touched.name && formikContact.errors.name && (
            <small className="text-danger mt-1">{formikContact.errors.name}</small>
          )}
        </div>
        <div className="contact-form__col">
          <Label for="institute" className="contact-form__label">
            {t("mainPageContact.institutionName")}
          </Label>
          <Input
            id="institute"
            placeholder={t("mainPageContact.institutionName")}
            className="contact-form__input"
            {...formikContact.getFieldProps("institute")}
          />
          {formikContact.touched.institute && formikContact.errors.institute && (
            <small className="text-danger mt-1">{formikContact.errors.institute}</small>
          )}
        </div>
        <div className="contact-form__col">
          <Label for="email" className="contact-form__label">
            Email
          </Label>
          <Input
            id="email"
            placeholder="Email"
            type="email"
            className="contact-form__input"
            {...formikContact.getFieldProps("email")}
          />
          {formikContact.touched.email && formikContact.errors.email && (
            <small className="text-danger mt-1">{formikContact.errors.email}</small>
          )}
        </div>
      </div>

      <FormGroup>
        <Label for="comment" className="contact-form__label">
          {t("mainPageContact.message")}
        </Label>
        <Input
          id="comment"
          type="textarea"
          placeholder={t("mainPageContact.message")}
          {...formikContact.getFieldProps("comment")}
        />
        {formikContact.touched.comment && formikContact.errors.comment && (
          <small className="text-danger mt-1">{formikContact.errors.comment}</small>
        )}
      </FormGroup>

      <Button
        type="submit"
        color="primary"
        className="contact-form__submit-button"
        disabled={isFormSubmitting}
      >
        {t("submit.send")}
      </Button>
    </Form>
  );
}
