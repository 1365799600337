import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Table,
  Container,
  Row,
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  Badge,
} from "reactstrap";
import AdminHeader from "../../components/AdminComponents/AdminHeader";
import AdminStatCards from "../../components/AdminComponents/AdminStatCards";
import { ConfirmationPopup } from "../../components/AdminComponents/ConfirmationPopup";
import { toast } from "react-toastify";
import {
  WsFilterTypes,
  WsOrderTypes,
  WsOrderValues,
  WsResponseAction,
  WsTable,
} from "../../app/models/ws.interface";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks/hooks";
import {
  fetchOverallStats,
  setCreatedCertificateId,
  setRequestedEmployee,
  setRequstedInstitute,
  setSearchPlaceholder,
  setSearchQuery,
} from "../../app/state/adminPanelSlice";
import { UserRole } from "../../app/models/role.interface";
import { institutesService } from "../../services/institutes.service";
import { AddCertificatePopup } from "../../components/AdminComponents/AddCertificatePopup";
import { certificatesService } from "../../services/certificates.service";
import { ICertificate, IInstituteCheckbox } from "../../app/models/adminPanel.interface";
import { saveAs } from "file-saver";
import EditCertificatePopup from "../../components/AdminComponents/EditCertificatePopup";
import { useAdminWebSocket } from "../../app/hooks/useAdminWebSocket";
import { useTranslation } from "react-i18next";

const CertificatesTable = () => {
  const dispatch = useAppDispatch();
  const {
    id: currentUserId,
    name: currentUserName,
    role: currentUserRole,
  } = useAppSelector(state => state.user);
  const { searchQuery, requestedInstitute, requestedEmployee, createdCertificateId } =
    useAppSelector(state => state.adminPanel);
  const navigate = useNavigate();

  const [selectedCertificate, setSelectedCertificate] = useState<ICertificate>(null);
  const [newCertificatePopup, setNewCertificatePopup] = useState(false);
  const [editCertificatePopup, setEditCertificatePopup] = useState(false);
  const [deleteCertificatePopup, setDeleteCertificatePopup] = useState(false);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [activeId, setActiveId] = useState(null);
  const [isSorting, setIsSorting] = useState(false);

  const [selectedFilterName, setSelectedFilterName] = useState(null);
  const { t } = useTranslation();

  const [allInstitutes, setAllInstitutes] = useState<IInstituteCheckbox[]>([]);
  const [displayBurned, setDisplayBurned] = useState(true);
  const [showQr, setShowQr] = useState(false);
  const [qrCode, setQrCode] = useState(null);

  const { setTableRequest, setFilters, pageCount, filters, tableRequest, data } =
    useAdminWebSocket<ICertificate>(WsTable.certificates, WsResponseAction.certificatesTable);

  // Handlers
  const handlePageClick = (event: any) => {
    setTableRequest(prevState => ({ ...prevState, page: event.selected + 1 }));
  };

  const performFiltration = (
    orderBy: WsOrderValues,
    orderType: WsOrderTypes,
    filterName: string
  ) => {
    setTableRequest(prevState => ({ ...prevState, page: 1, orderBy, orderType }));
    setSelectedFilterName(filterName);
    setIsSorting(true);
  };

  const resetFiltration = () => {
    setTableRequest(prevState => ({
      ...prevState,
      page: 1,
      orderBy: WsOrderValues.createdDate,
      orderType: WsOrderTypes.desc,
    }));
    setSelectedFilterName(null);
    const uncheckedInstitutes = [...allInstitutes];
    for (let i of uncheckedInstitutes) {
      i.checked = false;
    }
    setAllInstitutes(uncheckedInstitutes);
    setIsSorting(false);
  };

  const openDeleteCertificatePopup = (diploma: ICertificate) => {
    setDeleteCertificatePopup(true);
    setSelectedCertificate(diploma);
  };

  const deleteCertificate = async () => {
    // console.log(selectedCertificateId);
    setIsFormSubmitting(true);
    await certificatesService.deleteCertificate(selectedCertificate.id);
    toast.success(t("certificates-table.certificate-deleted"));
    setDeleteCertificatePopup(false);
    dispatch(fetchOverallStats());
    setIsFormSubmitting(false);
  };

  const handleInstituteCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = event.target;
    const index = allInstitutes.findIndex(i => i.id === +id);
    const newArray = [...allInstitutes];
    newArray[index].checked = checked;
    setAllInstitutes(newArray);
    setIsSorting(true);

    if (requestedInstitute !== "" && requestedInstitute.toString() === id) {
      dispatch(setRequstedInstitute(""));
    }
  };

  const formatDate = (isoDate: string) => {
    const parts = isoDate.slice(0, -8).split("T");
    return `${parts[0]} ${parts[1]}`;
  };

  const openQrPopup = (diploma: ICertificate) => {
    setSelectedCertificate(diploma);
    setShowQr(true);
  };

  const closeQrPopup = () => {
    setSelectedCertificate(null);
    dispatch(setCreatedCertificateId(null));
    setShowQr(false);
  };

  const copyIdToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(() => toast.success(t("certificates-table.copied")));
  };

  const getGraduationYear = (stringDate: string) => {
    return stringDate.slice(0, 4);
  };

  const downloadQr = (diploma: ICertificate) => {
    saveAs(
      qrCode,
      `${diploma.owner}_${diploma.institute.name}_${diploma.career.name}_${getGraduationYear(
        diploma.graduationDate
      )}.jpg`
    );
  };

  // Search
  useEffect(() => {
    const fIndex = filters.findIndex(f => f.filterType === WsFilterTypes.byDefault);
    const newFilter = [...filters];
    newFilter[fIndex].filterVariable = searchQuery;
    setFilters(newFilter);
  }, [searchQuery]);

  useEffect(() => {
    dispatch(setSearchPlaceholder(t("certificates-table.search-placeholder")));

    return () => {
      dispatch(setSearchQuery(""));
      dispatch(setRequstedInstitute(""));
      dispatch(setRequestedEmployee(""));
    };
  }, [dispatch]);

  // Get requsted certificates from institutions or employees table
  useEffect(() => {
    const newFilter = [...filters];
    if (requestedInstitute !== "") {
      newFilter.push({
        filterType: WsFilterTypes.byIntitutes,
        filterVariable: requestedInstitute.toString(),
      });
    } else if (requestedEmployee !== "") {
      newFilter.push({
        filterType: WsFilterTypes.byEmployees,
        filterVariable: requestedEmployee.toString(),
      });
    }
    setFilters(newFilter);
  }, [requestedEmployee, requestedInstitute]);

  // Get institutes list for dropdown
  useEffect(() => {
    const getAllInstitutes = async () => {
      const resp = await institutesService.getAvailableInstitutes();
      if (!resp.error) {
        // add checked false to all entries
        const instWithChecks = resp.map(o => {
          return { ...o, checked: false };
        });
        if (requestedInstitute) {
          const idx = instWithChecks.findIndex(i => i.id === +requestedInstitute);
          instWithChecks[idx].checked = true;
        }
        setAllInstitutes(instWithChecks);
      }
    };
    if (currentUserRole <= UserRole.admin) {
      getAllInstitutes();
    } else {
      const currentInstitute = { id: currentUserId, name: currentUserName, checked: false };
      setAllInstitutes([currentInstitute]);
    }
  }, [currentUserId, currentUserName, currentUserRole, requestedInstitute]);

  // Perform institution checkbox filtration
  useEffect(() => {
    if (allInstitutes.length === 0) {
      return;
    }
    const selectedCheckboxes = [];
    for (let a of allInstitutes) {
      if (a.checked) {
        selectedCheckboxes.push(a.id);
      } else {
        selectedCheckboxes.splice(a.id, 1);
      }
    }

    const fIndex = filters.findIndex(f => f.filterType === WsFilterTypes.byIntitutes);
    const newFilter = [...filters];

    if (selectedCheckboxes.length > 0) {
      if (fIndex > -1) {
        newFilter[fIndex].filterVariable = selectedCheckboxes.join();
      } else {
        newFilter.push({
          filterType: WsFilterTypes.byIntitutes,
          filterVariable: selectedCheckboxes.join(),
        });
      }
    } else {
      if (fIndex > -1) {
        newFilter.splice(fIndex, 1);
      }
    }
    setFilters(newFilter);
  }, [allInstitutes]);

  // Filter by burned diplomas
  useEffect(() => {
    const fIndex = filters.findIndex(f => f.filterType === WsFilterTypes.getBurned);
    const newFilter = [...filters];

    if (fIndex !== -1) {
      newFilter[fIndex].filterVariable = displayBurned.toString();
    } else {
      newFilter.push({
        filterType: WsFilterTypes.getBurned,
        filterVariable: displayBurned.toString(),
      });
    }
    setFilters(newFilter);
  }, [displayBurned]);

  // Observe filter change
  useEffect(() => {
    setTableRequest(prevState => ({ ...prevState, filters }));
  }, [filters]);

  // Display diploma QR
  useEffect(() => {
    const getCertificateQrCode = async (id: string) => {
      const resp = await certificatesService.getCertificateQr(id);
      if (resp.qrCode) {
        setQrCode(resp.qrCode);
      } else {
        toast.error(t("certificates-table.qr-error"));
      }
    };
    if (selectedCertificate && showQr) {
      getCertificateQrCode(selectedCertificate.id);
    }
  }, [selectedCertificate, showQr]);

  // Shop QR popup after creating the certificate
  useEffect(() => {
    if (createdCertificateId) {
      const findedIndex = data.findIndex(c => c.id === createdCertificateId);
      const newCreatedCertificate = data[findedIndex];
      openQrPopup(newCreatedCertificate);
    }
  }, [createdCertificateId, data]);

  return (
    <>
      <AdminHeader />

      <AdminStatCards />

      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="d-flex flex-column justify-content-between flex-md-row">
                  <h3 className="mb-1 mb-md-0">{t("certificates-table.title")}</h3>
                  <div className="row justify-content-end">
                    <Button
                      color="primary"
                      className="mb-1 mb-lg-0"
                      onClick={() => setNewCertificatePopup(true)}
                    >
                      {t("certificates-table.add")}
                    </Button>

                    {/* FILTER by institution */}
                    {currentUserRole <= UserRole.admin && (
                      <UncontrolledDropdown>
                        <DropdownToggle role="button" color="primary" outline caret>
                          {t("admin-panel.filters.institution")}
                        </DropdownToggle>
                        <DropdownMenu
                          className="dropdown-menu-arrow"
                          right
                          style={{ maxHeight: 500, overflowY: "auto" }}
                        >
                          {allInstitutes.length > 0 &&
                            allInstitutes.map(i => (
                              <DropdownItem key={i.id} toggle={false}>
                                <FormGroup check inline className="w-100">
                                  <Input
                                    type="checkbox"
                                    id={i.id.toString()}
                                    name={i.name}
                                    value={i.name}
                                    onChange={e => handleInstituteCheckbox(e)}
                                    defaultChecked={i.checked}
                                  />
                                  <Label
                                    check
                                    for={i.id.toString()}
                                    className="w-100"
                                    role="button"
                                  >
                                    {i.name}
                                  </Label>
                                </FormGroup>
                              </DropdownItem>
                            ))}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    )}

                    {/* BURNED DIPLOMAS CHECKBOX */}
                    <FormGroup check inline>
                      <Input
                        type="checkbox"
                        id="burned"
                        name="burned"
                        onChange={() => setDisplayBurned(!displayBurned)}
                        checked={displayBurned}
                      />
                      <Label check for="burned" role="button">
                        {t("certificates-table.burned-diplomas")}
                      </Label>
                    </FormGroup>

                    {/* FILTER */}
                    <UncontrolledDropdown>
                      <DropdownToggle
                        role="button"
                        color="primary"
                        outline
                        caret
                        style={{
                          maxWidth: 315,
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {selectedFilterName ? selectedFilterName : t("admin-panel.filters.base")}
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-arrow" right>
                        <DropdownItem
                          onClick={() =>
                            performFiltration(
                              WsOrderValues.diplomaOwnerName,
                              WsOrderTypes.asc,
                              t("certificates-table.filter.byUsernameASC")
                            )
                          }
                        >
                          {t("certificates-table.filter.byUsernameASC")}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            performFiltration(
                              WsOrderValues.diplomaOwnerName,
                              WsOrderTypes.desc,
                              t("certificates-table.filter.byUsernameDESC")
                            )
                          }
                        >
                          {t("certificates-table.filter.byUsernameDESC")}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            performFiltration(
                              WsOrderValues.createdDate,
                              WsOrderTypes.desc,
                              t("admin-panel.filters.dateNewest")
                            )
                          }
                        >
                          {t("admin-panel.filters.dateNewest")}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            performFiltration(
                              WsOrderValues.createdDate,
                              WsOrderTypes.asc,
                              t("admin-panel.filters.dateOldest")
                            )
                          }
                        >
                          {t("admin-panel.filters.dateOldest")}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            performFiltration(
                              WsOrderValues.diplomaNumber,
                              WsOrderTypes.asc,
                              t("certificates-table.filter.byPhysicalNumberASC")
                            )
                          }
                        >
                          Sort by physical diploma number (ascending)
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            performFiltration(
                              WsOrderValues.diplomaNumber,
                              WsOrderTypes.desc,
                              t("certificates-table.filter.byPhysicalNumberDESC")
                            )
                          }
                        >
                          {t("certificates-table.filter.byPhysicalNumberDESC")}
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    {isSorting && (
                      <Button
                        color="secondary"
                        className="mb-1 mr-2 mb-lg-0"
                        onClick={resetFiltration}
                      >
                        {t("admin-panel.filters.reset")}
                      </Button>
                    )}
                  </div>
                </div>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">{t("admin-panel.institution")}</th>
                    <th scope="col">{t("admins-table.student-receiver")}</th>
                    <th scope="col">{t("admins-table.career")}</th>
                    <th scope="col">{t("admins-table.certificateId")}</th>
                    <th scope="col">{t("admins-table.physical-number")}</th>
                    <th scope="col">{t("admins-table.date-issue")}</th>
                    <th scope="col">{t("admins-table.issuer")}</th>
                    <th scope="col">{t("admins-table.status")}</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {data.map((diploma: ICertificate) => (
                    <tr key={diploma.id}>
                      <td>{diploma.institute.name}</td>
                      <td>{diploma.owner}</td>
                      <td>
                        {diploma.career.name} {getGraduationYear(diploma.graduationDate)}
                      </td>
                      <td onClick={() => copyIdToClipboard(diploma.id)}>{diploma.id}</td>
                      <td>{diploma.number}</td>
                      <td>{formatDate(diploma.createdAt)}</td>
                      <td>{diploma.creator.name}</td>
                      <td>
                        {diploma.isDeleted ? (
                          <Badge color="" className="badge-dot mr-4">
                            <i className="bg-danger" />
                            {t("certificates-table.burned")}
                          </Badge>
                        ) : (
                          <Badge color="" className="badge-dot mr-4">
                            <i className="bg-success" />
                            {t("admins-table.active")}
                          </Badge>
                        )}
                      </td>
                      <td className="text-right">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            role="button"
                            size="sm"
                            color=""
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right container="body">
                            {!diploma.isDeleted && (
                              <DropdownItem onClick={() => openDeleteCertificatePopup(diploma)}>
                                {t("admins-table.delete")}
                              </DropdownItem>
                            )}
                            <DropdownItem onClick={() => openQrPopup(diploma)}>
                              {t("admins-table.qr-code")}
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => {
                                window.location.href = `/diploma/${diploma.id}`;
                              }}
                            >
                              {t("certificates-table.view-diploma")}
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => {
                                setEditCertificatePopup(true);
                                setActiveId(diploma.id);
                              }}
                            >
                              {t("admins-table.edit")}
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel={<i className="fas fa-angle-right" />}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={0}
                    pageCount={pageCount}
                    previousLabel={<i className="fas fa-angle-left" />}
                    className="pagination justify-content-end mb-0"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    activeClassName="active"
                    disabledClassName="disabled"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    forcePage={tableRequest.page - 1}
                  />
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>

      {/* POPUPS */}
      <AddCertificatePopup
        newCertificatePopup={newCertificatePopup}
        setNewCertificatePopup={setNewCertificatePopup}
        allInstitutes={allInstitutes}
      />
      <ConfirmationPopup
        title={t("confirmation-popup.title.delete-certificate")}
        text={t("confirmation-popup.text.delete-certificate")}
        popupState={deleteCertificatePopup}
        handlePopup={setDeleteCertificatePopup}
        submitAction={deleteCertificate}
        btnText={t("confirmation-popup.btn-text.delete")}
        btnColor="danger"
        isFormSubmitting={isFormSubmitting}
      />
      <EditCertificatePopup
        newCertificatePopup={editCertificatePopup}
        setNewCertificatePopup={setEditCertificatePopup}
        id={activeId}
      />
      {/* QR MODAL */}
      <Modal isOpen={showQr} toggle={closeQrPopup} centered>
        <img src={qrCode} alt="qr" />
        <div className="modal-footer">
          <Button color="secondary" onClick={closeQrPopup}>
            {t("admin-panel.close")}
          </Button>
          <Button color="primary" onClick={() => downloadQr(selectedCertificate)}>
            {t("admin-panel.download")}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default CertificatesTable;
