import "./userDropdown.scss";

import classNames from "classnames";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../../app/hooks/hooks";
import { setLoggedOut } from "../../../app/state/userSlice";
import { authService } from "../../../services/auth.service";

import arrowDownWhiteIcon from "../../../assets/icons/student/arrow-down-white.svg";
import arrowRightBlackIcon from "../../../assets/icons/student/arrow-right-black.svg";
import arrowUpBlueIcon from "../../../assets/icons/student/arrow-up-blue.svg";
import logOutIcon from "../../../assets/icons/student/log-out.svg";
import userAvatarIcon from "../../../assets/icons/student/user-avatar.png";
import { useTranslation } from "react-i18next";

const dropdownLinks = [
  {
    title: "Settings",
    href: "/student/settings",
  },
  {
    title: "Passport",
    href: "/student",
  },
];

interface IUserDropdownProps {
  variant: "primary" | "secondary";
}

export const UserDropdown = (props: IUserDropdownProps) => {
  const { variant } = props;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userName = useAppSelector(state => state.user.name);

  const [isOpen, setIsOpen] = useState(false);
  const {t} = useTranslation()

  const handleToggleDropdown = () => {
    setIsOpen(prev => !prev);
  };

  const executeLogout = async () => {
    await authService.logout();
    dispatch(setLoggedOut());
    navigate("/");
  };

  return (
    <div className="user-dropdown">
      <button
        className={classNames("user-dropdown__button", isOpen && "active", `${variant}`)}
        onClick={handleToggleDropdown}
      >
        <img src={userAvatarIcon} alt="" className="user-dropdown__avatar" />
        <span className={classNames("user-dropdown__username", isOpen && "active", `${variant}`)}>
          {userName}
        </span>
        <img
          src={variant === "primary" || isOpen ? arrowUpBlueIcon : arrowDownWhiteIcon}
          alt=""
          className={classNames(
            "user-dropdown__button-arrow",
            variant === "primary" && !isOpen && "rotate"
          )}
        />
      </button>

      {isOpen ? (
        <div className="user-dropdown__content">
          {dropdownLinks.map(link => (
            <div className="user-dropdown__link-wrapper">
              <Link to={link.href} key={link.title} className="user-dropdown__link">
                {link.title}
                <div className="user-dropdown__link-arrow-wrapper">
                  <img src={arrowRightBlackIcon} alt="" />
                </div>
              </Link>
            </div>
          ))}
          <div className="user-dropdown__link-wrapper">
            <button className="user-dropdown__log-out-button" onClick={executeLogout}>
              {t('userDropdown.logout')}
              <img src={logOutIcon} alt="" className="user-dropdown__log-out-icon" />
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
};
