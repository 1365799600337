import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { employeesService } from "../../services/employees.service";
import { facultyService } from "../../services/faculty.service";
import { IFaculty } from "../../app/models/adminPanel.interface";
import { useTranslation } from "react-i18next";

const validationEditEmployee = Yup.object({
  editEmployeeName: Yup.string()
    .required("Enter the name of the Employee")
    .min(2, "Minimum string length is 2 characters")
    .max(64, "Maximum string length is 64 characters")
    .matches(
      /^[aA-zZ\s]+$/,
      "Entering special characters is prohibited. Please enter the correct name of the Employee"
    ),
  editEmployeeEmail: Yup.string()
    .required("Enter the email address")
    .email("Please enter a valid e-mail address."),
});

export const EditEmployeePopup = ({
  editEmployeePopup,
  setEditEmployeePopup,
  selectedEmployee,
}) => {
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [currentFaculty, setCurrentFaculty] = useState<IFaculty>(null);
  const [faculties, setFaculties] = useState<IFaculty[]>([]);
  const { t } = useTranslation();

  const formikEditEmployee = useFormik({
    initialValues: {
      editEmployeeName: selectedEmployee ? selectedEmployee.name : "",
      editEmployeeEmail: selectedEmployee ? selectedEmployee.email : "",
    },
    validationSchema: validationEditEmployee,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: async values => {
      const toastLoader = toast.loading(t("admin-loading.update-employee"));
      setIsFormSubmitting(true);

      let newData: any;
      if (values.editEmployeeName !== selectedEmployee.name) {
        newData = { name: values.editEmployeeName };
      }
      if (values.editEmployeeEmail !== selectedEmployee.email) {
        newData = { ...newData, email: values.editEmployeeEmail };
      }

      if (currentFaculty.name !== selectedEmployee.facultyName) {
        newData = { ...newData, facultyId: currentFaculty.id };
      }

      if (!newData) {
        toast.update(toastLoader, {
          render: t("edit-employee-popup.nothing-changed"),
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
        setIsFormSubmitting(false);
        return;
      }

      const resp = await employeesService.updateEmployee(selectedEmployee.id, newData);
      if (resp.error) {
        toast.update(toastLoader, {
          render: resp.error,
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
        setIsFormSubmitting(false);
        return;
      } else {
        toast.update(toastLoader, {
          render: t("edit-employee-popup.updated"),
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
        setIsFormSubmitting(false);
        closeAndClearPopup();
      }
    },
  });

  const closeAndClearPopup = () => {
    formikEditEmployee.values.editEmployeeName = "";
    formikEditEmployee.values.editEmployeeEmail = "";
    formikEditEmployee.touched.editEmployeeName = false;
    formikEditEmployee.touched.editEmployeeEmail = false;
    setEditEmployeePopup(false);
  };

  useEffect(() => {
    if (selectedEmployee && selectedEmployee.instituteId) {
      const getFaculties = async () => {
        try {
          const response = await facultyService.getFacultiesByInstituteId(
            selectedEmployee.instituteId
          );
          setFaculties(response);

          const defaultFaculty = response.find(item => item.name === selectedEmployee.facultyName);

          setCurrentFaculty(defaultFaculty);
        } catch (error) {
          toast.error(error.message);
          setEditEmployeePopup(false);
          return;
        }
      };

      getFaculties();
    }
  }, [selectedEmployee]);

  return (
    <Modal className="modal-dialog-centered" isOpen={editEmployeePopup} toggle={closeAndClearPopup}>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          {t("edit-employee-popup.title")}
        </h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={closeAndClearPopup}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <Form onSubmit={formikEditEmployee.handleSubmit}>
        <div className="modal-body">
          <p>{t("edit-employee-popup.description")}</p>
          <Input
            placeholder={t("edit-employee-popup.form.name")}
            type="text"
            className="pl-2"
            {...formikEditEmployee.getFieldProps("editEmployeeName")}
          />
          {formikEditEmployee.touched.editEmployeeName &&
            formikEditEmployee.errors.editEmployeeName && (
              <small className="text-danger mb-1">
                {formikEditEmployee.errors.editEmployeeName}
              </small>
            )}
          <Input
            placeholder={t("edit-employee-popup.form.email")}
            type="email"
            className="pl-2 mt-3"
            {...formikEditEmployee.getFieldProps("editEmployeeEmail")}
          />
          {formikEditEmployee.touched.editEmployeeEmail &&
            formikEditEmployee.errors.editEmployeeEmail && (
              <small className="text-danger mb-1">
                {formikEditEmployee.errors.editEmployeeEmail}
              </small>
            )}
          <UncontrolledDropdown className="mt-3 d-block">
            <DropdownToggle
              role="button"
              color="primary"
              outline
              caret
              style={{
                maxWidth: "100%",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {currentFaculty ? currentFaculty.name : t("edit-employee-popup.form.faculty")}
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow">
              {faculties.map(i => (
                <DropdownItem key={i.id} onClick={() => setCurrentFaculty(i)}>
                  {i.name}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
        <div className="modal-footer">
          <Button color="secondary" type="button" onClick={closeAndClearPopup}>
            {t("admin-panel.cancel")}
          </Button>
          <Button color="primary" type="submit" disabled={isFormSubmitting}>
            {t("admin-panel.save")}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};
