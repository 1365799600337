import "./loginForm.scss";

import { useFormik } from "formik";
import jwt_decode from "jwt-decode";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button, Form, FormGroup, Input, Label, Modal } from "reactstrap";
import * as Yup from "yup";

import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useNavigate } from "react-router-dom";
import { authService } from "../../../services/auth.service";
import { useTranslation } from "react-i18next";
import { IJwt } from "../../../constants";

const loginFormValidationSchema = Yup.object({
  email: Yup.string()
    .required("Please, enter e-mail address.")
    .email("Please enter a valid e-mail address."),
  password: Yup.string()
    .required("Please, enter password.")
    .min(6, "The password is too short. The minimum password length is 6 characters.")
    .max(15, "Password invalid."),
});

interface ILoginFormProps {
  onLogin: (isTwoFactorEnabled: boolean, isTwoFactorPassed: boolean) => void;
}

export function LoginForm({ onLogin }: ILoginFormProps) {
  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [forgotPassModal, setForgotPassModal] = useState(false);
  const [resetEmail, setResetEmail] = useState("");
  const [isResetEmailValid, setIsResetEmailValid] = useState(false);
  const [resetError, setResetError] = useState("");
  const [authError, setAuthError] = useState("");
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const { t, i18n } = useTranslation();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginFormValidationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: values => {
      executeLogin(values.email, values.password);
    },
  });

  const toggleForgotPassModal = () => {
    setForgotPassModal(!forgotPassModal);
    setResetError("");
  };

  const validateEmail = (value: string) => {
    let matchMask = value.match(/[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]/);
    let hasAt = value.includes("@");
    let notEmpty = value.length > 0;

    if (!matchMask) {
      return t("login.validation.email");
    }
    if (!hasAt) {
      return t("login.validation.hasEmail");
    }
    if (!notEmpty) {
      return t("login.validation.notEmpty");
    }
    return "";
  };

  // LOGIN
  const executeLogin = async (email: string, password: string) => {
    setIsFormSubmitting(true);

    const token = await executeRecaptcha("login");
    const resp = await authService.login(email, password, token);

    if (resp.error && resp.error[0].includes("captcha")) {
      setAuthError(t("captcha.incorrect"));
      setIsFormSubmitting(false);
      return;
    }
    if (resp.status === 403) {
      navigate("/auth/deactivated");
      return;
    }
    if (resp.error) {
      setAuthError(resp.error);
      setIsFormSubmitting(false);
      return;
    }
    if (!resp.data) {
      setAuthError(t("server.error"));
      setIsFormSubmitting(false);
      return;
    }

    const decodedData: IJwt = jwt_decode<any>(resp.data.accessToken);

    i18n.changeLanguage(decodedData.preferredLanguage);
    const { isTwoFactorEnabled, isTwoFactorPassed } = decodedData;

    onLogin(isTwoFactorEnabled, isTwoFactorPassed);
  };

  // VALIDATE RESET PASSWORD
  useEffect(() => {
    if (resetEmail.length > 0) {
      let error = validateEmail(resetEmail);
      setIsResetEmailValid(!error);
      setResetError(error);
    }
  }, [resetEmail]);

  // CHECK TOKENS
  // useEffect(() => {
  //   async function refresh() {
  //     const resp = await authService.refreshTokens();
  //     if (resp.status === 403) {
  //       navigate("/auth/deactivated");
  //     }
  //     if (!resp.error) {
  //       dispatch(setLoggedIn());
  //       dispatch(setUserData());
  //     }
  //   }
  //
  //   const tokens = localStorage.getItem("tokens");
  //   if (tokens) {
  //     refresh();
  //   }
  // }, [navigate]);

  const executeResetPassword = async () => {
    setIsFormSubmitting(true);
    const resp = await authService.resetPassword(resetEmail);
    if (resp.error) {
      toast.error(resp.error);
      setIsFormSubmitting(false);
      return;
    }
    toast.success(t("login.success"));
    setForgotPassModal(false);
    setIsFormSubmitting(false);
  };

  // Cleaning auth error on change fields value
  useEffect(() => {
    if (authError.length > 0) {
      setAuthError("");
    }
  }, [formik.values.email, formik.values.password]);

  return (
    <>
      <Form className="login-form" onSubmit={formik.handleSubmit}>
        <FormGroup className="login-form__form-group">
          <Label for="email">E-mail</Label>
          <Input
            id="email"
            type="email"
            placeholder="example@email.com"
            className="login-form__input"
            autoComplete="off"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email && (
            <small className="text-danger mt-1 login-form__error">{formik.errors.email}</small>
          )}
        </FormGroup>

        <FormGroup className="login-form__form-group">
          <Label for="password">{t("password.basic")}</Label>
          <Input
            id="password"
            type="password"
            placeholder={t("password.your")}
            className="login-form__input"
            autoComplete="off"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password && (
            <small className="text-danger mt-1 login-form__error">{formik.errors.password}</small>
          )}
        </FormGroup>

        <button
          type="button"
          className="login-form__forgot-password-button"
          onClick={toggleForgotPassModal}
        >
          {t("password.forgot")}
        </button>

        {authError && <small className="text-danger mb-1 login-form__error">{authError}</small>}
        <Button
          type="submit"
          color="primary"
          disabled={isFormSubmitting}
          className="login-form__submit-button"
        >
          {t("login.login")}
        </Button>
      </Form>

      <Modal
        className="modal-dialog-centered"
        isOpen={forgotPassModal}
        toggle={toggleForgotPassModal}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            {t("password.forgotTitle")}
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggleForgotPassModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <p className="mb-2">{t("login.associatedEmail")}</p>
          <FormGroup className="login-form__form-group">
            <Input
              name="resetEmail"
              type="email"
              placeholder="example@email.com"
              className="auth-page__input"
              value={resetEmail}
              onChange={e => setResetEmail(e.target.value)}
            />
            {formik.touched.email && formik.errors.email && (
              <small className="text-danger mt-1 login-form__error">{resetError}</small>
            )}
          </FormGroup>
        </div>
        <div className="modal-footer">
          <Button
            color="primary"
            type="button"
            disabled={isResetEmailValid === false || isFormSubmitting}
            onClick={executeResetPassword}
          >
            {t("submit.basic")}
          </Button>
        </div>
      </Modal>
    </>
  );
}
