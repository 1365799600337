import { useFormik } from "formik";
import { useState } from "react";
import { toast } from "react-toastify";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import * as Yup from "yup";
import { studentService } from "../../../../services/student.service";
import { useTranslation } from "react-i18next";

const transferModalTwoFactorFormValidationSchema = Yup.object({
  twoFactorToken: Yup.string()
    .required("Please enter a token")
    .matches(/[0-9]+/, "Token includes numbers only")
    .length(6, "Token must be 6 numbers long."),
});

interface ITransferModalTwoFactorFormProps {
  certificateId: string;
  email: string;
  onTransfer: () => Promise<void>;
}

export const TransferModalTwoFactorForm = (props: ITransferModalTwoFactorFormProps) => {
  const { certificateId, email, onTransfer } = props;
  const {t} = useTranslation()

  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const formik = useFormik({
    initialValues: {
      twoFactorToken: "",
    },
    validationSchema: transferModalTwoFactorFormValidationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async ({ twoFactorToken }, { resetForm }) => {
      setIsFormSubmitting(true);
      const toastLoader = toast.loading(t("loading.transferring"));

      const response = await studentService.transferDocument(certificateId, email, twoFactorToken);

      if (response && response.error) {
        toast.update(toastLoader, {
          render: response.error,
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
        setIsFormSubmitting(false);
        return;
      }

      toast.update(toastLoader, {
        render: t("transferModalTwoFactor.success"),
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });

      await onTransfer()
      resetForm();
    },
  });

  //   let timeout: any = null;

  //   const handleEmailChange = () => {
  //     if (timeout) {
  //       clearTimeout(timeout);
  //       setIsFormSubmitting(true);
  //       setIsFormDataVerified(false);
  //     }
  //     if (formik.errors.email.length) {
  //       return;
  //     }

  //     timeout = setTimeout(async () => {
  //       const response = await studentService.checkEmail(formik.values.email);

  //       if (response && response.error) {
  //         toast.error(response.error, {
  //           autoClose: 3000,
  //         });
  //         setIsFormSubmitting(false);
  //         return;
  //       }

  //       toast.success("Your document was send", {
  //         autoClose: 3000,
  //       });
  //       setIsFormDataVerified(true);
  //     }, 3000);
  //   };

  return (
    <Form className="transfer-document-form" onSubmit={formik.handleSubmit}>
      <h3 className="transfer-document-confirm__text">{t('transferModalTwoFactor.title')}</h3>
      <FormGroup>
        <Label for="twoFactorToken" className="transfer-document-form__label">
          {t('transferModalTwoFactor.enter2fa')}
        </Label>
        <Input id="twoFactorToken" type="text" {...formik.getFieldProps("twoFactorToken")} />
        {formik.touched.twoFactorToken && formik.errors.twoFactorToken && (
          <small className="text-danger mt-1">{formik.errors.twoFactorToken}</small>
        )}
      </FormGroup>

      <Button
        type="submit"
        color="primary"
        className="transfer-document-form__submit-button"
        disabled={isFormSubmitting}
      >
        {t('submit.confirm')}
      </Button>
    </Form>
  );
};
