import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
} from "reactstrap";
import AdminHeader from "../../components/AdminComponents/AdminHeader";
import AdminStatCards from "../../components/AdminComponents/AdminStatCards";

import {
  WsFilterTypes,
  WsOrderTypes,
  WsOrderValues,
  WsResponseAction,
  WsTable,
} from "../../app/models/ws.interface";
import ReactPaginate from "react-paginate";
import { useAppDispatch, useAppSelector } from "../../app/hooks/hooks";
import {
  fetchOverallStats,
  setRequestedEmployee,
  setRequstedInstitute,
  setSearchPlaceholder,
  setSearchQuery,
} from "../../app/state/adminPanelSlice";
import { IFaculty } from "../../app/models/adminPanel.interface";
import { formatDate } from "../../utils/formatDate";
import AddFacultyPopup from "../../components/AdminComponents/AddFacultyPopup";
import EditFacultyPopup from "../../components/AdminComponents/EditFacultyPopup";
import { ConfirmationPopup } from "../../components/AdminComponents/ConfirmationPopup";
import { toast } from "react-toastify";
import { facultyService } from "../../services/faculty.service";
import { useAdminWebSocket } from "../../app/hooks/useAdminWebSocket";
import { useTranslation } from "react-i18next";
import { UserRole } from "../../app/models/role.interface";
import { institutesService } from "../../services/institutes.service";

const FacultiesTable = () => {
  const dispatch = useAppDispatch();
  const {
    id: currentUserId,
    name: currentUserName,
    role: currentUserRole,
  } = useAppSelector(state => state.user);
  const { searchQuery } = useAppSelector(state => state.adminPanel);

  const [newFacultyPopup, setNewFacultyPopup] = useState(false);
  const [editFacultyPopup, setEditFacultyPopup] = useState(false);
  const [selectedFaculty, setSelectedFaculty] = useState<number>(null);
  const [deleteFacultyPopup, setDeleteFacultyPopup] = useState(false);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [allInstitutes, setAllInstitutes] = useState(null);

  const { t } = useTranslation();

  const [selectedFilterName, setSelectedFilterName] = useState(null);

  const { setTableRequest, setFilters, pageCount, filters, tableRequest, data } =
    useAdminWebSocket<IFaculty>(WsTable.faculties, WsResponseAction.facultiesTable);

  // Handlers
  const handlePageClick = (event: any) => {
    setTableRequest(prevState => ({ ...prevState, page: event.selected + 1 }));
  };

  const performFiltration = (
    orderBy: WsOrderValues,
    orderType: WsOrderTypes,
    filterName: string
  ) => {
    setTableRequest(prevState => ({ ...prevState, page: 1, orderBy, orderType }));
    setSelectedFilterName(filterName);
  };

  const resetFiltration = () => {
    setTableRequest(prevState => ({
      ...prevState,
      page: 1,
      orderBy: WsOrderValues.createdDate,
      orderType: WsOrderTypes.desc,
    }));
    setSelectedFilterName(null);
  };

  const toggleAddFacultyPopup = () => {
    setNewFacultyPopup(prevState => !prevState);
  };

  const toggleEditFacultyPopup = (id?: number) => {
    setEditFacultyPopup(prevState => !prevState);

    if (id) {
      setSelectedFaculty(id);
    }
  };

  const openDeleteFacultyPopup = (id?: number) => {
    setDeleteFacultyPopup(prevState => !prevState);

    if (id) {
      setSelectedFaculty(id);
    }
  };

  const deleteFaculty = async () => {
    setIsFormSubmitting(true);
    await facultyService.deleteFaculty(selectedFaculty);
    toast.success(t("faculties-table.faculty-deleted"));
    setDeleteFacultyPopup(false);
    dispatch(fetchOverallStats());
    setIsFormSubmitting(false);
  };

  // Search
  useEffect(() => {
    const fIndex = filters.findIndex(f => f.filterType === WsFilterTypes.byDefault);
    const newFilter = [...filters];
    newFilter[fIndex].filterVariable = searchQuery;
    setFilters(newFilter);
  }, [searchQuery]);

  useEffect(() => {
    dispatch(setSearchPlaceholder(t("faculties-table.search-placeholder")));
    return () => {
      dispatch(setSearchQuery(""));
      dispatch(setRequstedInstitute(""));
      dispatch(setRequestedEmployee(""));
    };
  }, [dispatch]);

  // Observe filter change
  useEffect(() => {
    setTableRequest(prevState => ({ ...prevState, filters }));
  }, [filters]);

  useEffect(() => {
    const getAllInstitutes = async () => {
      const resp = await institutesService.getAvailableInstitutes();
      if (!resp.error) {
        setAllInstitutes(resp);
      }
    };

    if (currentUserRole < UserRole.insitute) {
      getAllInstitutes();
    } else {
      const currentInstitute = { id: currentUserId, name: currentUserName, checked: false };
      setAllInstitutes([currentInstitute]);
    }
  }, [currentUserId, currentUserName, currentUserRole]);

  return (
    <>
      <AdminHeader />

      <AdminStatCards />

      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="d-flex flex-column justify-content-between flex-md-row">
                  <h3 className="mb-1 mb-md-0">{t("faculties-table.title")}</h3>
                  <div className="row justify-content-end">
                    {currentUserRole <= UserRole.insitute && (
                      <Button
                        color="primary"
                        className="mb-1 mb-lg-0"
                        onClick={() => setNewFacultyPopup(true)}
                      >
                        {t("faculties-table.add")}
                      </Button>
                    )}

                    {/* FILTER */}
                    <UncontrolledDropdown>
                      <DropdownToggle
                        role="button"
                        color="primary"
                        outline
                        caret
                        style={{
                          maxWidth: 315,
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {selectedFilterName ? selectedFilterName : t("admin-panel.filters.base")}
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-arrow" right>
                        <DropdownItem
                          onClick={() =>
                            performFiltration(
                              WsOrderValues.instituteId,
                              WsOrderTypes.asc,
                              t("admin-panel.filters.sortAZasc")
                            )
                          }
                        >
                          {t("admin-panel.filters.sortAZasc")}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            performFiltration(
                              WsOrderValues.instituteId,
                              WsOrderTypes.desc,
                              t("admin-panel.filters.sortAZdesc")
                            )
                          }
                        >
                          {t("admin-panel.filters.sortAZdesc")}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            performFiltration(
                              WsOrderValues.createdDate,
                              WsOrderTypes.desc,
                              t("admin-panel.filters.dateNewest")
                            )
                          }
                        >
                          {t("admin-panel.filters.dateNewest")}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            performFiltration(
                              WsOrderValues.createdDate,
                              WsOrderTypes.asc,
                              t("admin-panel.filters.dateOldest")
                            )
                          }
                        >
                          {t("admin-panel.filters.dateOldest")}
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    {selectedFilterName && (
                      <Button
                        color="secondary"
                        className="mb-1 mr-2 mb-lg-0"
                        onClick={resetFiltration}
                      >
                        {t("admin-panel.filters.reset")}
                      </Button>
                    )}
                  </div>
                </div>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">{t("admin-panel.facultyId")}</th>
                    <th scope="col">{t("admin-panel.name")}</th>
                    <th scope="col">{t("admin-panel.institution")}</th>
                    <th scope="col">{t("admin-panel.createdAt")}</th>
                    {currentUserRole <= UserRole.insitute && <th scope="col"></th>}
                  </tr>
                </thead>
                <tbody>
                  {data.map((faculty: IFaculty) => (
                    <tr key={faculty.id}>
                      <td>{faculty.id}</td>
                      <td>{faculty.name}</td>
                      <td>{faculty.institute.name}</td>
                      <td>{formatDate(faculty.createdAt)}</td>
                      {currentUserRole <= UserRole.insitute && (
                        <td className="text-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              role="button"
                              size="sm"
                              color=""
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right container="body">
                              <DropdownItem onClick={() => toggleEditFacultyPopup(faculty.id)}>
                                {t("admin-panel.edit")}
                              </DropdownItem>
                              <DropdownItem onClick={() => openDeleteFacultyPopup(faculty.id)}>
                                {t("admin-panel.delete")}
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel={<i className="fas fa-angle-right" />}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={0}
                    pageCount={pageCount}
                    previousLabel={<i className="fas fa-angle-left" />}
                    className="pagination justify-content-end mb-0"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    activeClassName="active"
                    disabledClassName="disabled"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    forcePage={tableRequest.page - 1}
                  />
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      <AddFacultyPopup
        isAddFacultyOpen={newFacultyPopup}
        toggleAddFacultyPopup={toggleAddFacultyPopup}
        allInstitutes={allInstitutes}
      />
      <EditFacultyPopup
        isEditFacultyPopupOpen={editFacultyPopup}
        toggleEditFacultyPopup={toggleEditFacultyPopup}
        facultyId={selectedFaculty}
      />
      <ConfirmationPopup
        title={t("confirmation-popup.title.delete-faculty")}
        text={t("confirmation-popup.text.delete-faculty")}
        popupState={deleteFacultyPopup}
        handlePopup={setDeleteFacultyPopup}
        submitAction={deleteFaculty}
        btnText={t("confirmation-popup.btn-text.delete")}
        btnColor="danger"
        isFormSubmitting={isFormSubmitting}
      />
    </>
  );
};

export default FacultiesTable;
