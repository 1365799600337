import React, { useState } from "react";
import { useFormik } from "formik";
import "./contact.scss";
import * as Yup from "yup";
import { supportService } from "../../services/support.service";
import { toast } from "react-toastify";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useTranslation } from "react-i18next";

const validationSupport = Yup.object({
  name: Yup.string()
    .required("Enter a name")
    .min(2, "Minimum string length is 2 characters")
    .max(64, "Maximum string length is 64 characters")
    .matches(
      /^[aA-zZ\s]+$/,
      "Entering special characters is prohibited. Please enter a valid name"
    ),
  email: Yup.string().required("Enter email").email("Please enter a valid e-mail address"),
  comment: Yup.string().max(512, "Maximum message length - 512 characters"),
});

const Support = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const { t } = useTranslation();

  const formikSupport = useFormik({
    initialValues: {
      name: "",
      email: "",
      comment: "",
    },
    validationSchema: validationSupport,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values, { resetForm }) => {
      setIsFormSubmitting(true);
      const newSupport = {
        name: values.name,
        email: values.email,
        comment: values.comment,
      };
      const token = await executeRecaptcha("support");
      const resp = await supportService.contactSupport(newSupport, token);
      if (resp.error) {
        toast.error(t("server.error"));
        setIsFormSubmitting(false);
        return;
      }
      toast.success(t("support.success"));
      resetForm();
      setIsFormSubmitting(false);
    },
  });

  return (
    <main className="container contact">
      <h1 className="title">{t("support.title")}</h1>
      <form className="contact__form" onSubmit={formikSupport.handleSubmit}>
        <p
          className="subtitle"
          dangerouslySetInnerHTML={{
            __html: t("support.fill.first") + " " + t("support.fill.second"),
          }}
        ></p>
        {formikSupport.touched.name && formikSupport.errors.name && (
          <small className="text-danger">{formikSupport.errors.name}</small>
        )}
        <input
          className="input"
          type="text"
          {...formikSupport.getFieldProps("name")}
          placeholder={t("support.name")}
        />
        {formikSupport.touched.email && formikSupport.errors.email && (
          <small className="text-danger">{formikSupport.errors.email}</small>
        )}
        <input
          className="input"
          type="email"
          {...formikSupport.getFieldProps("email")}
          placeholder="Email"
        />
        {formikSupport.touched.comment && formikSupport.errors.comment && (
          <small className="text-danger">{formikSupport.errors.comment}</small>
        )}
        <textarea
          className="input"
          {...formikSupport.getFieldProps("comment")}
          cols={30}
          rows={4}
          placeholder={t("support.describeProblem")}
        ></textarea>
        <small>
          This site is protected by reCAPTCHA and the Google{" "}
          <a href="https://policies.google.com/privacy">Privacy Policy</a> and{" "}
          <a href="https://policies.google.com/terms">Terms of Service</a> apply.
        </small>
        {/* <div className="contact__form-btns">
          <button
            type="button"
            className="custom-button"
            style={{ color: "var(--main-black)" }}
            onClick={() => formikSupport.resetForm()}
          >
            {t("submit.cancel")}
          </button>
          <button
            type="submit"
            className="custom-button custom-button_full"
            disabled={isFormSubmitting}
          >
            {t("submit.send")}
          </button>
        </div> */}
        <button
          type="submit"
          className="custom-button custom-button_full contact__btn"
          disabled={isFormSubmitting}
        >
          {t("submit.send")}
        </button>
      </form>
    </main>
  );
};

export default Support;
