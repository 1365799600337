import "./mainPage.scss";

import classNames from "classnames";
import { useState, useEffect } from "react";

import { BenefitsList } from "../../components/MainPage/Benefits/BenefitsList";
import { ContactForm } from "../../components/MainPage/ContactForm/ContactForm";
import { Instruction } from "../../components/MainPage/Instruction/Instruction";
import { MainPageHero } from "../../components/MainPage/MainPageHero/MainPageHero";
import { MainPageSection } from "../../components/MainPage/MainPageSection/MainPageSection";
import { Slider } from "../../components/MainPage/Slider/Slider";
import { WhySection } from "../../components/MainPage/Why/WhySection";
import VideoJS from "../../components/VideoJS/VideoJS";

import firstSectionIcon from "../../assets/icons/main-page/sections/section-1.svg";
import secondSectionIcon from "../../assets/icons/main-page/sections/section-2.svg";
import thirdSectionIcon from "../../assets/icons/main-page/sections/section-3.svg";
import upButtonIcon from "../../assets/icons/main-page/up-button.svg";
import { videoUrl } from "../../constants";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { scrollToElement } from "../../utils/scrollTo";

const videoJsOptions = {
  autoplay: false,
  controls: true,
  responsive: true,
  fluid: true,
  sources: [
    {
      src: videoUrl,
      type: "video/mp4",
    },
  ],
};

const MainPage = () => {
  const [benefitsTarget, setBenefitsTarget] = useState<"institutions" | "companies">(
    "institutions"
  );
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      scrollToElement(location.hash.slice(1));
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  return (
    <>
      <MainPageHero />

      <main className="mainPage">
        <MainPageSection
          id="benefits"
          icon={firstSectionIcon}
          name={t("mainPage.benefits")}
          title={t("mainPage.storing")}
          subtitle={t("mainPage.achieve")}
        >
          <div className="mainPage__toggle-wrapper">
            <button
              className={classNames(
                "mainPage__toggle left",
                benefitsTarget === "institutions" && "active"
              )}
              onClick={() => setBenefitsTarget("institutions")}
            >
              {t("mainPage.forWho")}
            </button>
            <button
              className={classNames(
                "mainPage__toggle right",
                benefitsTarget === "companies" && "active"
              )}
              onClick={() => setBenefitsTarget("companies")}
            >
              {t("mainPage.forCompanies")}
            </button>
          </div>
          <BenefitsList benefitsTarget={benefitsTarget} />
        </MainPageSection>

        <MainPageSection
          id="features"
          icon={secondSectionIcon}
          name={t("mainPage.features")}
          title={t("mainPage.tools")}
          subtitle={t("mainPage.easierBlockchain")}
        >
          <Slider />
        </MainPageSection>

        <MainPageSection
          id="video"
          icon={thirdSectionIcon}
          name={t("mainPage.video")}
          title={t("mainPage.meet")}
          subtitle={t("mainPage.howItWorks")}
        >
          <VideoJS options={videoJsOptions} />
        </MainPageSection>

        <WhySection />

        <section id="contact-form" className="container mainPage__contact-form-wrapper">
          <ContactForm />
        </section>

        <section className="mainPage__instruction container">
          <p>{t("mainPage.begin")}</p>
          <h2>{t("mainPage.wantToWork")}</h2>
          <Instruction />
        </section>

        <div className="mainPage__up-button-wrapper">
          <button
            className="mainPage__up-button"
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            {t("mainPage.backUp")}
            <img src={upButtonIcon} alt="" />
          </button>
        </div>
      </main>
    </>
  );
};

export default MainPage;
