import "./shareDocumentModal.scss";

import { useFormik } from "formik";
import { useState } from "react";
import { toast } from "react-toastify";
import { Button, Form, FormGroup, Modal, ModalProps } from "reactstrap";
import * as Yup from "yup";

import { studentService } from "../../../services/student.service";
import { useTranslation } from "react-i18next";
import info from "../../../assets/icons/info.svg";

const transferModalEmailFormValidationSchema = Yup.object({
  email: Yup.string().required("Email is required").email("Please enter a valid email address"),
});

interface IShareDocumentModalProps extends ModalProps {
  certificateId: string | null | undefined;
  onShare: () => void;
}

export const ShareDocumentModal = (props: IShareDocumentModalProps) => {
  const { certificateId, onShare, ...modalProps } = props;

  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const {t} = useTranslation()

  const handleToggle = (event: any) => {
    props.toggle(event);
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: transferModalEmailFormValidationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async ({ email }) => {
      setIsFormSubmitting(true);
      const toastLoader = toast.loading(t("loading.sending"));

      const response = await studentService.sendDocumentByEmail(certificateId, email);

      if (response && response.error) {
        toast.update(toastLoader, {
          render: response.error,
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
        setIsFormSubmitting(false);
        return;
      }

      toast.update(toastLoader, {
        render: t("shareDocument.success"),
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });

      setIsFormSubmitting(false);
      handleToggle(null)
    },
  });

  return (
    <Modal {...modalProps}>
      <Form className="transfer-document-form" onSubmit={formik.handleSubmit}>
        <div className="transfer-document-form__header">
          <h2>{t('shareDocument.title')}</h2>
          <img src={info} alt="info" />
        </div>

        <div className="transfer-document-form__content">
          <p>
            {t('shareDocument.content')}
          </p>
        </div>

        <FormGroup>
          <div className="transfer-document-form__input">
            <label htmlFor="email">
              {t("transferModal.enter")}
            </label>
            <input type="text" id="email" placeholder="example@email.com" {...formik.getFieldProps('email')} autoComplete="off"/>
          </div>
          {formik.touched.email && formik.errors.email && (
            <small className="text-danger mt-1">{formik.errors.email}</small>
          )}
        </FormGroup>

        <div className="transfer-document-form__buttons">
          <Button
            type="submit"
            color="primary"
            className="transfer-document-form__submit-button"
            disabled={isFormSubmitting}
          >
            {t('submit.confirm')}
          </Button>
          <Button
            type="button"
            color="primary"
            outline
            onClick={handleToggle}
          >
            {t('submit.back')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};
