import React from "react";
import { Modal, Button } from "reactstrap";
import { useTranslation } from "react-i18next";

export const ConfirmationPopup = ({
  popupState,
  handlePopup,
  submitAction,
  title,
  text,
  btnText,
  btnColor,
  isFormSubmitting,
}) => {
  const {t} = useTranslation()

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={popupState}
      toggle={() => handlePopup(!popupState)}
    >
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          {title}
        </h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => handlePopup(false)}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <p className="mb-0">{text}</p>
      </div>
      <div className="modal-footer">
        <Button
          color="secondary"
          data-dismiss="modal"
          type="button"
          onClick={() => handlePopup(false)}
        >
          {t('admin-panel.cancel')}
        </Button>
        <Button color={btnColor} type="button" onClick={submitAction} disabled={isFormSubmitting}>
          {btnText}
        </Button>
      </div>
    </Modal>
  );
};
