import "./studentSettings.scss";

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useAppSelector } from "../../../app/hooks/hooks";

import { StudentEmailChangeForm } from "../../../components/Student/StudentEmailChangeForm/StudentEmailChangeForm";
import { StudentPasswordChangeForm } from "../../../components/Student/StudentPasswordChangeForm/StudentPasswordChangeForm";
import { useTranslation } from "react-i18next";

export const StudentSettings = () => {
  const isUserLogged = useAppSelector(state => state.user.isLogged);
  const navigate = useNavigate();
  const {t} = useTranslation()

  useEffect(() => {
    if (!isUserLogged) {
      navigate("/");
    }
  }, []);

  return (
    <main className="student-settings">
      <div className="container student-settings__content">
        <div className="student-settings__text-wrapper">
          <h1>{t('studentSettings.title')}</h1>
          <p>{t('studentSettings.saveTime')}</p>
        </div>
        <div className="student-settings__form-wrapper">
          <StudentEmailChangeForm />
          <StudentPasswordChangeForm />
        </div>
      </div>
    </main>
  );
};
