import { ReactNode } from "react";

import "./mainPageSection.scss";

interface IMainPageSectionProps {
  id: string;
  icon: string;
  name: string;
  title: string;
  subtitle: string;
  children?: ReactNode;
}

export function MainPageSection(props: IMainPageSectionProps) {
  const { id, icon, name, title, subtitle, children } = props;

  return (
    <section id={id} className="container mainPageSection">
      <div className="mainPageSection__name">
        <div className="mainPageSection__icon">
          <img src={icon} alt="" />
        </div>
        {name}
      </div>
      <h2 className="mainPageSection__title">{title}</h2>
      <p className="mainPageSection__subtitle">{subtitle}</p>
      {children}
    </section>
  );
}
