import "swiper/css";
import "./slider.scss";

import { Swiper, SwiperSlide } from "swiper/react";

import { SlideButton } from "./SlideButton";

import firstSlide from "../../../assets/icons/main-page/slides/slide-1.svg";
import secondSlide from "../../../assets/icons/main-page/slides/slide-2.svg";
import thirdSlide from "../../../assets/icons/main-page/slides/slide-3.svg";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Navigation } from "swiper";

export function Slider() {
  const { t } = useTranslation();
  const [currentSlideIdx, setCurrentSlideIdx] = useState(0);

  const slides = [
    {
      id: 0,
      name: t("mainPageSlider.digitalVerification"),
      title: <div dangerouslySetInnerHTML={{ __html: t("mainPageSlider.verifyAny") }} />,
      text: t("mainPageSlider.saveTime"),
      subtitle: t("mainPageSlider.verificationPortal"),
      image: firstSlide,
    },
    {
      id: 1,
      name: t("mainPageSlider.documentPublisher"),
      title: <div dangerouslySetInnerHTML={{ __html: t("mainPageSlider.beginTokenizing") }} />,
      text: t("mainPageSlider.opportunity"),
      subtitle: t("mainPageSlider.publisher"),
      image: secondSlide,
    },
    {
      id: 2,
      name: t("mainPageSlider.passport"),
      title: (
        <div dangerouslySetInnerHTML={{ __html: t("mainPageSlider.administerCredentials") }} />
      ),
      text: t("mainPageSlider.access"),
      subtitle: t("mainPageSlider.passport"),
      image: thirdSlide,
    },
  ];

  return (
    <>
      <Swiper
        modules={[Navigation]}
        spaceBetween={50}
        slidesPerView={1}
        breakpoints={{ 1023: { slidesPerView: 1.2 } }}
        className="slider"
        onSlideChange={e => setCurrentSlideIdx(e.activeIndex)}
        navigation={{
          nextEl: ".slideButton.next",
          prevEl: ".slideButton.prev",
        }}
      >
        {slides.map(slide => (
          <SwiperSlide key={slide.id} className="slide">
            <div>
              <p className="slide__name">{slide.name}</p>
              <h3 className="slide__title">{slide.title}</h3>
              <p className="slide__text">{slide.text}</p>
            </div>

            <div className="slide__image">
              <img src={slide.image} alt={slide.name} />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="slide__navigation">
        <SlideButton slidesAmount={slides.length} slideId={currentSlideIdx} direction="back" />
        <SlideButton slidesAmount={slides.length} slideId={currentSlideIdx} direction="forward" />
        <p>{slides[currentSlideIdx].subtitle}</p>
      </div>
    </>
  );
}
