import "./documentsList.scss";

import { DocumentTypes } from "../../../app/models/adminPanel.interface";
import { ISelectedDocument, ModalType } from "../../../pages/Student/Student";
import { DocumentCard, IDocument } from "../DocumentCard/DocumentCard";
import { useTranslation } from "react-i18next";

interface IDocumentsListProps {
  documents: IDocument[];
  documentsType: DocumentTypes;
  onOpenModal: (modalType: ModalType, document: ISelectedDocument) => void;
}

export const DocumentsList = (props: IDocumentsListProps) => {
  const { documents, documentsType, onOpenModal } = props;
  const {t} = useTranslation()

  return (
    <>
      {documents.length > 0 ? (
        <div className="documents-list">
          {documents.map(document => (
            <DocumentCard
              key={document.id}
              document={document}
              onOpenModal={onOpenModal}
            />
          ))}
        </div>
      ) : (
        <div className="documents-list__empty">
          <p>
            {t("documentsList.dont")}{" "}
            {documentsType === DocumentTypes.certificate ? t("documentsList.certificates") : t("documentsList.diplomas")} {t("documentsList.yet")}
          </p>
        </div>
      )}
    </>
  );
};
