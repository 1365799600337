import "./instruction.scss";

import firstStepImage from "../../../assets/icons/main-page/instruction/instruction-1.svg";
import secondStepImage from "../../../assets/icons/main-page/instruction/instruction-2.svg";
import thirdStepImage from "../../../assets/icons/main-page/instruction/instruction-3.svg";
import { useTranslation } from "react-i18next";

export function Instruction() {
  const {t} = useTranslation()

  const instructionSteps = [
    {
      id: 1,
      image: firstStepImage,
      title: t("mainPageInstruction.fill"),
      text: t("mainPageInstruction.weContact"),
    },
    {
      id: 2,
      image: secondStepImage,
      title: t("mainPageInstruction.contract"),
      text: t("mainPageInstruction.weCreate"),
    },
    {
      id: 3,
      image: thirdStepImage,
      title: t("mainPageInstruction.integrate"),
      text: t("mainPageInstruction.ourTeam"),
    },
  ];

  return (
    <div className="instruction">
      {instructionSteps.map(step => (
        <div className="instruction__step" key={step.id}>
          <div className="instruction__step-number">{step.id}</div>
          <div className="instruction__image-wrapper">
            <img src={step.image} alt={step.title} />
          </div>
          <h3 className="instruction__step-title">{step.title}</h3>
          <p className="instruction__step-text">{step.text}</p>
        </div>
      ))}
    </div>
  );
}
