import "./why.scss";

import { WhyList } from "./WhyList";

import whySectionIcon from "../../../assets/icons/main-page/sections/section-4.svg";
import whySectionBackground from "../../../assets/images/main-page/why-section.jpg";
import { useTranslation } from "react-i18next";

export function WhySection() {
  const {t} = useTranslation()
  return (
    <div className="whySection">
      <img src={whySectionBackground} alt="" className="whySection__background" />

      <section className="container whySection__wrapper">
        <div className="whySection__name">
          <div className="whySection__icon">
            <img src={whySectionIcon} alt="" />
          </div>
          {t('whySection.title')}
        </div>

        <h2 className="whySection__title">
          {t('whySection.provides.first')}
          <br />
          <span className="whySection__title-bold" dangerouslySetInnerHTML={{__html: t('whySection.provides.second')}}/>
        </h2>

        <WhyList />
      </section>
    </div>
  );
}
