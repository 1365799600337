import "./slideButton.scss";

import classNames from "classnames";
import { useMemo } from "react";

import activeArrow from "../../../assets/icons/main-page/slides/arrow-active.svg";
import disabledArrow from "../../../assets/icons/main-page/slides/arrow-disabled.svg";

interface ISlideButtonProps {
  slidesAmount: number;
  slideId: number;
  direction: "back" | "forward";
}

export function SlideButton(props: ISlideButtonProps) {
  const { slidesAmount, slideId, direction } = props;

  const isDisabled = useMemo(() => {
    switch (direction) {
      case "back":
        return slideId === 0;
      case "forward":
        return slideId === slidesAmount - 1;
    }
  }, [direction, slideId, slidesAmount]);

  return (
    <button
      className={classNames(
        "slideButton",
        direction === "back" ? "prev" : "next",
        isDisabled && "disabled"
      )}
    >
      <img src={isDisabled ? disabledArrow : activeArrow} alt={direction} />
    </button>
  );
}
