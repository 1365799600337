import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./app/store";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import './i18n';

const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY;

ReactDOM.render(
  <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </GoogleReCaptchaProvider>,
  document.getElementById("root")
);
